import React from 'react';
import clsx from 'clsx';
import './Layout.css';

interface LayoutProps {
  spacing?: number;
  className?: string;
}

export const Layout: React.FC<LayoutProps> = (props) => {

  const classes = clsx(
    props.className,
    'jetui-layout',
    {
      [`jetui-layout-spacing-1`]: props.spacing === undefined,
      [`jetui-layout-spacing-${String(props.spacing)}`]: props.spacing
    }
  );

  return (
    <div className={classes}>
      {props.children}
    </div>
  );
};