import React from 'react';
import { Dialog } from '../Dialog/Dialog';
import { ToolButton } from '../ToolButton/ToolButton';

interface ConfirmActionProps<T> {
  value: T;
  icon: string;
  onAction: (value: T, confirmed: boolean) => void;
  title: string;
  disabled?: boolean;
};

export const ConfirmAction = <T extends {}>(props: React.PropsWithChildren<ConfirmActionProps<T>>) => {
  const [dialogOpen, setDialogOpen] = React.useState(false);

  const handleOnClose = React.useCallback((confirmed: boolean) => {
    props.onAction(props.value, confirmed);
    setDialogOpen(false);
  }, [props.value, props.onAction]);

  const handleClick = React.useCallback(() => setDialogOpen(true), []);

  return (
    <span className="jetui-table-confirmation">
      <ToolButton icon={props.icon} onClick={handleClick} collapse disabled={props.disabled} />
      <Dialog open={dialogOpen} onClose={handleOnClose} title={props.title}>
        {props.children}
        <div className="jetui-dialog-actions">
          <button onClick={() => handleOnClose(true)}>Yes</button>
          <button onClick={() => handleOnClose(false)}>No</button>
        </div>  
      </Dialog>
    </span>
  );
};