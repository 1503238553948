import React, { Fragment } from 'react';
import axios from 'axios';
import { TriggerDefinition, ApiResult, NugetSource, Flow } from '../../common/dataTypes/Jetstream';

import './Triggers.css'
import { useAuth } from '../../common/auth/use-auth';
import { JetTable, ConfirmAction, ToolButton, EditPanelState, useForm, EditPanel, SelectOption, Formic } from '../../jet-ui';
import { Column, Cell } from 'react-table';

interface TriggersProps {
  flowName: string;
}

export const Triggers: React.FC<TriggersProps> = (props) => {

  const [triggers, setTriggers] = React.useState<TriggerDefinition[]>([]);
  const [flows, setFlows] = React.useState<Flow[]>([]);

  const [loading, setLoading] = React.useState(false);
  const [editState, setEditState] = React.useState<EditPanelState<TriggerDefinition>>({open: false, value: {}});

  const auth = useAuth();
  const Form = useForm(editState.value);

  const fetchData = React.useCallback(async () => {
    try {
      setLoading(true);
      const result = await axios.get<ApiResult<TriggerDefinition[]>>(`/api/triggers?flowName=${encodeURIComponent(props.flowName)}`, auth.getRequestConfig());
      setTriggers(result.data.data);

      const flowResult = await axios.get<ApiResult<any>>('/api/flows', auth.getRequestConfig());
      setFlows(flowResult.data.data.flows);
    }
    finally {
      setLoading(false);
    }
  }, [props.flowName]);

  React.useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleOnDelete = React.useCallback(async (value: TriggerDefinition, confirm: boolean) => {
    if (confirm) {
      await axios.delete(`/api/triggers/${value.triggerDefinitionId}`, auth.getRequestConfig());

      setEditState(prev => ({...prev, status: `Trigger on ${value.completedFlow} ${value.on} deleted.`}));
      await fetchData();
    }
  }, [fetchData]);

  const handleOnSave = React.useCallback(async (trigger: Partial<Formic<TriggerDefinition>>) => {
    const result = await axios.post<ApiResult<any>>('/api/triggers', {...trigger, flowName: props.flowName}, auth.getRequestConfig());
    if (!result.data.success) {
      throw { message: result.data.message };
    }
    else {
      setEditState(prev => ({...prev, status: `Trigger on ${trigger.completedFlow} ${trigger.on} added.`}));
    }
    
    await fetchData();
  }, [props.flowName, fetchData]);

  const columns = React.useMemo((): Column<TriggerDefinition>[] => [
    { 
      id: 'actions',
      width: 64,
      maxWidth: 64,
      Cell: (cell: Cell<TriggerDefinition>) => (
        <Fragment>
          <ConfirmAction value={cell.row.original} icon="delete" onAction={handleOnDelete} title='Delete Trigger'>
            Are you sure you wish to delete the trigger on {cell.row.original.completedFlow} {cell.row.original.on}?
          </ConfirmAction>
          <ToolButton icon="edit" onClick={() => setEditState({value: cell.row.original, open: true})} collapse />
        </Fragment>
      )
    },
    { Header: 'Flow', accessor: 'completedFlow' },
    { Header: 'On', accessor: 'on' }
  ], []);

  const options = React.useMemo((): SelectOption[] => [
    {value: 'Success', label: 'Success'}, 
    {value: 'Failure', label: 'Failure'},
    {value: 'Completion', label: 'Any Completion'}
  ], []);

  return (
    <div className="Triggers">
      <h2>Triggers</h2>
      <JetTable data={triggers} loading={loading} columns={columns} />
      <EditPanel<TriggerDefinition> className="Triggers__EditPanel" state={editState} form={Form} addLabel="Add Trigger" modelLabel="Trigger" onSave={handleOnSave}>
        <div><Form.OptionSelect name="on" placeholder="On" options={options} /></div>
        <div><Form.OptionSelect name="completedFlow" placeholder="Of Flow" options={flows.map(flow => ({value: flow.structure.name, label: flow.structure.name}))} /></div>
      </EditPanel>
    </div>
  );
}