import React from 'react';
import { useHistory } from 'react-router-dom';

import './User.css';

import { useAuth } from '../../common/auth/use-auth';
import { Avatar } from '../../jet-ui/Avatar/Avatar';
import { Breakpoint, Medium } from '../../jet-ui/Breakpoints/Breakpoint';
import { Dropdown } from '../../jet-ui/Dropdown/Dropdown';
import { DropdownItem } from '../../jet-ui/DropdownItem/DropdownItem';
import { ThemeResult } from '../../jet-ui/Theme/useTheme';

/**
 * A component for the header menu that displays the user.
 * @param props The properties for the component.
 */
export const User: React.FC<{theme: ThemeResult}> = (props) => {

  const menuAnchorEl = React.useRef<HTMLDivElement | null>(null);
  const [open, setOpen] = React.useState(false);
  
  const history = useHistory();

  const auth = useAuth();

  /**
   * Handles a logout click request.
   */
  const handleLogout = () => {
    auth.service.logout();
    handleMenuClose();
  };

  /**
   * Handles a click event for the user menu.
   * @param e The click event.
   */
  const handleMenuClick = (e: React.MouseEvent<HTMLDivElement>) => {
    setOpen(true);
  };

  /**
   * Handles the closing of the user menu.
   */
  const handleMenuClose = () => {
    setOpen(false);
  };

  /**
   * Handles the click event when the profile link is clicked on the user menu.
   */
  const handleMenuProfile = async () => {
    history.push('/profile');
    handleMenuClose();
  };

  return (
    <div className="User" onClick={handleMenuClick}>
      <Breakpoint>
        <Medium>
          <span ref={menuAnchorEl}>{auth.user?.profile.name} <i className="material-icons">expand_more</i></span>
          <Dropdown className="User__Dropdown" anchor={menuAnchorEl.current} open={open} onClose={handleMenuClose}>
            <DropdownItem icon="account_circle" onClick={handleMenuProfile}>Profile</DropdownItem>
            {props.theme.theme === 'light' && <DropdownItem icon="brightness_high" onClick={props.theme.toggleTheme}>Switch to Dark Mode</DropdownItem>}
            {props.theme.theme === 'dark' && <DropdownItem icon="brightness_low" onClick={props.theme.toggleTheme}>Switch to Light Mode</DropdownItem>}
            <DropdownItem icon="exit_to_app" onClick={handleLogout}>Sign Out</DropdownItem>       
          </Dropdown>
        </Medium>
      </Breakpoint>     
      {auth.user?.profile.picture
        ? <Avatar src={auth.user?.profile.picture} className="User__Avatar" />
        : <Avatar className="User__Avatar">{auth.user?.profile.name && auth.user?.profile.name[0]}</Avatar>
      }
    </div>  
  );
}