import React from 'react';
import { FlowStructure } from '../../common/dataTypes/Jetstream';

// @ts-ignore
import * as d3 from 'd3';
// @ts-ignore
import * as d3dag from 'd3-dag';

import './JobTree.css';

/**
 * A DAG layout representation of a job graph.
 */
interface JobDag {

  /** The ID of the job within the flow. */
  id: number;

  /** The ID of the job execution. */
  jobExecutionId: number;

  /** The type of the job. */
  type: string;

  /** The IDs of the children of this job. */
  children: number[];

  /** The IDs of the parents of this job. */
  parentIds: number[];
}

/** Properties on the JobTree component. */
interface JobTreeProps {

  /** The structure of the flow to display. */
  flow: FlowStructure
}

/**
 * A component that displays the job graph in a tree format.
 * @param props The properties of the component.
 */
export const JobTree: React.FC<JobTreeProps> = (props) => {

  const graphContainer = React.useRef(null);

  React.useEffect(() => {

    if(props.flow && graphContainer.current) {

      const svg = d3.select(graphContainer.current);

      const layout = d3dag.sugiyama()
        .nodeSize([40, 80])
        .layering(d3dag.layeringSimplex())
        .decross(d3dag.decrossOpt())
        .coord(d3dag.coordCenter())

      const dag = structureToDag(props.flow);
      layout(dag);

      const lineRenderer = d3.line()
        .curve(d3.curveCatmullRom)
        .x((d: any) => d.y)
        .y((d: any) => d.x);

      svg.append('g')
        .selectAll('path')
        .data(dag.links())
        .enter()
        .append('path')
        .attr('d', (links: any) => lineRenderer(links.data.points))
        .attr('fill', 'none')
        .attr('stroke-width', 3)
        .attr('stroke', '#ddd');

      const nodes = svg.append('g')
        .selectAll('g')
        .data(dag.descendants())
        .enter()
        .append('g')
        .attr('transform', (d: any) => `translate(${d.y}, ${d.x})`);

      nodes.append('circle')
        .attr('r', 16)
        .attr('fill', 'steelblue')
        .attr('cursor', 'pointer');

      nodes.append('text')
        .text((d: any) => d.id)
        .attr('font-size', '14px')
        .attr('text-anchor', 'middle')
        .attr('alignment-baseline', 'middle')
        .attr('fill', 'white')
        .attr('cursor', 'pointer');
    }

  }, [props.flow]);

  /**
   * Creates a d3-dag consumable dag from a flow structure.
   * @param structure The flow structure.
   */
  function structureToDag(structure: FlowStructure) {

    var stratify = d3dag.dagStratify();

    var jobs = structure.jobs.map(job => ({
      id: job.id,
      jobExecutionId: job.jobExecutionId,
      type: job.type,
      children: job.children,
      parentIds: []
    } as JobDag));

    jobs.forEach(job => {
      job.children.forEach(child => {
        jobs[child].parentIds.push(job.id);
      })
    });

    return stratify(jobs);
  };

  return (
    <svg viewBox="-20 -20 1240 640" ref={graphContainer} />
  )
}
