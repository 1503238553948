import React from 'react';
import { Content } from '../jet-ui/Content/Content';
import { Breadcrumb } from '../jet-ui/Breadcrumb/Breadcrumb';
import { Card } from '../jet-ui/Card/Card';
import { FlowTable } from '../flowTable/FlowTable';

export const Flows: React.FC = () => {

    let pageIndex = Number(window.sessionStorage.getItem("pageIndex"));

    const onManualPageChange = React.useCallback((pageIndex: number) => {
        window.sessionStorage.setItem("pageIndex", String(pageIndex));
        return;
    }, []);

    return (
        <Content className="Flows">
            <div>
                <Breadcrumb href="/">Home</Breadcrumb>
                <Breadcrumb href="/flows">Flows</Breadcrumb>
            </div>
            <h1>Flows</h1>
            <Card>
                <h2>All Flows</h2>
                <FlowTable onManualPageChangeFn={onManualPageChange} pageIndex={pageIndex} />
            </Card>
        </Content>
    );
};