import React from 'react';
import { useAuth } from '../common/auth/use-auth';

import './Profile.css';

import { AvatarUploader } from './avatarUploader/AvatarUploader';
import { UserDetails } from './userDetails/UserDetails';
import { ChangePassword } from './changePassword/ChangePassword';
import { AccessTokens } from './accessTokens/AccessTokens';
import { Content } from '../jet-ui/Content/Content';
import { Breadcrumb } from '../jet-ui/Breadcrumb/Breadcrumb';
import { Card } from '../jet-ui/Card/Card';
import { CliAuth } from './cliAuth/CliAuth';

/**
 * A component that manages the user's profile.
 */
export const Profile: React.FC = () => {

  const auth = useAuth();
  const userProfile = auth.user?.profile;

  /**
   * Gets the profile image source with refresh hash.
   */
  const getProfileImageSource = () => {
    if (userProfile?.picture) {
      return `${userProfile.picture}&hash=${new Date().getTime()}`;
    }

    return undefined;
  };

  return (
    <Content className="Profile">
      <div>
        <Breadcrumb href="/">Home</Breadcrumb>
        <Breadcrumb href="/profile">Profile</Breadcrumb>
      </div>
      <h1>Profile</h1>
      <Card>
        <CliAuth />
        <UserDetails />
        <ChangePassword />
        <div className="Profile__Section">
          <h2>Avatar Image</h2>
          <AvatarUploader src={getProfileImageSource()} />
        </div>
        <AccessTokens />
      </Card>
    </Content>  
  );
}
