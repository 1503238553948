import React from 'react';
import { useLocation } from 'react-router-dom';
import axios, { AxiosError } from 'axios';

import './Login.css';
import { Status } from '../jet-ui/Status/Status';
import { FormInput, Input } from '../jet-ui/Input/Input';
import { Card } from '../jet-ui/Card/Card';
import { Logo } from '../jet-ui/Logo/Logo';

/** An interface that describes the login form model. */
interface ILoginForm {

  /** The username to login with. */
  username: string;

  /** The password to login with. */
  password: string;
}

/** An interface that describes the login metadata API response. */
interface ILoginMetadata {

  /** A collection of enabled external authentication providers. */
  providers: IAuthProvider[];
}

/**
 * An external authentication provider that has been enabled
 * in the Jetstream auth system.
 */
interface IAuthProvider {

  /** The display name of the authentication provider. */
  displayName: string;

  /** The name of the authentication scheme for this provider. */
  scheme: string;
}

/**
 * The login page functional component.
 */
export const Login: React.FC = () => {

  const [isLoggingIn, setIsLoggingIn] = React.useState<boolean>(false);
  const [externalProviders, setExternalProviders] = React.useState<IAuthProvider[]>([]);
  const [formState, setFormState] = React.useState<ILoginForm>({ username: '', password: '' });
  const [error, setError] = React.useState<string>('');

  const location = useLocation();
  const search = new URLSearchParams(location.search);

  const returnUrl = search.get('ReturnUrl');
  const returnMessage = returnUrl && new URL(decodeURIComponent(returnUrl), window.location.origin).searchParams.get('message');

  React.useEffect(() => {
    if (returnMessage) {
      setError(returnMessage);
    }
  }, [returnMessage]);

  React.useEffect(() => {

    /**
     * Fetches the login metadata from the backend.
     */
    const fetchLoginMetadata = async () => {
      const result = await axios.get<ILoginMetadata>(`account/metadata?ReturnUrl=${returnUrl}`);
      if (result.status === 200) {
        setExternalProviders(result.data.providers);
      }
    };

    fetchLoginMetadata();
  }, [returnUrl]);

  /**
   * Handles the submission of the login form.
   * @param e The form submission event.
   */
  const handleLoginSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();

    setIsLoggingIn(true);

    try {
      const result = await axios.post('/account/login', formState);

      if (result.status === 200 && returnUrl) {
        window.sessionStorage.setItem("pageIndex", "0");
        window.location.assign(returnUrl);
      } else {
        setError('The username and/or password provided was not valid.');
        setIsLoggingIn(false);
      }

    } catch(err) {
      const error = err as AxiosError;

      if (error.response && error.response.status === 401) {
        setError('The username and/or password provided was not valid.');
        setIsLoggingIn(false);
      } else {
        setError(error.message);
        setIsLoggingIn(false);
      }
    }
  };

  /**
   * Handles when an external login is requested via clicking on
   * an external auth provider icon.
   * @param scheme The scheme to login using.
   */
  const handleExternalLogin = (scheme: string) => {
    if (returnUrl) {
      window.location.assign(`/account/external?scheme=${encodeURI(scheme)}&ReturnUrl=${encodeURIComponent(returnUrl)}`);
    } else {
      window.location.assign(`/account/external?scheme=${encodeURI(scheme)}`);
    }   
  };

  /**
   * Handles when the username field is updated.
   * @param e An input change event.
   */
  const handleSetUsername = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setFormState(prev => cloneInto(prev, {username: value}));
  }

  /**
   * Handles when the password field is updated.
   * @param e An input change event.
   */
  const handleSetPassword = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setFormState(prev => cloneInto(prev, {password: value}));
  }

  /**
   * Clones the target object and shallow copies the fields
   * of the source object into the target object.
   * @param target The object to clone and assign to.
   * @param source The source object to copy fields from.
   */
  const cloneInto = (target: any, source: any) => {
    const clone = Object.assign({}, target);
    return Object.assign(clone, source);
  };
  
  /**
   * Renders external authentication providers.
   */
  const renderExternalProviders = () => externalProviders.map(provider => {
    return (
      <li onClick={() => handleExternalLogin(provider.scheme)}>
        <img src={`auth_${provider.scheme}.svg`} alt={provider.displayName} /> {provider.displayName}
      </li>
    );
  });

  return (
    <div className='Login__Wrapper'>
      <div className='Login'>
        <Logo size='lg' includeText />
        <Card className="Login__Card">
          <h1>LOGIN TO JETSTREAM</h1>
          <div hidden={error === ''} className="Login__Error">{error}</div>
          <form onSubmit={handleLoginSubmit}>          
            <Input name="username" type="text" placeholder="Username" value={formState.username} onChange={handleSetUsername} />
            <Input name="password" type="password" placeholder="Password" value={formState.password} onChange={handleSetPassword} />
            <button type="submit" disabled={isLoggingIn}>{isLoggingIn ? "LOGGING IN..." : "LOGIN"}</button>
          </form>
          <div className='Login__Card__ExternalProviders'>
            <h1>OR LOGIN WITH</h1>
            <ul>
              {renderExternalProviders()}
            </ul>
          </div>
        </Card>
      </div>   
    </div>
  )
}