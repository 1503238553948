import React from 'react';
import * as Router from 'react-router-dom'
import { Route } from 'react-router-dom';
import axios from 'axios';

import './FlowPage.css'

import { FlowNav } from './flowNav/FlowNav';
import { ApiResult, Flow } from '../common/dataTypes/Jetstream';
import { FlowDetail } from './flowDetail/FlowDetail';
import { JobTree } from './jobTree/JobTree';
import { FlowExecutions } from './flowExecutions/FlowExecutions';
import { Content } from '../jet-ui/Content/Content';
import { Breadcrumb } from '../jet-ui/Breadcrumb/Breadcrumb';
import { Card } from '../jet-ui/Card/Card';
import { Schedule } from './Schedule/Schedule';
import { useAuth } from '../common/auth/use-auth';
import { Triggers } from './Triggers/Triggers';

/**
 * A component that displays the Flow information page.
 */
export const FlowPage: React.FC = () => {
  const { flowName } = Router.useParams();
  const match = Router.useRouteMatch();

  const auth = useAuth();

  const [flow, setFlow] = React.useState<Flow>({
    structure: {
      name: flowName as string,
      flowExecutionId: null,
      jobs: []
    },
    schedule: 'None',
    next: '',
    last: '',
    histogram: []
  });

  React.useEffect(() => {

    /**
     * Fetches the flow data from Jetstream.
     */
    const fetchData = async () => {
      const result = await axios.get<ApiResult<Flow>>('/api/flows/' + encodeURI(flowName as string), auth.getRequestConfig());
      setFlow(result.data.data);
    };

    fetchData();
  }, [flowName]);

  return (
    <Content className="Flow">
      <div>
        <Breadcrumb href="/">Home</Breadcrumb>
        <Breadcrumb href="/flows">Flows</Breadcrumb>
        <Breadcrumb href={`${match.url}/detail`}>{flowName}</Breadcrumb>
      </div>
      <h1>{flowName}</h1>  
      <Card>
        <FlowNav flow={flow}/>
        <Route path={`${match.url}/detail`}>
          <FlowDetail flow={flow} />
        </Route>
        <Route path={`${match.url}/tree`}>
          <div className="JobTree">
            <JobTree flow={flow.structure} />
          </div>
        </Route>
        <Route path={`${match.url}/schedule`}>
          <Schedule flowName={flow.structure.name} />
        </Route>
        <Route path={`${match.url}/triggers`}>
          <Triggers flowName={flow.structure.name} />
        </Route>
        <Route path={`${match.url}/executions`}>
          <div>
            <FlowExecutions flowName={flow.structure.name} />
          </div>
        </Route>
      </Card>
    </Content>
  )
}
