import React from 'react';
import axios from 'axios';

import './GanttChart.css';

//@ts-ignore
import * as d3 from 'd3';
//@ts-ignore
import * as d3Scale from 'd3-scale';

import { JobState, ApiResult } from '../../common/dataTypes/Jetstream';
import { useAuth } from '../../common/auth/use-auth';

/** Properties on the GanttChart component. */
interface GanttChartProps {

  /** The execution ID of the flow. */
  flowExecutionId?: number
}

/**
 * A component that displays a Gantt chart of job timings.
 * @param props The properties of the component.
 */
export const GanttChart: React.FC<GanttChartProps> = (props) => {
  
  const [jobTimings, setJobTimings] = React.useState<any[]>([]);
  const chartContainer = React.useRef<SVGSVGElement>(null);

  const auth = useAuth();
  
  React.useEffect(() => { 

    /**
     * Handles when a flow execution ID is changed in the component properties.
     */
    const onExecutionIdChange = async () => {
      if (props.flowExecutionId) {
        const result = await axios.get<ApiResult<JobState[]>>(`/api/executions/${props.flowExecutionId}/timings`, auth.getRequestConfig());
        setJobTimings(result.data.data);
      }   
    };

    onExecutionIdChange(); 
  }, [props.flowExecutionId]);

  React.useEffect(() => {

    /**
     * Renders the Gantt chart.
     */
    const renderChart = () => {
      if (chartContainer.current && jobTimings.length > 0) {
  
        const svg = d3.select(chartContainer.current);
  
        const height = jobTimings.length * 50;
        const padding = 50;
  
        svg.attr('viewBox', `-20 -20 1640 ${height}`);
        svg.selectAll('*').remove();
  
        const minTime = d3.min(jobTimings.map(x => new Date(x.started)));
        const maxTime = d3.max(jobTimings.map(x => new Date(x.ended)));
  
        const timeScale = d3Scale.scaleTime()
          .domain([minTime, maxTime])
          .range([padding, 1640 - padding]);
  
        svg.append('g')
          .attr('transform', `translate(0, ${height - padding})`)
          .call(d3.axisBottom(timeScale));
  
        const jobScale = d3.scaleBand()
          .domain(jobTimings.map(x => x.jobId))
          .range([0, height - padding])
          .padding(0.2);
  
        svg.append('g')
          .attr('transform', `translate(${padding - 10}, 0)`)
          .call(d3.axisLeft(jobScale));
  
        svg.append('g')
          .selectAll('rect')
          .data(jobTimings)
          .enter()
          .append('rect')
          .attr('x', (d: any) => timeScale(new Date(d.started)))
          .attr('width', (d: any) => Math.max(timeScale(new Date(d.ended)) - timeScale(new Date(d.started)), 2))
          .attr('y', (d: any) => jobScale(d.jobId))
          .attr('rx', 4)
          .attr('ry', 4)
          .attr('height', jobScale.bandwidth())
          .attr('fill', 'steelblue');
      }
    };

    renderChart();
  }, [jobTimings]);

  return (
    <div className='GanttChart'>
      <svg viewBox="-20 -20 1640 640" ref={chartContainer} />
    </div>
  )
}