import React, { ReactElement } from 'react';

export const Breakpoint: React.FC = (props) => {

  let e: any = window;
  let a = 'inner';

  if (!('innerWidth' in window)) {
      a = 'client';
      e = document.documentElement || document.body;
  }

  const getLayoutSize = React.useCallback(() => {
    const width = e[ a+'Width' ] as number;

    return width >= 1200 ? 3
      : width >= 992 ? 2
      : width >= 768 ? 1
      : 0
  }, [a, e]);

  const [size, setSize] = React.useState(getLayoutSize());

  React.useEffect(() =>{
    const handleResize = () => {
      setSize(getLayoutSize());
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [getLayoutSize]);


  const childSizes: { [index: number]: ReactElement | null } = {
    0: null,
    1: null,
    2: null,
    3: null
  };

  React.Children.forEach(props.children, (child: any) => {
    const typeToSize: { [index: string]: number } = {
      'Small': 0,
      'Medium': 1,
      'Large': 2,
      'XLarge': 3
    };

    let childSize: 0 | 1 | 2 | 3 | undefined = undefined;
    if (child.type === Small) {
      childSize = 0;
    }

    if (child.type === Medium) {
      childSize = 1;
    }

    if (child.type === Large) {
      childSize = 2;
    }

    if (child.type === XLarge) {
      childSize = 3;
    }
    
    if (childSize !== undefined) {
      childSizes[childSize] = child;
    }
  });
  
  for (var childSize = 3; childSize >= 0; childSize--) {
    if (Number(childSize) <= size && childSizes[childSize]) {
      return childSizes[Number(childSize)];
    }
  }

  return null;
};

export const Small: React.FC = (props) => props.children as ReactElement;
export const Medium: React.FC = (props) => props.children as ReactElement;
export const Large: React.FC = (props) => props.children as ReactElement;
export const XLarge: React.FC = (props) => props.children as ReactElement;