import React from 'react';
import { JetTableFilterProps } from './JetTable';
import { OptionSelect, SelectOption } from '../OptionSelect/OptionSelect';

export const SelectFilter = <T extends {}>(props: JetTableFilterProps<T, {}>) => {
  const unfilteredRows = props.column.preFilteredRows;
  const columnId = props.column.id;

  const getOptions = React.useCallback(() => {
    const options: {[index: string]: boolean} = {};
    for (var i = 0; i < unfilteredRows.length; i++) {
      options[unfilteredRows[i].values[columnId]] = true;
    }

    return Object.keys(options).map(o => ({label: o, value: o}) as SelectOption);
  }, [unfilteredRows, columnId]);

  const options = React.useMemo(() => getOptions(), [getOptions]);

  return (
    <OptionSelect name="filter" className="jetui-table-selectfilter" options={options} onChange={props.column.setFilter} />
  );
};