import React from 'react';
import clsx from 'clsx';
import './Status.css';
import { useTransition } from '../Transition/useTransition';

export declare type StatusType = 'info' | 'good' | 'warning' | 'error' | 'neutral';

interface StatusProps {
  open: boolean;
  type?: StatusType;
  onClose: () => void;
};

export const Status: React.FC<StatusProps> = (props) => {

  const [timeoutRef, setTimeoutRef] = React.useState<NodeJS.Timeout | null>(null);
  const [open, setOpen] = React.useState(props.open);
  const animState = useTransition(0.3, open);

  React.useEffect(() => {
    if (animState === 'closed') {
      if (timeoutRef) {
        clearTimeout(timeoutRef);
        setTimeoutRef(null);
      }
      props.onClose();
    }

    if (animState === 'open') {
      if (timeoutRef) {
        clearTimeout(timeoutRef);
        setTimeoutRef(null);
      }

      const timeout = setTimeout(() => setOpen(false), 5000);
      setTimeoutRef(timeout);
    }
  }, [animState]);

  React.useEffect(() => setOpen(props.open), [props.open]);

  const classes = clsx('jetui-status', {
    [`jetui-status-${props.type}`]: props.type,
    'jetui-status-info': !props.type,
    'jetui-status-open': animState === 'open',
    'jetui-status-closed': animState === 'closed'
  });

  const icon = clsx({
    'info': props.type === 'info' || props.type === 'good' || props.type === 'neutral' || !props.type,
    'warning': props.type === 'warning',
    'error': props.type === 'error'
  });

  return (
    <div className="jetui-status-container">
      <div className={classes}>
        <i className="material-icons">{icon}</i>
        <span>{props.children}</span>
      </div>
    </div>
  )
};
