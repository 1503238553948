import React from 'react';
import clsx from 'clsx';
import './Input.css';
import { FormSystem, ValidationResult, Formic, FormKeys } from '../Form/FormSystem';

export interface InputProps<T> extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  error?: string;
  name: FormKeys<T>;
  onValidate?(state: Partial<Formic<T>>, value: Partial<Formic<T>>[FormKeys<T>]): ValidationResult;
};

export function FormInput<T>(formSystem: FormSystem<T> | undefined) {

  function useFormRender(props: InputProps<T>, ref: React.Ref<HTMLInputElement>) {
    let {error, onValidate, ...inputProps} = props;
    let [value, setValue] = React.useState<Formic<T>[FormKeys<T>]>();

    if (formSystem) {
      let {required} = props;
      delete inputProps.required;

      [value, setValue] = formSystem.useRegistration({name: props.name, label: props.placeholder ?? props.name, onValidate: props.onValidate, required: required});
      error = formSystem.errors.byField[props.name]?.message;
    }

    const classes = clsx('jetui-input', props.className, {
      'jetui-input-disabled': props.disabled,
      'jetui-input-error': props.error !== null && props.error !== undefined
    });

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      setValue(e.target.value);
      props.onChange && props.onChange(e);
    }

    return (
      <div className="jetui-input-container">
        <input {...inputProps} ref={ref} className={classes} placeholder=" " value={value ?? props.value ?? ''} onChange={handleChange} />
        {props.placeholder && <label className="jetui-input-label">{props.placeholder}</label>}
        {error !== undefined && <i className="material-icons" title={error}>error_outline</i>}
      </div>  
    );
  };
  
  return React.forwardRef(useFormRender);
};

export const Input = FormInput<any>(undefined);