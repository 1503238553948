import React from 'react';
import { format } from 'date-fns';
import { FlowExecution } from '../common/dataTypes/Jetstream';
import { Card } from '../jet-ui/Card/Card';
import { Layout } from '../jet-ui/Layout/Layout';
import { LayoutItem } from '../jet-ui/LayoutItem/LayoutItem';
import { Capsule } from '../jet-ui/Capsule/Capsule';
import { Link } from 'react-router-dom';

interface ExecutionCardProps {
  execution: FlowExecution;
}

export const ExecutionCard: React.FC<ExecutionCardProps> = (props) => {

  const getStatusColor = (status: string) => {
    switch (status) {
      case 'Succeeded':
        return 'good';
      case 'Running':
        return 'warning';
      case 'Canceled':
        return 'neutral';
    }

    return 'error';
  }

  return (
    <Card className="Monitor__RecentTable__Card">
      <Layout>
        <LayoutItem sm={6}>
          <Capsule>{props.execution.flowExecutionId}</Capsule>
        </LayoutItem>
        <LayoutItem sm={6} className="Monitor__RecentTable__Card__Status">
          <Capsule color={getStatusColor(props.execution.status)}>{props.execution.status}</Capsule>
        </LayoutItem>
        <LayoutItem sm={12} className="Monitor__RecentTable__Card__FlowName">
          <h4><Link to={`/execution/${props.execution.flowExecutionId}/detail`}>{props.execution.flowName}</Link></h4>
          <h5>Started By: {props.execution.userId}</h5>            
        </LayoutItem>
      </Layout>
      <Layout>
        <LayoutItem sm={12} md={6}>
          <b>Started</b> | {format(new Date(props.execution.started), 'yyyy-MM-dd HH:mm:ss')}
        </LayoutItem>
        <LayoutItem sm={12} md={6}>
          <b>Finished</b> | {format(new Date(props.execution.started), 'yyyy-MM-dd HH:mm:ss')}
        </LayoutItem>
        <LayoutItem>
          <b>Jobs</b> | {props.execution.structure.jobs.length}
        </LayoutItem> 
      </Layout>
    </Card>
  );
}