import * as oidc from 'oidc-client';
import axios from 'axios';

/**
 * The authorization service for Jetstream.
 */
export default class AuthService {

  /**
   * A promise that is resolved when a user is found.
   */
  public user: Promise<oidc.User>; 

  /**
   * An instance of the OIDC user manager.
   */
  private auth: oidc.UserManager;

  /** A callback that fires when the user is changed. */
  private userChangeCallback: (user: oidc.User) => void;

  /**
   * Creates an instance of the AuthService.
   */
  constructor() {

    const authConfig: oidc.UserManagerSettings = {
      authority: "/",
      client_id: "jetstream-web",
      redirect_uri: `${window.location.protocol}//${window.location.host}/`,
      response_type: "code",
      scope: "openid profile apiv1 email offline_access roles",
      post_logout_redirect_uri: "/login.html",
      prompt: "login",
      checkSessionInterval: 5000,
      silent_redirect_uri: `${window.location.protocol}//${window.location.host}/?silent_login=true`
    };
  
    this.auth = new oidc.UserManager(authConfig);
    this.auth.events.addUserSignedOut(() => this.logout());
    this.auth.events.addSilentRenewError(() => this.logout());

    this.user = new Promise<oidc.User>((resolve, reject) => this.retrieveUser(resolve, reject));
    this.userChangeCallback = () => {};

    oidc.Log.logger = console;
  }

  /**
   * Subscribes to user changes via a callback.
   * @param callback The callback to run when the user is updated.
   */
  public onUserChange(callback: (user: oidc.User) => void): void {
    this.userChangeCallback = callback;
  }

  /**
   * Retreives a user from the OIDC system.
   * @param resolve A function that resolved the user promise.
   * @param reject A function that rejects the user promise.
   */
  private async retrieveUser(resolve: (user: oidc.User) => void, reject: (reason?: any) => void): Promise<void> {
    const search = new URLSearchParams(window.location.search);

    const isSilentLoginFrame = search.get('silent_login') !== null
    const isLoginTypePage = window.location.pathname === '/login' || window.location.pathname === '/signup'
    const isCallback = search.get('code') || search.get('error');

    if (!isLoginTypePage) {
      if (isSilentLoginFrame && isCallback) {
        await new oidc.UserManager({response_mode: 'query'}).signinSilentCallback();
      }
      else if (!isSilentLoginFrame && isCallback) {
        try {
          await new oidc.UserManager({response_mode: 'query'}).signinRedirectCallback();
          window.location.replace(search.get('returnUrl') ?? '/');
        }
        catch(err) {
          console.log(err);
          if (err.error === 'login_required') {
            this.auth.signinRedirect();
          }
        }
      }
      else if (!isSilentLoginFrame && !isCallback && !isLoginTypePage) {
        try {
          let user = await this.auth.getUser();
          if (!user || (user && user.expired)) {
            user = await this.auth.signinSilent();
          }

          this.auth.startSilentRenew();
          this.auth.events.addUserLoaded(this.userChangeCallback);

          this.userChangeCallback(user);
          resolve(user);
        }
        catch(err) {
          await this.auth.removeUser();
          await this.auth.signinRedirect();
        }
      }
    }
  }

  /**
   * Logs out of Jetstream.
   */
  public async logout(): Promise<void> {
    await this.auth.removeUser();
    return this.auth.signoutRedirect();
  }

  /**
   * Logs in locally to Jetstream.
   * @param username The username to log in with.
   * @param password The password to log in with.
   */
  public async login(username: string, password: string): Promise<void> {
    const search = new URLSearchParams(window.location.search);

    const isLocalUrl = (url: string) => new URL(url, window.location.href).host === window.location.host;
    const redirectUrl = search.get('RedirectUrl');

    if (redirectUrl && isLocalUrl(redirectUrl)) {
      const result = await axios.post('/account/login', { username: username, password: password });
      if (result.status === 200) {
        window.location.replace(redirectUrl);
      }
    }
  }

  /**
   * Re-authenticates the user in a popup window.
   */
  public async refresh(): Promise<Oidc.User> {
    var user = await this.auth.signinSilent();
    this.userChangeCallback(user);

    return user;
  }
}