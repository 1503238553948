import React from 'react';
import { Layout } from './Layout/Layout';
import { LayoutItem } from './LayoutItem/LayoutItem';
import { Card } from './Card/Card';
import { Logo } from './Logo/Logo';

export const GridTest: React.FC = () => {
  return (
    <div>
      <Logo includeText size='sm' />
      <Logo includeText size='md' />
      <Logo includeText size='lg' />
      <Layout>     
        <LayoutItem sm={12} md={6} lg={4} xl={3}><Card padding='large'>This is a layout item.</Card></LayoutItem>
        <LayoutItem sm={12} md={6} lg={4} xl={3}><Card padding='large'>This is a layout item.</Card></LayoutItem>
        <LayoutItem sm={12} md={6} lg={4} xl={3}><Card padding='large'>This is a layout item.</Card></LayoutItem>
        <LayoutItem sm={12} md={6} lg={4} xl={3}><Card padding='large'>This is a layout item.</Card></LayoutItem>
        <LayoutItem sm={12} md={6} lg={4} xl={3}><Card padding='large'>This is a layout item.</Card></LayoutItem>
      </Layout>
    </div>
    
  )
};