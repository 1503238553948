import * as signalr from '@microsoft/signalr';
import { JobState } from '../dataTypes/Jetstream';

/**
 * A collection of job states by flow execution ID and
 * job execution ID.
 */
interface ExecutionStatusQueue {
  [index: number]: { [index: number]: JobState };
}

/**
 * A collection of subscription callbacks by flow execution ID.
 */
interface StatusListenerQueue {
  [index: number]: (states: { [index: number]: JobState }) => void;
}

/**
 * A service that receives job state updates from the Jetstream system.
 */
export class JobStateBus {

  /** The connection to the Jetstream system. */
  public static connection: signalr.HubConnection

  /** The cached collection of job state updates. */
  private static queue: ExecutionStatusQueue = {};

  /** The collection of subscribed listener callbacks. */
  private static listeners: StatusListenerQueue = {};

  /** Creates an instance of the JobStateBus. */
  constructor() {
    if (!JobStateBus.connection) {
      JobStateBus.connection = new signalr.HubConnectionBuilder()
        .withUrl("/jobState")
        .withAutomaticReconnect()
        .build();

      JobStateBus.connection.on('jobUpdate', (jobState: JobState) => {
        if (!JobStateBus.queue[jobState.flowExecutionId]) {
          JobStateBus.queue[jobState.flowExecutionId] = {};
        }

        JobStateBus.queue[jobState.flowExecutionId][jobState.jobExecutionId] = jobState;
        if (JobStateBus.listeners[jobState.flowExecutionId]) {
          let listener = JobStateBus.listeners[jobState.flowExecutionId];
          listener(Object.assign({}, JobStateBus.queue[jobState.flowExecutionId]));
        }
      });

      JobStateBus.connection.start();
    }
  }

  /**
   * Subscribes to the job state bus and returns the current state of a flow execution.
   * @param flowExecutionId The ID of the execution to subscribe to.
   * @param action The callback to execute when a new job state update is received.
   */
  public subscribe(flowExecutionId: number, action: (states: { [index: number]: JobState }) => void): { [index: number]: JobState } {
    JobStateBus.listeners[flowExecutionId] = action;
    return JobStateBus.queue[flowExecutionId] ?? {};
  }

  /**
   * Unsubscribes from the job state bus.
   * @param flowExecutionId The ID of the execution to unsubscribe from.
   */
  public unsubscribe(flowExecutionId: number) {
    delete JobStateBus.listeners[flowExecutionId];
  }
}
