import React, { Fragment } from 'react';
import { Formic, UseForm, ToolButton, Status, Dialog, FormAction } from '..';

interface EditPanelProps<T> {
  className?: string;
  state: EditPanelState<T>;
  onSave: (state: Partial<Formic<T>>) => Promise<void>;
  form: UseForm<T>;
  addLabel?: string;
  saveLabel?: string;
  modelLabel: string;
  actions?: FormAction<T>[];
}

export declare type EditPanelState<T> = {
  value: Partial<Formic<T>>;
  open: boolean;
  style?: 'add' | 'edit';
  status?: string;
}

export function EditPanel<T>(props: React.PropsWithChildren<EditPanelProps<T>>) {
  const [state, setState] = React.useState(props.state);
  const [propState, setPropState] = React.useState(props.state);

  const Form = props.form;

  React.useEffect(() => {
    if (props.state !== propState) {
      setState(props.state);
      setPropState(props.state);

      Form.replace(props.state.value);
    }
  }, [props.state, Form]);
  
  const handleAddClick = React.useCallback(() => {
    const newState: EditPanelState<T> = {value: {}, open: true, style: 'add'};
    setState(newState);
    Form.replace(newState.value);
  }, [Form]);
  
  const handleFormSubmit = React.useCallback(async (val: Partial<Formic<T>>) => {
    await props.onSave(val);
    setState(prev => ({...prev, open: false}));
  }, [props.onSave]);

  const actions = React.useMemo((): FormAction<T>[] => {
    const actions: FormAction<T>[] = [
      {type: 'submit', label: props.saveLabel ?? 'Save'},
      {type: 'button', label: 'Cancel', onClick: async () => setState(prev => ({...prev, open: false}))}
    ];

    if (props.actions) {
      actions.unshift(...props.actions);
    }

    return actions;
  }, [props.saveLabel, props.actions]);

  return (
    <Fragment>
      <ToolButton icon="add" onClick={handleAddClick}>{props.addLabel ?? 'Add'}</ToolButton>
      <Status type='good' open={state.status !== undefined} onClose={() => setState(prev => ({...prev, status: undefined}))}>{state.status}</Status>
      <Dialog className={props.className} open={state.open} onClose={() => setState(prev => ({...prev, open: false}))}>
        <h3>{state.style === 'add' ? 'Add' : 'Edit'} {props.modelLabel}</h3>
        <Form.Form onSubmit={handleFormSubmit} actions={actions}>
          {props.children}
        </Form.Form>
      </Dialog>
    </Fragment>
  );
}