import React from 'react';
import axios from 'axios';
import { useAuth } from '../../common/auth/use-auth';
import { Input, Spinner, Status, StatusType } from '../../jet-ui';

/** A component that handles updating the user's profile details */
export const UserDetails: React.FC = () => {

  const auth = useAuth();
  const userProfile = auth.user?.profile;

  const [fullName, setFullName] = React.useState<string | undefined>();
  const [email, setEmail] = React.useState<string | undefined>();

  const [updating, setUpdating] = React.useState(false);
  const [status, setStatus] = React.useState<{type: StatusType, message: string}>({type: 'neutral', message: ''});

  React.useEffect(() => {
    setFullName(userProfile?.name);
    setEmail(userProfile?.email);
  }, [userProfile]);

  /**
   * Handles the user details update being submitted.
   * @param e The click event from the update button.
   */
  const handleDetailsUpdate = (e: React.MouseEvent<HTMLButtonElement>) => {
    setUpdating(true);
    setStatus(prev => ({...prev, message: ''}));

    axios.post('/account/details', { name: fullName, email: email }, auth.getRequestConfig())
      .then(result => {
        auth.service.refresh()
        .then(() => setStatus({type: 'good', message: 'User details updated successfully.'}))
        .catch(() => setStatus({type: 'error', message: 'An error occurred updating the user details.'}))
        .finally(() => { 
          setUpdating(false);          
        });
      }).catch(() => {
        setStatus({type: 'error', message: 'An error occurred updating the user details.'});
        setUpdating(false);
      });
  };

  return (
    <div className="Profile__Section">
      <h2>User Details</h2>
      <Input name="fullname" placeholder="Full Name" value={fullName} onChange={e => setFullName(e.target.value)} />
      <Input name="email" placeholder="EMail Address" value={email} onChange={e => setEmail(e.target.value)} />
      <button onClick={handleDetailsUpdate} disabled={updating}>Update</button>
      {updating && <Spinner size={24} />}
      <Status type={status.type} open={status.message !== ''} onClose={() => setStatus(prev => ({...prev, message: ''}))}>{status.message}</Status>
    </div>
  );
};