import React, { Fragment } from 'react';
import axios from 'axios';
import { Column, Cell } from 'react-table';
import { useAuth } from '../../../common/auth/use-auth';
import { User, ApiResult } from '../../../common/dataTypes/Jetstream';
import { ConfirmAction, ToolButton, SelectFilter, Breakpoint, Small, GlobalSearch, Card, Layout, LayoutItem, Medium, JetTable, EditPanel, EditPanelState, useForm, Formic, ValidationResult } from '../../../jet-ui';

import './Users.css';

type UserForm = Omit<User, 'isActive' | 'roles'>;

export const Users: React.FC = () => {

  const [users, setUsers] = React.useState<User[]>([]);
  const [editPanelState, setEditPanelState] = React.useState<EditPanelState<UserForm>>({value: {}, open: false});

  const [loading, setLoading] = React.useState(true);
  const [filter, setFilter] = React.useState('');
  
  const auth = useAuth();
  const Form = useForm(editPanelState.value);

  const fetchUsers = React.useCallback(async () => {
    setLoading(true);
    const result = await axios.get<ApiResult<User[]>>('/api/users', auth.getRequestConfig());
    setUsers(result.data.data.map(user => {
      delete user.password;
      delete user.salt;
      delete user.confirmPassword;

      return user;
    }));
    setLoading(false);
  }, [auth]);

  React.useEffect(() => {
    fetchUsers();
  }, [fetchUsers]);

  const deleteUser = React.useCallback(async (user: User, confirmed: boolean) => {
    if (confirmed) {
      setLoading(true);

      await axios.delete<ApiResult<User[]>>(`/api/users/${encodeURIComponent(user.userId)}`, auth.getRequestConfig());
      setEditPanelState(prev => ({...prev, status: `User ${user.userId} deleted.`}));

      await fetchUsers();
      setLoading(false);
    }
  }, [fetchUsers]);

  const hasAdminRole = React.useCallback((user: User) => user.roles?.find(x => x.roleId === 'admin') !== undefined, []);

  const toggleAdmin = React.useCallback(async (user: User, confirmed: boolean) => {
    if (confirmed) {
      setLoading(true);

      try {
        if (!hasAdminRole(user)) {
          const result = await axios.post<ApiResult<any>>(`/api/users/${encodeURIComponent(user.userId)}/roles`, JSON.stringify("admin"), auth.getRequestConfig({"Content-Type": "application/json"}));
          setEditPanelState(prev => ({...prev, status: result.data.message}));
        }
        else {
          const result = await axios.delete<ApiResult<any>>(`/api/users/${encodeURIComponent(user.userId)}/roles/admin`, auth.getRequestConfig());
          setEditPanelState(prev => ({...prev, status: result.data.message}));
        }
      }
      finally {
        await fetchUsers();
        setLoading(false);
      }  
    }
  }, [hasAdminRole, fetchUsers]);

  const canDeleteUser = React.useCallback((user: User) => user.userId !== 'admin' && user.userId !== auth.user?.profile.sub, []);
  const canEditUser = React.useCallback((user: User) => canDeleteUser(user) && user.scheme === 'local', []);

  const columns = React.useMemo<Column<User>[]>(() => [
    { 
      id: 'actions',
      width: 96,
      maxWidth: 96,
      Cell: (cell: Cell<User>) => (
        <Fragment>
          <ConfirmAction value={cell.row.original} icon="delete" onAction={deleteUser} title="Delete User" disabled={!canDeleteUser(cell.row.original)}>
            Are you sure you wish to delete user {cell.row.original.userId}?
          </ConfirmAction>
          <ToolButton icon="edit" onClick={() => setEditPanelState({value: cell.row.original, open: true})} collapse disabled={!canEditUser(cell.row.original)} />
          <ConfirmAction value={cell.row.original} icon="admin_panel_settings" onAction={toggleAdmin} title={!hasAdminRole(cell.row.original) ? "Add Admin" : "Remove Admin"} disabled={!canDeleteUser(cell.row.original)}>
            Are you sure you wish to {!hasAdminRole(cell.row.original) ? "add admin for" : "remove admin from"} user {cell.row.original.userId}?
          </ConfirmAction>
        </Fragment>
      )
    },
    {accessor: 'userId', width: '33%', Header: 'User ID', disableFilters: true},
    {accessor: 'email', width: '33%', Header: 'EMail', disableFilters: true},
    {accessor: 'name', width: '33%', Header: 'Name', disableFilters: true},
    {accessor: 'scheme', Header: 'Scheme', disableGlobalFilter: true, width: 60, Filter: SelectFilter},
    {id: 'isAdmin', Header: 'Admin', disableGlobalFilter: true, Filter: SelectFilter, width: 60, Cell: (cell: Cell<User>) => <span>{hasAdminRole(cell.row.original) ? 'true' : 'false'}</span>},
    {accessor: 'isActive', Header: 'Active', disableGlobalFilter: true, Filter: SelectFilter, width: 60, Cell: ({value}) => <span>{value ? 'true' : 'false'}</span>}
  ], [deleteUser, canDeleteUser, canEditUser, hasAdminRole]);

  const applyFilter = React.useCallback((filter: string, users: User[]) => {
    if (filter !== '') {
      return users.filter(user => 
        user.userId.indexOf(filter) !== -1 ||
        user.email.indexOf(filter) !== -1 ||
        user.name.indexOf(filter) !== -1);
    }

    return users;
  }, []);

  const handleSave = React.useCallback(async (user: Partial<Formic<UserForm>>) => {
    user.scheme = 'local';
    await axios.post('/api/users', {...user, isActive: true}, auth.getRequestConfig());
    
    setEditPanelState(prev => ({...prev, open: false, status: 'User saved successfully.'}));
    await fetchUsers();
  }, [auth, fetchUsers]);

  const validatePassword = (state: Partial<Formic<Omit<User, 'isActive'>>>,
    value: Partial<Formic<Omit<User, 'isActive'>>>[keyof Partial<Formic<Omit<User, 'isActive'>>>]): ValidationResult => state?.password === state?.confirmPassword ?
    {valid: true} : {valid: false, message: 'Passwords must match.'};

  return (
    <div className="Users">
      <h3>Manage Users</h3>
      <Breakpoint>
        <Small>
          <GlobalSearch unfilteredRows={users} value={filter} onChange={value => setFilter(value)} />
          {applyFilter(filter, users).map(user => (
            <Card key={user.userId}>
              <Layout>
                <LayoutItem sm={9}>
                  <h3>{user.userId}</h3>
                </LayoutItem>
                <LayoutItem className="Users__Delete" sm={3}>
                  <ConfirmAction value={user} icon="delete" onAction={deleteUser} title='Delete User' disabled={!canDeleteUser(user)}>
                    Are you sure you wish to delete user {user.userId}?
                  </ConfirmAction>
                  <ToolButton icon="edit" onClick={() => setEditPanelState({value: user, open: true})} collapse disabled={!canEditUser(user)} />
                  <ConfirmAction value={user} icon="admin_panel_settings" onAction={toggleAdmin} title={!hasAdminRole(user) ? "Add Admin" : "Remove Admin"} disabled={!canDeleteUser(user)}>
                    Are you sure you wish to {!hasAdminRole(user) ? "add admin for" : "remove admin from"} user {user.userId}?
                  </ConfirmAction>
                </LayoutItem>
              </Layout>
              <div>Email: {user.email}</div>
              <div>Name: {user.name}</div>
              <div>Scheme: {user.scheme}</div>
              <div>Admin: {hasAdminRole(user) ? 'true' : 'false'}</div>
              <div>Active: {user.isActive ? 'true' : 'false'}</div>
            </Card>
          ))}
        </Small>
        <Medium>
          <JetTable data={users} columns={columns} loading={loading} rowKey={user => user.userId} searchable filterable />
        </Medium>
      </Breakpoint>
      <EditPanel<UserForm> state={editPanelState} addLabel="Add User" modelLabel="User" onSave={handleSave} form={Form}>
        <Form.Input name="userId" placeholder="User ID" required />
        <Form.Input name="email" placeholder="EMail" required />
        <Form.Input name="name" placeholder="Name" required />
        <Form.Input name="password" type="password" placeholder="Password" onValidate={validatePassword} />
        <Form.Input name="confirmPassword" type="password" placeholder="Confirm Password" onValidate={validatePassword} />
      </EditPanel>
    </div>
  );
}