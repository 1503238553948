import React from 'react';
import axios from 'axios';
import { useAuth } from '../../common/auth/use-auth';
import { StatusType, Input, Spinner, Status } from '../../jet-ui';

/** A component that handles updating the user's password. */
export const ChangePassword: React.FC = () => {

  const auth = useAuth();

  const [password, setPassword] = React.useState<string | undefined>();
  const [newPassword, setNewPassword] = React.useState<string | undefined>();
  const [confirmPassword, setConfirmPassword] = React.useState<string | undefined>();

  const [updating, setUpdating] = React.useState(false);
  const [status, setStatus] = React.useState<{type: StatusType, message: string}>({type: 'neutral', message: ''});

  /**
   * Handles the user submitting a password change.
   * @param e The mouse click from the change button.
   */
  const handlePasswordUpdate = (e: React.MouseEvent<HTMLButtonElement>) => {
    setUpdating(true);
    setStatus(prev => ({...prev, message: ''}));

    axios.post('/account/password', { password: password, newPassword: newPassword, confirmPassword: confirmPassword }, auth.getRequestConfig())
      .then(result => {
        auth.service.refresh()
          .then(() => setStatus({type: 'good', message: 'Password changed successfully.'}))
          .catch(() => setStatus({type: 'error', message: 'Error changing password. Please check the current password and ensure the new passwords match.'}))
          .finally(() => {
            setUpdating(false);
            setPassword("");
            setNewPassword("");
            setConfirmPassword("");
          });
      }).catch(() => {
        setStatus({type: 'error', message: 'Error changing password. Please check the current password and ensure the new passwords match.'});
        setUpdating(false)
      });
  };

  return (
    <div className="Profile__Section">
      <h2>Change Password</h2>
      <Input name="current" type="password" placeholder="Current Password" value={password} onChange={e => setPassword(e.target.value)} />
      <Input name="new" type="password" placeholder="New Password" value={newPassword} onChange={e => setNewPassword(e.target.value)} />
      <Input name="confirm" type="password" placeholder="Confirm New Password" value={confirmPassword} onChange={e => setConfirmPassword(e.target.value)} />
      <button onClick={handlePasswordUpdate} disabled={updating}>Change</button>
      {updating && <Spinner size={24} />}
      <Status type={status.type} open={status.message !== ''} onClose={() => setStatus(prev => ({...prev, message: ''}))}>{status.message}</Status>
    </div>
  );
};