import React from 'react';
import * as Router from 'react-router-dom';
import { Route } from 'react-router-dom';
import axios from 'axios';

import { FlowNav } from './flowNav/FlowNav';
import { ApiResult, Flow, FlowExecution } from '../common/dataTypes/Jetstream';
import { FlowDetail } from './flowDetail/FlowDetail';
import { JobTree } from './jobTree/JobTree';
import { FlowExecutions } from './flowExecutions/FlowExecutions';
import { GanttChart } from './ganttChart/GanttChart';
import { Content } from '../jet-ui/Content/Content';
import { Breadcrumb } from '../jet-ui/Breadcrumb/Breadcrumb';
import { Card } from '../jet-ui/Card/Card';
import { JobStateBus } from '../common/jobStateBus/JobStateBus';
import { useAuth } from '../common/auth/use-auth';

/** The route params for this page. */
interface ExecutionPageRouteParams {

  /** The execution ID for the flow. */
  executionId?: string;
}

/**
 * A component that displays the Flow execution information page.
 */
export const ExecutionPage: React.FC = () => {
  const routeParams: ExecutionPageRouteParams = Router.useParams<ExecutionPageRouteParams>();
  const executionId: number | undefined = routeParams.executionId ? parseInt(routeParams.executionId) : undefined;
  const match = Router.useRouteMatch();

  const auth = useAuth();

  const [flow, setFlow] = React.useState<Flow>({
    structure: {
      name: "Loading...",
      flowExecutionId: null,
      jobs: []
    },
    schedule: 'None',
    next: '',
    last: '',
    histogram: []
  });

  const [flowExecution, setFlowExecution] = React.useState<FlowExecution | undefined>();
  React.useRef(new JobStateBus());

  React.useEffect(() => {
    if (flowExecution) {
      const handleUpdate = (flowExecutionId: number, status: string) => {
        if (flowExecutionId === flowExecution.flowExecutionId) {
          setFlowExecution({...flowExecution, status: status});
        }
      };
  
      JobStateBus.connection.on('flowStatusUpdate', handleUpdate);  
      return () => JobStateBus.connection.off('flowStatusUpdate', handleUpdate);
    }
  }, [flowExecution]);

  React.useEffect(() => {

    /**
     * Fetches data about the flow execution from the Jetstream system.
     */
    const fetchData = async () => {
      if (executionId) {
        const result = await axios.get<ApiResult<FlowExecution>>(`/api/executions/${executionId}`, auth.getRequestConfig());
        setFlowExecution(result.data.data);

        const flow: Flow = {
          structure: result.data.data.structure,
          schedule: '',
          next: '',
          last: '',
          histogram: []
        };

        setFlow(flow);
      }
    };

    fetchData();
  }, [executionId]);

  return (
    <Content className="Execution">
      <div>
        <Breadcrumb href="/">Home</Breadcrumb>
        <Breadcrumb href="/flows">Flows</Breadcrumb>
        <Breadcrumb href={`/flow/${encodeURIComponent(flow.structure.name)}/detail`}>{flow.structure.name}</Breadcrumb>
        <Breadcrumb href={`${match.url}/detail`}>Execution {executionId}</Breadcrumb>
      </div>
      <h1>Execution {executionId} - {flow.structure.name} - {flowExecution?.status}</h1>
      <h3>Started By: {flowExecution?.userId}</h3>
      <Card>
        <FlowNav flow={flow} execution={flowExecution} />
        <Route path={`${match.url}/detail`}>
          <FlowDetail flow={flow} flowExecution={flowExecution} />
        </Route>
        <Route path={`${match.url}/tree`}>
          <div className="JobTree">
            <JobTree flow={flow.structure} />
          </div>
        </Route>
        <Route path={`${match.url}/timing`}>
          <GanttChart flowExecutionId={executionId} />
        </Route>
        <Route path={`${match.url}/executions`}>
          <div>
            <FlowExecutions flowName={flow.structure.name} />
          </div>
        </Route>
      </Card>
    </Content>
  )
}
