import React from 'react';
import axios from 'axios';
import { NotificationDefinition, ApiResult } from '../../common/dataTypes/Jetstream';
import { Column, Cell } from 'react-table';
import { useAuth } from '../../common/auth/use-auth';
import { ConfirmAction, ToolButton, EditPanelState, JetTable, SelectFilter, useForm, EditPanel, Formic, SelectOption, FormAction } from '../../jet-ui';

export const WebhookNotifications: React.FC = () => {
  const [webhookNotifications, setWebhookNotifications] = React.useState<NotificationDefinition[]>([]);
  const [loading, setLoading] = React.useState(true);
  const [editPanelState, setEditPanelState] = React.useState<EditPanelState<NotificationDefinition>>({value: {}, open: false});

  const auth = useAuth();
  const Form = useForm(editPanelState.value);

  const fetchNotifications = React.useCallback(async () => {
    setLoading(true);

    const result = await axios.get<ApiResult<NotificationDefinition[]>>('/api/notifications', auth.getRequestConfig());
    setWebhookNotifications(result.data.data.filter(d => d.type === 'WebHook'));

    setLoading(false);
  }, []);

  React.useEffect(() => {
    fetchNotifications();
  }, [fetchNotifications]);

  const columns: Column<NotificationDefinition>[] = [
    { id: 'actions',
      width: 80,
      maxWidth: 80,
      Cell: (cell: Cell<NotificationDefinition>) => (
      <React.Fragment>
        <ConfirmAction value={cell.row.original} icon="delete" onAction={handleNotificationDeleted} title='Remove Notification'>
          Are you sure you wish to remove notification for {cell.row.original.destination}?
        </ConfirmAction>
        <ToolButton icon="edit" onClick={() => setEditPanelState({value: cell.row.original, open: true})} collapse />
      </React.Fragment>
    )},
    { Header: 'Post', accessor:'destination', width: '80%', disableFilters: true},
    { Header: 'On', accessor: 'event', width: 120, disableGlobalFilter: true, Filter: SelectFilter }
  ];

  const handleNotificationAdd = async (notification: Partial<Formic<NotificationDefinition>>) => {
    notification.type = "WebHook";
    await axios.post("/api/notifications", notification, auth.getRequestConfig());
    setEditPanelState(prev => ({...prev, open: false, status: `Webhook notification saved.`}));
    await fetchNotifications();
  };

  const handleNotificationDeleted = async (data: NotificationDefinition) => {
    var notification = data as NotificationDefinition;
    await axios.delete(`/api/notifications/${notification.notificationDefinitionId}`, auth.getRequestConfig());
    setEditPanelState(prev => ({...prev, open: false, status: `Webhook notification removed.`}));
    await fetchNotifications();
  };

  const testWebhook = async (data: Partial<Formic<NotificationDefinition>>) => {
    if (data.payload && typeof data.payload === 'string') {
      const replacedPayload = data.payload
        .replace(/{{name}}/g, 'Test Flow')
        .replace(/{{status}}/g, 'Succeeded')
        .replace(/{{link}}/g, `${window.location.protocol}//${window.location.host}/executions/1/detail`)
        .replace(/{{executionId}}/g, '1')
        .replace(/{{numJobs}}/g, '1')
        .replace(/{{timestamp}}/g, new Date().toISOString());

      await axios.post('/api/notifications/test', { destination: data.destination, payload: replacedPayload }, auth.getRequestConfig());
    }
  };

  const options: SelectOption[] = [
    {value: 'Success', label: 'Success'}, 
    {value: 'Failure', label: 'Failed'},
    {value: 'Completion', label: 'Any Completed'}
  ];

  const actions: FormAction<NotificationDefinition>[] = [
    {type: 'button', label: 'Test Webhook', onClick: testWebhook}
  ];

  return (
    <div>
      <h3>Webhook Notifications</h3>
      <JetTable data={webhookNotifications} columns={columns} loading={loading} filterable searchable />
      <EditPanel<NotificationDefinition> className="Notifications__Webhook__Dialog" form={Form} state={editPanelState} onSave={handleNotificationAdd}
        modelLabel="Webhook Notification" addLabel="Add Webhook" actions={actions}>
      <p>
        <Form.OptionSelect name="event" placeholder="Notify On" options={options} required />
        <Form.Input name="destination" placeholder="POST To" required />
        <Form.TextBox name="payload" placeholder="Payload" required rows={30} />
        Add a payload to POST to the webhook endpoint. Valid tags include:
        <ul>
          <li><b>{"{{name}}"}</b> - The name of the flow that has completed.</li>
          <li><b>{"{{status}}"}</b> - The completion status of the flow.</li>
          <li><b>{"{{link}}"}</b> - A link to the flow execution details page.</li>
          <li><b>{"{{executionId}}"}</b> - The ID of the flow execution.</li>
          <li><b>{"{{numJobs}}"}</b> - The number of jobs in the flow.</li>
          <li><b>{"{{timestamp}}"}</b> - The timestamp of the completion.</li>
        </ul>
      </p>
    </EditPanel>
    </div>
  );
}