import React from 'react';
import * as ReactTable from 'react-table';
import { FormInput, Input } from '../Input/Input';

interface GlobalSearchProps<T> {
  unfilteredRows: T[];
  onChange: (value: string) => void;
  value: string | undefined;
};

export const GlobalSearch = <T extends {}>(props: React.PropsWithChildren<GlobalSearchProps<T>>) => {

  const [value, setValue] = React.useState(props.value);
  const onChange = ReactTable.useAsyncDebounce<(val: string) => void>(val => props.onChange(val), 200);

  const handleOnChange = React.useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
    onChange(e.target.value);
  }, []);

  return (
    <div className="jetui-table-search">
      <Input name="search" value={value} onChange={handleOnChange} /><i className="material-icons">search</i>
    </div>
  );
};