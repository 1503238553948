import React from 'react';
import { useRouteMatch, Route } from 'react-router-dom';

import './Admin.css';
import { ToolButton } from '../jet-ui/ToolButton/ToolButton';
import { Invite } from './userManagement/Invite/Invite';
import { Notifications } from './notifications/Notifications';
import { Content } from '../jet-ui/Content/Content';
import { Breadcrumb } from '../jet-ui/Breadcrumb/Breadcrumb';
import { Card } from '../jet-ui/Card/Card';
import { Users } from './userManagement/Users/Users';
import { Breakpoint, Small, Medium, Large } from '../jet-ui/Breakpoints/Breakpoint';
import { GlobalRetentionPolicy } from './GlobalRetentionPolicy/GlobalRetentionPolicy';
import { NuGetSources } from './NuGetSources/NuGetSources';

/**
 * A component that manages the Admin page.
 */
export const Admin: React.FC = () => {

  const match = useRouteMatch();

  return (
    <Content className="Admin">
      <div>
        <Breadcrumb href="/">Home</Breadcrumb>
        <Breadcrumb href="/admin/">Admin</Breadcrumb>
      </div>
      <h1>Admin</h1>
      <Card>
        <div className="Admin__Menu">
          <Breakpoint>
            <Small>
              <ToolButton to="users" icon="account_circle" />
              <ToolButton to="notifications" icon="drafts" />
              <ToolButton to="retention" icon="delete_sweep" />
              <ToolButton to="nuget" icon="archive" />
            </Small>
            <Large>
              <ToolButton to="users" icon="account_circle">User Management</ToolButton>
              <ToolButton to="notifications" icon="drafts">Notifications</ToolButton>
              <ToolButton to="retention" icon="delete_sweep">Retention Policy</ToolButton>
              <ToolButton to="nuget" icon="archive">NuGet Sources</ToolButton>
            </Large>          
          </Breakpoint>    
        </div>
        <div className="Admin__Section">
          <Route path={`${match.url}/(users)?`} exact>
            <div>
              <h1>User Management</h1>
              <Invite />
              <Users />
            </div>        
          </Route>     
          <Route path={`${match.url}/notifications`}>
            <Notifications />
          </Route>
          <Route path={`${match.url}/retention`}>
            <GlobalRetentionPolicy />
          </Route>
          <Route path={`${match.url}/nuget`}>
            <NuGetSources />
          </Route>
        </div>
      </Card>    
    </Content>
  )
}