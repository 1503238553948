import React from 'react';
import { FlowStructure, JobState } from '../../common/dataTypes/Jetstream';

import './FlowGraph.css';

// @ts-ignore
import * as d3 from 'd3';
// @ts-ignore
import * as d3dag from 'd3-dag';

/**
 * A DAG layout representation of a job graph.
 */
interface JobDag {

  /** The ID of the job within the flow. */
  id: number;

  /** The ID of the job execution. */
  jobExecutionId: number;

  /** The type of the job. */
  type: string;

  /** The IDs of the children of this job. */
  children: number[];

  /** The IDs of the parents of this job. */
  parentIds: number[];
}

/**
 * Properties on the FlowGraph component.
 */
interface FlowGraphProps {

  /** The structure of the flow to graph. */
  flow: FlowStructure

  /** The states of the jobs in the flow. */
  states: { [index: number]: JobState }

  /** A callback that runs when a job is clicked in the graph. */
  onJobSelect: (jobId: number) => void;
}

/**
 * A component that renders a topological graph of the jobs in
 * a flow.
 * @param props The properties of the component.
 */
export const FlowGraph: React.FC<FlowGraphProps> = (props) => {

  const graphContainer = React.useRef<SVGSVGElement | null>(null);
  const [layout, setLayout] = React.useState<any>();

  const numJobs = props.flow.jobs.length ?? 0;
  const onJobSelect = props.onJobSelect;

  React.useEffect(() => {

    /**
     * Calculates the layout of the graph.
     */
    const calculateLayout = () => {
      const calculatedLayout = d3dag.zherebko()
        .size([100, Math.min(numJobs * 50, 1600)]);

      const dag = structureToDag(props.flow);
      calculatedLayout(dag);

      setLayout(dag);
    };

    if (props.flow && graphContainer.current && numJobs) {
      calculateLayout();
    }
  }, [props.flow, numJobs]);

  React.useEffect(() => {

    /**
     * Places the graph elements into the SVG.
     */
    const placeGraphElements = () => {
      const svg = d3.select(graphContainer.current);
        svg.selectAll('*').remove();

        svg.attr('viewBox', '-20 -20 ' + (Math.min(numJobs * 50, 1600) + 40) + ' 140');

        const lineRenderer = d3.line()
          .curve(d3.curveMonotoneX)
          .x((d: any) => d.y)
          .y((d: any) => d.x);

        svg.append('g')
          .selectAll('path')
          .data(layout.links())
          .enter()
          .append('path')
          .attr('d', (links: any) => lineRenderer(links.data.points))
          .attr('fill', 'none')
          .attr('stroke-width', 3)
          .attr('stroke', '#ddd');

        const nodes = svg.append('g')
          .selectAll('g')
          .data(layout.descendants())
          .enter()
          .append('g')
          .attr('transform', (d: any) => `translate(${d.y}, ${d.x})`);

        nodes.append('circle')
          .attr('r', 16)
          .attr('fill', 'steelblue')
          .attr('cursor', 'pointer')
          .on('click', (d: any, i: number) => onJobSelect(d.data.id));

        nodes.append('text')
          .text((d: any) => d.id)
          .attr('font-size', '14px')
          .attr('text-anchor', 'middle')
          .attr('alignment-baseline', 'middle')
          .attr('fill', 'white')
          .attr('cursor', 'pointer')
          .on('click', (d: any, i: number) => onJobSelect(d.data.id));
    };

    if (layout) {
      placeGraphElements();   
    }
  }, [layout, numJobs, onJobSelect]);

  React.useEffect(() => {

    /**
     * Updates the node state colors and classes.
     */
    const updateNodeStates = () => {
      const svg = d3.select(graphContainer.current);

      const nodes = svg.selectAll('circle')
        .select(function (this: any) { 
          return this.parentNode; 
        })
        .data(layout.descendants());

      const nonRunningNodes = nodes.selectAll('circle:not(.FlowGraph--pulse');
      const runningNodes = nodes.selectAll('circle.FlowGraph--pulse');

      nonRunningNodes.attr('fill', (d: any) => {
        const state = props.states[d.data.jobExecutionId];

        if (state && state.jobStatus === 'Running') {
          return 'orange';
        }

        if (state && state.jobStatus === 'Succeeded') {
          return 'green';
        }

        if (state && state.jobStatus === 'Failed') {
          return 'red';
        }

        if (state && state.jobStatus === 'Canceled') {
          return '#888';
        }

        return 'steelblue';
      });

      nodes.filter(function (this: any, d: any) {
        const state = props.states[d.data.jobExecutionId];

        //Find nodes that are running but don't have a running circle created yet
        if (state && state.jobStatus === 'Running') {
          const alreadyRunning = d3.select(this).select('circle.FlowGraph--pulse');
          if (alreadyRunning.empty()) {
            return true;
          }
        }

        return false;
      })
      .insert('circle', 'text')
      .attr('r', 16)
      .attr('class', 'FlowGraph--pulse');

      runningNodes.filter((d: any) => props.states[d.data.jobExecutionId] && props.states[d.data.jobExecutionId].jobStatus !== 'Running')
        .remove();

    };

    if (layout) {
      updateNodeStates();
    }  
  }, [layout, props.states]);

  /**
   * Creates a d3-dag consumable dag from a flow structure.
   * @param structure The flow structure.
   */
  const structureToDag = (structure: FlowStructure) => {

    var stratify = d3dag.dagStratify();

    var jobs = structure.jobs.map(job => ({
      id: job.id,
      jobExecutionId: job.jobExecutionId,
      type: job.type,
      children: job.children,
      parentIds: []
    } as JobDag));

    jobs.forEach(job => {
      job.children.forEach(child => {
        jobs[child].parentIds.push(job.id);
      })
    });

    return stratify(jobs);
  };

  return (
    <svg viewBox="-20 -20 1640 140" ref={graphContainer} />
  )
}
