import React from 'react';
import * as Router from 'react-router-dom';
import axios from 'axios';
import { FlowExecution, ApiResult } from '../../common/dataTypes/Jetstream';
import { useAuth } from '../../common/auth/use-auth';

import '../../flowTable/FlowTable.css';
import './FlowExecutions.css';
import { Column, Cell } from 'react-table';
import { JetTable, Capsule } from '../../jet-ui';
import { format } from 'date-fns';

/**
 * Properties on the FlowExecutions component.
 */
interface FlowExecutionsProps {

  /** The name of the flow. */
  flowName: string;
}

/**
 * A component that displays a table of executions for a given flow.
 * @param props The properties of the component.
 */
export const FlowExecutions: React.FC<FlowExecutionsProps> = (props: FlowExecutionsProps) => {

  const [executions, setExecutions] = React.useState<FlowExecution[]>([]);
  const [isLoading, setIsLoading] = React.useState(true);
  const history = Router.useHistory();

  const auth = useAuth();

  /**
   * Gets a collection of flow executions from Jetstream.
   */
  const fetchExecutions = React.useCallback(async () => {
    setIsLoading(true);
    try {
      const result = await axios.get<ApiResult<FlowExecution[]>>(`/api/executions/flow?flowName=${encodeURI(props.flowName)}`, auth.getRequestConfig());
      setExecutions(result.data.data);
    }
    finally {
      setIsLoading(false);
    }  
  }, [props.flowName]);

  React.useEffect(() => {
    fetchExecutions();
  }, [fetchExecutions]);

  const getStatusColor = React.useCallback((status: string) => {
    switch (status) {
      case 'Succeeded':
        return 'good';
      case 'Running':
        return 'warning';
      case 'Canceled':
        return 'neutral';
    }

    return 'error';
  }, []);

  const columns = React.useMemo((): Column<FlowExecution>[] => [
    { Header: 'Execution ID', accessor: 'flowExecutionId', width: '25%', Cell: (cell: Cell<FlowExecution>) => <Router.Link to={`/execution/${cell.value}/detail`}>{cell.value}</Router.Link> },
    { Header: 'Started', accessor: 'started', width: '25%', Cell: ({ value }) => format(new Date(value), 'yyyy-MM-dd HH:mm:ss') },
    { Header: 'Ended', accessor: 'ended', width: '25%', Cell: ({ value }) => format(new Date(value), 'yyyy-MM-dd HH:mm:ss') },
    { Header: 'By', accessor: 'userId', width: '25%' },
    {
      Header: 'Status',
      accessor: 'status',
      width: '100px',
      Cell: ({ value }) => (<Capsule color={getStatusColor(value)}>{value}</Capsule>)
    }
  ], []);

  return (
    <div className="FlowExecutions">
      <JetTable data={executions} columns={columns} loading={isLoading} rowsPerPage={20} />
    </div>
  );
}
