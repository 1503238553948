import React from 'react';
import axios from 'axios';
import { Column, Cell } from 'react-table';
import { NotificationDefinition, ApiResult } from '../../common/dataTypes/Jetstream';
import { useAuth } from '../../common/auth/use-auth';
import { Formic, useForm, ConfirmAction, SelectFilter, SelectOption, JetTable, EditPanel, EditPanelState } from '../../jet-ui';

export const EMailNotifications: React.FC = () => {
  const [emailNotifications, setEmailNotifications] = React.useState<NotificationDefinition[]>([]);
  const [addEditState, setAddEditState] = React.useState<EditPanelState<NotificationDefinition>>({value: {}, open: false});
  const [loading, setLoading] = React.useState(true);

  const auth = useAuth();

  const Form = useForm<NotificationDefinition>(addEditState.value);

  const fetchNotifications = React.useCallback(async () => {
    const result = await axios.get<ApiResult<NotificationDefinition[]>>('/api/notifications', auth.getRequestConfig());
    setEmailNotifications(result.data.data.filter(d => d.type === 'Email'));
    setLoading(false);
  }, []);

  React.useEffect(() => {
    fetchNotifications();
  }, [fetchNotifications]);

  const handleNotificationDeleted = async (data: NotificationDefinition, confirmed: boolean) => {
    if (confirmed) {
      await axios.delete(`/api/notifications/${data.notificationDefinitionId}`, auth.getRequestConfig());

      setAddEditState(prev => ({...prev, status: `Notification for ${data.destination} removed.`}));
      await fetchNotifications();
    }
  };

  const handleNotificationAdd = async (notification: Partial<Formic<NotificationDefinition>>) => {
    notification.type = 'EMail';
    await axios.post("/api/notifications", notification, auth.getRequestConfig());

    setAddEditState(prev => ({...prev, open: false, status: `Notification for ${notification.destination} added.`}));

    await fetchNotifications();
  };

  const emailColumns: Column<NotificationDefinition>[] = [
    { id: 'actions',
      width: 32,
      Cell: (cell: Cell<NotificationDefinition>) => (
        <ConfirmAction value={cell.row.original} icon="delete" onAction={handleNotificationDeleted} title='Remove Notification'>
          Are you sure you wish to remove notification for {cell.row.original.destination}?
        </ConfirmAction>) },
    { Header: 'EMail', accessor: 'destination', width: '100%', disableFilters: true},
    { Header: 'On', accessor: 'event', width: 120, Filter: SelectFilter, disableGlobalFilter: true}
  ];

  const options: SelectOption[] = [
    {value: 'Success', label: 'Success'}, 
    {value: 'Failure', label: 'Failed'},
    {value: 'Completion', label: 'Any Completed'}
  ];

  return (
    <div>
      <h3>EMail Notifications</h3>  
      <JetTable data={emailNotifications} columns={emailColumns} filterable searchable loading={loading} />
      <EditPanel<NotificationDefinition> form={Form} addLabel='Add EMail Notification' modelLabel='EMail Notification'
        onSave={handleNotificationAdd} state={addEditState}>
        <Form.Input name="destination" placeholder="EMail Address" required />
        <Form.OptionSelect name="event" options={options} placeholder="On" required />
      </EditPanel>
    </div> 
  );
}