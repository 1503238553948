import React from 'react';
import { TableAction } from '../jet-ui/TableAction/TableAction';
import { JetTable } from '../jet-ui/JetTable/JetTable';
import { Column } from 'react-table';
import { Link } from 'react-router-dom';
import { format } from 'date-fns';
import { ConfirmAction, ToolButton } from '../jet-ui';
import { Flow } from '../common/dataTypes/Jetstream';

interface UpcomingTableProps {
  data: any[];
  onRun: (name: string) => Promise<void>;
  onRemove: (name: string) => Promise<void>;
  loading?: boolean;
};

export const UpcomingTable: React.FC<UpcomingTableProps> = (props) => {

  const {onRun, onRemove} = props;
  const columns = React.useMemo((): Column<any>[] => [
    {
      Header: '',
      id: 'actions',
      accessor: 'name',
      Cell: cell => (
        <div>
          <ToolButton icon="play_circle_outline" onClick={() => onRun(cell.value)} collapse />
          <ConfirmAction icon="event_busy" title="Remove From Schedule" value={cell.row.original} onAction={(value: any, confirmed) => confirmed && onRemove(value.name)}>
            Are you sure you want to remove {cell.row.original.name} from the schedule?
          </ConfirmAction>
        </div>
      ),
      width: 64
    },
    {
      Header: 'Flow',
      accessor: 'name',
      width: '50%',
      Cell: cell => (<Link to={`/flow/${encodeURIComponent(cell.value)}/detail`}>{cell.value}</Link>)
    },
    {
      Header: 'Scheduled At',
      accessor: 'scheduled',
      width: '45%',
      Cell: cell => format(new Date(cell.value), 'yyyy-MM-dd HH:mm:ss')
    } 
  ], [onRun, onRemove]);

  return (
    <JetTable data={props.data} columns={columns} emptyMessage="No flows currently scheduled" loading={props.loading} />
  );
};
