import { format } from 'date-fns';
import React from 'react';
import { Link } from 'react-router-dom';
import { Column } from 'react-table';
import { FlowExecution } from '../common/dataTypes/Jetstream';
import { Breakpoint, Large, Small } from '../jet-ui/Breakpoints/Breakpoint';
import { Capsule } from '../jet-ui/Capsule/Capsule';
import { Card } from '../jet-ui/Card/Card';
import { JetTable } from '../jet-ui/JetTable/JetTable';
import { Spinner } from '../jet-ui/Spinner/Spinner';
import { ExecutionCard } from './ExecutionCard';

interface RecentTableProps {
  data: FlowExecution[];
  rowsPerPage?: number;
  pages: number;
  onPageChange: (pageIndex: number) => void;
  pageIndex: number;
  loading?: boolean;
};

export const RecentTable: React.FC<RecentTableProps> = (props) => {

  const getStatusColor = React.useCallback((status: string) => {
    switch (status) {
      case 'Succeeded':
        return 'good';
      case 'Running':
        return 'warning';
      case 'Canceled':
        return 'neutral';
    }

    return 'error';
  }, []);

  const columns = React.useMemo((): Column<FlowExecution>[] => [
    {
      Header: 'Flow',
      accessor: 'flowName',
      width: '25%',
      Cell: cell => (<Link to={`/execution/${cell.row.original.flowExecutionId}/detail`}>{cell.value}</Link>)
    },
    {
      Header: 'ID',
      accessor: 'flowExecutionId',
      width: '100px'
    },
    {
      Header: 'Jobs',
      accessor: (execution) => execution.structure.jobs.length,
      width: '60px'
    },
    {
      Header: 'Started',
      accessor: 'started',
      width: '25%',
      Cell: ({ value }) => format(new Date(value), 'yyyy-MM-dd HH:mm:ss')
    },
    {
      Header: 'Finished',
      accessor: 'ended',
      width: '25%',
      Cell: ({ value }) => format(new Date(value), 'yyyy-MM-dd HH:mm:ss')
    },
    {
      Header: 'By',
      accessor: 'userId',
      width: '25%'
    },
    {
      Header: 'Status',
      accessor: 'status',
      width: '100px',
      Cell: ({ value }) => (<Capsule color={getStatusColor(value)}>{value}</Capsule>)
    }
  ], []);

  return (
    <Breakpoint>
      <Small>
        {props.loading && <div className="Monitor__Loading"><Spinner /></div>}
        {props.data.map(execution => <ExecutionCard execution={execution} />)}
        {(props.data.length === 0 && !props.loading) && <Card>No recent flows found.</Card>}
      </Small>
      <Large>
        <JetTable data={props.data} columns={columns} emptyMessage="No recent flows found" loading={props.loading} pages={props.pages}
          onManualPageChange={props.onPageChange} rowsPerPage={props.rowsPerPage ?? 20} pageIndex={props.pageIndex} />
      </Large>
    </Breakpoint>
  );
};