import React from 'react';
import clsx from 'clsx';
import { Shade } from '../Shade/Shade';
import './Slider.css';

interface SliderProps {
  open: boolean;
  onClose: () => void;
  className?: string;
}

export const Slider: React.FC<SliderProps> = (props) => {

  const [shadeOpen, setShadeOpen] = React.useState(false);
  const classes = clsx('jetui-slider',
  {
    'jetui-slider-open': props.open,
    'jetui-slider-closed': !props.open
  }, props.className);

  React.useEffect(() => {
    if (props.open) {
      setShadeOpen(true);
    }

    if (!props.open) {
      setShadeOpen(false);
    }
  }, [props.open]);

  return (
    <Shade active={shadeOpen} onClick={props.onClose} darkened>
      <div className={classes}>
        {props.children}
      </div>
    </Shade>
  );
}