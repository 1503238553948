import React from 'react';
import './Timepicker.css';
import { SlideRule } from '../SlideRule/SlideRule';
import { FormInput, Input } from '../Input/Input';

interface TimepickerProps {
  maxInterval?: 'hours' | 'minutes' | 'seconds';
  value?: SelectedTime;
  onChange?: (time: SelectedTime) => void;
};

export interface SelectedTime {
  hour: number;
  minute: number;
  second: number;
}

export const Timepicker: React.FC<TimepickerProps> = (props) => {

  const [hour, setHour] = React.useState(props.value?.hour ?? 0);
  const [minute, setMinute] = React.useState(props.value?.minute ?? 0);
  const [second, setSecond] = React.useState(props.value?.second ?? 0);

  const onChange = props.onChange;

  const toNumber = React.useCallback((value: string) => {
    const number = parseInt(value);
    return { isNumber: !isNaN(number), value: isNaN(number) ? 0 : number };
  }, []);

  const handleHourInputChange = React.useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const num = toNumber(e.target.value);
    if (num.isNumber && num.value >= 0 && num.value <= 23) {
      setHour(num.value);
      onChange && onChange({ hour: num.value, minute: minute, second: second })
    }
  }, [toNumber, onChange]);

  const handleHourSlideChange = React.useCallback(value => {
    setHour(value);
    onChange && onChange({ hour: value, minute: minute, second: second })
  }, [onChange]);

  const handleMinuteInputChange = React.useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const num = toNumber(e.target.value);
    if (num.isNumber && num.value >= 0 && num.value <= 59) {
      setMinute(num.value);
      onChange && onChange({ hour: hour, minute: num.value, second: second })
    }
  }, [toNumber, onChange]);

  const handleMinuteSlideChange = React.useCallback(value => {
    setMinute(value);
    onChange && onChange({ hour: hour, minute: value, second: second })
  }, [onChange]);

  const handleSecondInputChange = React.useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const num = toNumber(e.target.value);
    if (num.isNumber && num.value >= 0 && num.value <= 59) {
      setSecond(num.value);
      onChange && onChange({ hour: hour, minute: minute, second: num.value })
    }
  }, [toNumber, onChange]);

  const handleSecondSlideChange = React.useCallback(value => {
    setSecond(value);
    onChange && onChange({ hour: hour, minute: minute, second: value })
  }, [onChange]);

  const renderHours = () => {
    if (!props.maxInterval || (props.maxInterval && props.maxInterval === 'hours')) {
      return (
        <div>
          <Input name="hour" type="number" size={2} value={hour} onChange={handleHourInputChange} placeholder="Hour" />
          <SlideRule min={0} max={23} value={hour} onChange={handleHourSlideChange} />
        </div>
      );
    }
  };

  const renderMinutes = () => {
    if (!props.maxInterval || (props.maxInterval && props.maxInterval === 'hours') || (props.maxInterval && props.maxInterval === 'minutes')) {
      return (
        <div>
          <Input name="minute" type="number" size={2} value={minute} onChange={handleMinuteInputChange} placeholder="Minute" />
          <SlideRule min={0} max={59} value={minute} onChange={handleMinuteSlideChange} />
        </div>
      );
    }
  };

  return (
    <div className="jetui-timepicker">
      {renderHours()}
      {renderMinutes()}
      <div>
        <Input name="second" type="number" size={2}value={second} onChange={handleSecondInputChange} placeholder="Second" />
        <SlideRule min={0} max={59} value={second} onChange={handleSecondSlideChange} />
      </div>
    </div>
  );
};