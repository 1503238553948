import React from 'react';
import axios from 'axios';
import { OptionSelect, SelectOption } from '../../jet-ui/OptionSelect/OptionSelect';
import { DateInput } from '../../jet-ui/DateInput/DateInput';
import { Timepicker, SelectedTime } from '../../jet-ui/Timepicker/Timepicker';
import { FormInput, Input } from '../../jet-ui/Input/Input';
import cronstrue from 'cronstrue';
import { Spinner } from '../../jet-ui/Spinner/Spinner';
import TimeSpan from '../../common/TimeSpan';
import { useAuth } from '../../common/auth/use-auth';

interface ScheduleFormProps {
  flowName?: string;
  model: any;
  onClose: () => void;
}

export const ScheduleForm: React.FC<ScheduleFormProps> = (props) => {

  const [scheduleType, setScheduleType] = React.useState<string>('');
  const [model, setModel] = React.useState<any>({});

  const [confirming, setConfirming] = React.useState(false);

  const auth = useAuth();

  React.useEffect(() => setModel(props.model), [props.model]);

  const scheduleOptions: SelectOption[] = [
    { label: 'None', value: 'None' },
    { label: 'Hourly', value: 'Hourly' },
    { label: 'Daily', value: 'Daily' },
    { label: 'One Time', value: 'Single' },
    { label: 'Interval', value: 'Recurring' },
    { label: 'Cron', value: 'Cron' }
  ];

  const renderReadableCron = () => {
    if (model.cronExpression) {
      try {
        return cronstrue.toString(model.cronExpression);
      }
      catch { }
    }

    return "No valid cron expression entered.";
  };

  const handleTimeChange = React.useCallback((time: SelectedTime) => {
    setModel((prev: any) => {
      const prevDate = prev.scheduled ? prev.scheduled as Date : new Date();
      return {scheduled: new Date(prevDate.getFullYear(), prevDate.getMonth(), prevDate.getDate(), time.hour, time.minute, time.second)};
    });
  }, []);

  const formMap: {[label: string]: JSX.Element} = {
    'None': (<div>This flow will have its schedule removed.</div>),
    'Hourly': (
      <div>
        <p>This flow will be scheduled every hour at the minute and second specified.</p>
        <Timepicker value={{hour: 0, minute: model.minutes, second: model.seconds}} 
          onChange={time => setModel({minutes: time.minute, seconds: time.second})} maxInterval='minutes' />
      </div>
    ),
    'Daily': (
      <div>
        <p>This flow will be scheduled every day at the hour, minute, and second specified.</p>
        <Timepicker value={{hour: model.hours, minute: model.minutes, second: model.seconds}}
          onChange={time => setModel({hours: time.hour, minutes: time.minute, seconds: time.second})} />
      </div>
    ),
    'Single': (
      <div>
        <p>This flow will be scheduled exactly once on the specified date and time.</p>
        <DateInput onChange={date => setModel({scheduled: date})} value={model.scheduled ? model.scheduled : new Date()} />
        <Timepicker value={model.scheduled} onChange={handleTimeChange} />
      </div>
    ),
    'Recurring': (
      <div>
        <p>This flow will be scheduled every provided interval.</p>
        <Timepicker value={{hour: model.interval?.hours, minute: model.interval?.minutes, second: model.interval?.seconds}}
          onChange={time => setModel({interval: new TimeSpan(time.hour, time.minute, time.second)})} />
      </div> 
    ),
    'Cron': (
      <div>
        <p>This flow will be executed on the provided cron schedule.</p>
        <Input name="cron" type="text" value={model.cronExpression} onChange={e => setModel({cronExpression: e.target.value})} placeholder="Cron Expression" />
        <span>{renderReadableCron()}</span>
      </div>
    )
  };

  const handleConfirm = async () => {
    setConfirming(true);
    if (Object.keys(model).length !== 0 && props.flowName) {

      const data = {
        flowName: props.flowName,
        type: scheduleType,
        schedule: JSON.stringify(model, (key, value) => {
          if (key === 'interval') {
            return value.toString();
          }

          return value;
        })
      };

      await axios.post(`/api/flows/${encodeURIComponent(props.flowName)}/schedule`, data, auth.getRequestConfig());
    }

    setConfirming(false);
    props.onClose();
  };

  return (
    <div>
        <div>
          <OptionSelect name="schedule" options={scheduleOptions} onChange={val => setScheduleType(val?.toString() ?? '')} />
        </div>
        {formMap[scheduleType]}
        <div style={{marginTop: 8}}>
          <button onClick={handleConfirm} disabled={Object.keys(model).length === 0}>
            {confirming && <Spinner size={16} />}Confirm
          </button>
          <button onClick={props.onClose}>Cancel</button>
        </div>    
    </div>
  );
};