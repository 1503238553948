import React from 'react';
import clsx from 'clsx';
import './Card.css';

interface CardProps {
  padding?: 'dense' | 'normal' | 'large';
  className?: string;
}

export const Card: React.FC<CardProps> = (props) => {

  const classes = clsx(
    props.className,
    'jetui-card',
    {
      'jetui-card-normal': !props.padding,
      [`jetui-card-${String(props.padding)}`]: props.padding
    }
  );

  return (
    <div className={classes}>
      {props.children}
    </div>
  );
};