import React from 'react';
import clsx from 'clsx';
import { HistogramEntry } from '../dataTypes/Jetstream';
import { useHistory } from 'react-router-dom';

import './Histogram.css';

/** Properties on the Histogram component. */
interface HistogramProps {

  /** A collection of most recent execution times, in seconds. */
  data: HistogramEntry[];
}

/**
 * An element that displays a SVG of a histogram of job
 * execution times.
 * @param props A collection of runtimes in seconds.
 */
export const Histogram: React.FC<HistogramProps> = (props) => {

  const history = useHistory();

  const maxData = Math.max(...props.data.map(x => x.durationSeconds));
  const scale = maxData / 100;

  const rectColorClass = (status: string) => `jetui-histogram-rect-${status}`;

  return (
    <svg viewBox="0 0 200 60" xmlns="http://www.w3.org/2000/svg" className="jetui-histogram">
      {props.data.map((entry: HistogramEntry, index: number) => 
        <rect className={rectColorClass(entry.status)} key={index} x={index * 20} width="16" height={entry.durationSeconds / scale} y={100 - (entry.durationSeconds / scale)} 
          onClick={() => history.push(`/execution/${entry.flowExecutionId}/detail`)} />)}
    </svg>
  );
}
