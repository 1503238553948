import React from 'react'

import './JobEntry.css';

import { Job, JobState } from '../../../common/dataTypes/Jetstream';
import { Spinner } from '../../../jet-ui/Spinner/Spinner';
import { Dropdown } from '../../../jet-ui/Dropdown/Dropdown';
import { DropdownItem } from '../../../jet-ui/DropdownItem/DropdownItem';

/** Properties on the JobEntry component. */
interface JobEntryProps {

  /** The jobs in the job's flow. */
  jobs: Job[];

  /** The ID of the job within the flow. */
  id: number;

  /** The current state of the job. */
  state: JobState;

  /**
   * A callback fired when this job is selected.
   * @param jobId The ID of the job that was selected.
   */
  onJobSelect: (jobId: number) => void;

  /**
   * A callback fired when a request is made to resume
   * the flow starting at this job.
   * @param jobId The ID of the job that was requested.
   * @param singleJob Whether or not to run only the job requested.
   */
  onJobResume: (jobId: number, singleJob: boolean) => void;

  /** Whether or not the job is selected. */
  selected?: boolean;
}

/**
 * A component that displays a job in the flow details job list.
 * @param props The properties of the component.
 */
export const JobEntry: React.FC<JobEntryProps> = (props) => {

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const job = props.jobs[props.id];

  /** Generates a status circle icon. */
  const statusCircle = () => {
    let status = props.state['jobStatus'];
    if (status && status === 'Running') {
      return (<Spinner size={16} className="JobEntry__StatusDot--running" />);
    }

    if (status && status === 'Succeeded') {
      return (<div className="JobEntry__StatusDot JobEntry__StatusDot--success" />);
    }

    if (status && status === 'Failed') {
      return (<div className="JobEntry__StatusDot JobEntry__StatusDot--failure" />);
    }

    if (status && status === 'Canceled') {
      return (<div className="JobEntry__StatusDot JobEntry__StatusDot--cancel" />);
    }

    return (<div className="JobEntry__StatusDot" />);
  };

  /**
   * Handles when the job menu is opened.
   * @param e The mouse click event that triggered the menu.
   */
  const handleMenuOpen = (e: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(e.currentTarget);
    e.stopPropagation();
  };

  /**
   * Handles when the job menu is closed.
   * @param action The action to perform when the menu is closed.
   * @returns A function that handles the close event and performs the action.
   */
  const handleClose = (action: () => void) => {
    return () => {
      action();
      setAnchorEl(null);
    };
  };

  return (
    <div className={props.selected ? "JobEntry JobEntry--Selected" : "JobEntry"} onClick={() => props.onJobSelect(props.id)}>
      {statusCircle()}
      <div className="JobEntry__Name" title={job.name}>{job.name}</div>
      <div className="JobEntry__Menu" onClick={handleMenuOpen}>
        <i className="material-icons">more_vert</i>
        <Dropdown anchor={anchorEl} open={anchorEl !== null} onClose={handleClose(() => {})}>
          <DropdownItem icon="notes" onClick={() => props.onJobSelect(props.id)}>View Log</DropdownItem>
          <DropdownItem icon="play_circle_outline" onClick={() => props.onJobResume(props.id, false)}>Start From Job</DropdownItem>
          <DropdownItem icon="slideshow" onClick={() => props.onJobResume(props.id, true)}>Run This Job Only</DropdownItem>
        </Dropdown>
      </div>
    </div>
  )
}
