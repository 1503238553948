import React from 'react';
import { Datepicker } from '../Datepicker/Datepicker';
import { formatISO } from 'date-fns';
import { Dropdown } from '../Dropdown/Dropdown';
import './DateInput.css';
import { FormInput, Input } from '../Input/Input';

interface DateInputProps {
  value: Date;
  onChange: (date: Date) => void;
};

export const DateInput: React.FC<DateInputProps> = (props) => {

  const [value, setValue] = React.useState<string>(formatDate(props.value));
  const [dateValue, setDateValue] = React.useState<Date>(props.value);

  const inputRef = React.useRef<HTMLInputElement>(null);
  const [pickerOpen, setPickerOpen] = React.useState(false);

  const validKeyCodes = React.useRef<{[keyCode: number]: boolean}>({
    37: true, //Left
    38: true, //Up
    39: true, //Right
    40: true, //Down
    46: true, //Delete
    8: true, //Backspace
    97: true, //Numpad Numbers
    98: true,
    99: true,
    100: true,
    101: true,
    102: true,
    103: true,
    104: true,
    105: true,
    48: true, //Top row numbers
    49: true,
    50: true,
    51: true,
    52: true,
    53: true,
    54: true,
    55: true,
    56: true,
    57: true,
    189: true //Dash
  });

  React.useEffect(() => {
    setValue(formatDate(props.value));
    setDateValue(props.value);
  }, [props.value]);

  const onChange = props.onChange;
  const handleInputChange = React.useCallback((e: React.ChangeEvent<HTMLInputElement>) => {

    let localValue = e.target.value;
    if (localValue.length > 10) {
      e.preventDefault();
      return;
    }

    setValue(localValue);

    const date = new Date(localValue);
    if (!isNaN(date.valueOf())) {
      setDateValue(date);
      onChange(date);
    }
    else {
      setDateValue(new Date());
      onChange(new Date());
    }

  }, [onChange]);

  const handleKeyDown = React.useCallback((e: React.KeyboardEvent<HTMLInputElement>) => {
    if (!validKeyCodes.current[e.keyCode]) {
      e.preventDefault();
    }
  }, []);

  const handleDatepickerChange = React.useCallback((date: Date) => {
    setValue(formatDate(date));
    setDateValue(date);
    onChange(date);
  }, [onChange]);

  function formatDate(date: Date) {
    return formatISO(date, { representation: 'date' });
  };

  return (
    <React.Fragment>
      <Input name="date" className="jetui-dateinput" type="text" onChange={handleInputChange} onKeyDown={handleKeyDown} value={value} ref={inputRef} onClick={() => setPickerOpen(true)} placeholder="Scheduled Date" />
      <Dropdown anchor={inputRef.current} open={pickerOpen} onClose={() => setPickerOpen(false)} pinned>
        <Datepicker onChange={handleDatepickerChange} value={dateValue} />
      </Dropdown>   
    </React.Fragment>
  );
};