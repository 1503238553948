import React from 'react';
import axios from 'axios';
import { ApiResult, UserInvite } from '../../../common/dataTypes/Jetstream';

import './Invite.css';
import { useAuth } from '../../../common/auth/use-auth';
import { JetTable } from '../../../jet-ui/JetTable/JetTable';
import { Column, ColumnInstance, Cell } from 'react-table';
import { Dialog } from '../../../jet-ui/Dialog/Dialog';
import { ToolButton } from '../../../jet-ui/ToolButton/ToolButton';
import { ConfirmAction } from '../../../jet-ui/JetTable/ConfirmAction';
import { Formic } from '../../../jet-ui/Form/FormSystem';
import { Status } from '../../../jet-ui/Status/Status';
import { useForm, FormAction } from '../../../jet-ui/Form/Form';
import { EditPanel, EditPanelState } from '../../../jet-ui';

export const Invite = () => {

  const [invites, setInvites] = React.useState<UserInvite[]>([]);
  const [loading, setLoading] = React.useState(true);
  const [addEditState, setAddEditState] = React.useState<EditPanelState<UserInvite>>({value: {}, open: false});

  const auth = useAuth();
  const Form = useForm(addEditState.value);

  React.useEffect(() => {
    
    /** Fetches invites from the backend. */
    const fetchInvites = async () => {
      const result = await axios.get<ApiResult<UserInvite[]>>('/api/invites', auth.getRequestConfig());
      setInvites(result.data.data);
      setLoading(false);
    };

    fetchInvites();
  }, []);

  const handleInviteDelete = React.useCallback(async (invite: UserInvite, confirmed: boolean) => {
    if (confirmed) {
      setLoading(true);
      await axios.delete(`/api/invites/${invite.inviteId}`, auth.getRequestConfig());     
      setAddEditState(prev => ({...prev, status: `Invite for ${invite.email} deleted.`}));

      const result = await axios.get<ApiResult<UserInvite[]>>('/api/invites', auth.getRequestConfig());
      setInvites(result.data.data);
      setLoading(false);
    }
  }, []);

  const columns: Column<UserInvite>[] = React.useMemo(() => [
    { 
      id: 'actions',
      width: 32,
      Cell: (cell: Cell<UserInvite>) => (
        <ConfirmAction value={cell.row.original} icon="delete" onAction={handleInviteDelete} title='Delete Invite'>
          Are you sure you wish to delete invite for {cell.row.original.email}?
        </ConfirmAction>)
    },
    { Header: 'EMail', accessor:'email', width: '70%' },
    { Header: 'Issued', accessor: 'issued', width: '30%' }
  ], []);

  const handleAdding = async (invite: Partial<Formic<UserInvite>>): Promise<void> => {
    try {
      await axios.post<ApiResult<undefined>>('/api/invites', JSON.stringify(invite.email), auth.getRequestConfig({"Content-Type": "application/json"}));
      setAddEditState(prev => ({...prev, open: false, status: `Invite sent to ${invite.email}.`}));

      const result = await axios.get<ApiResult<UserInvite[]>>('/api/invites', auth.getRequestConfig());
      setInvites(result.data.data);
    }
    catch (err) {
      throw {message: `Could not send invite to ${invite.email}.`};
    }
  };

  return (
    <div className="Invite">
      <h3>Invite Users</h3>
      <JetTable data={invites} columns={columns} loading={loading} searchable />
      <EditPanel<UserInvite> state={addEditState} addLabel="Send Invite" saveLabel="Send" modelLabel="Invite"
        onSave={handleAdding} form={Form}>
          <Form.Input name="email" required placeholder="EMail Address" />
      </EditPanel>
    </div>
  );
}