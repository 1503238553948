import React from 'react';
import { Column } from 'react-table';
import { ExecutionCard } from './ExecutionCard';
import { Link } from 'react-router-dom';
import { FlowExecution } from '../common/dataTypes/Jetstream';
import { format as dateFormat } from 'date-fns';
import { ConfirmAction, Spinner, Capsule, Breakpoint, Small, Card, Large, JetTable } from '../jet-ui';

interface RunningTableProps {
  data: FlowExecution[];
  onStop: (flowExecutionId: number) => Promise<void>;
  loading?: boolean;
};

export const RunningTable: React.FC<RunningTableProps> = (props) => {

  const onStop = props.onStop;
  const columns = React.useMemo((): Column<FlowExecution>[] => [
    {
      Header: '',
      id: 'actions',
      accessor: 'flowName',
      Cell: cell => (
        cell.row.original.status === 'Canceling' 
        ? <Spinner size={28} /> 
        : <ConfirmAction title="Cancel Flow Execution" value={cell.row.original} icon="block" onAction={(value, confirmed) => confirmed && onStop(value.flowExecutionId)}>
          Are you sure you wish to cancel {cell.row.original.flowName} execution {cell.row.original.flowExecutionId}?
        </ConfirmAction>
      ),
      width: 32
    },
    {
      Header: 'Flow',
      accessor: 'flowName',
      width: '33%',
      Cell: cell => (<Link to={`/execution/${cell.row.original.flowExecutionId}/detail`}>{cell.value}</Link>)
    },
    {
      Header: 'ID',
      accessor: 'flowExecutionId',
      width: '100px'
    },
    {
      Header: 'Jobs',
      accessor: execution => execution.structure.jobs.length,
      width: '60px'
    },
    {
      Header: 'Started',
      accessor: 'started',
      width: '33%',
      Cell: cell => dateFormat(new Date(cell.value), 'yyyy-MM-dd HH:mm:ss')
    },
    {
      Header: 'By',
      accessor: 'userId',
      width: '33%'
    },
    {
      Header: 'Status',
      accessor: 'status',
      width: '100px',
      Cell: ({value}) => (<Capsule color={getStatusColor(value)}>{value}</Capsule>)
    }
  ], [onStop]);

  const getStatusColor = (status: string) => {
    switch (status) {
      case 'Succeeded':
        return 'good';
      case 'Running':
      case 'Canceling':
        return 'warning';
    }

    return 'error';
  }

  return (
    <Breakpoint>
      <Small>
        {props.loading && <div className="Monitor__Loading"><Spinner /></div>}
        {props.data.map(execution => <ExecutionCard execution={execution} />)}
        {(props.data.length === 0 && !props.loading) && <Card>No flows currently executing.</Card>}
      </Small>
      <Large>
        <JetTable data={props.data} columns={columns} rowKey={(execution) => execution.flowExecutionId} emptyMessage="No flows currently executing" loading={props.loading} />
      </Large> 
    </Breakpoint>
  );
};
