import { getDay, getDaysInMonth, addMonths, subMonths } from 'date-fns';

/** A map of weeks to weekdays, filled with month dates. */
export interface DateMap {
  /** The year of the date. */
  year: number;

  /** The month of the date. */
  month: string;

  /** The month of the date, in numeric form. */
  monthNum: number;

  /** The map of weeks to weekdays and month dates. */
  map: (number | null)[][];
}

/** A system for tracking calendar dates. */
export class Datesystem {

  /** 
   * The map of the previous month, expressed as an array of 5
   * weeks of 7 days, populated with dates of the month.
   */
  public prevMonth: DateMap = { year: 0, monthNum: 0, month: 'January', map: [] };

  /** 
   * The map of the current month, expressed as an array of 5
   * weeks of 7 days, populated with dates of the month.
   */
  public currentMonth: DateMap = { year: 0, monthNum: 0, month: 'January', map: [] };

  /** 
   * The map of the next month, expressed as an array of 5
   * weeks of 7 days, populated with dates of the month.
   */
  public nextMonth: DateMap = { year: 0, monthNum: 0, month: 'January', map: [] };

  /** The date system's current date. */
  private currDate: Date = new Date();

  /**
   * Sets the date system to a given year and month.
   * @param year The year to set to.
   * @param month The month to set to.
   */
  public set(year: number, month: number): void {
    const date = new Date(year, month);
    this.currDate = date;

    this.currentMonth = this.getMonthMap(date);
    this.prevMonth = this.getMonthMap(subMonths(date, 1));
    this.nextMonth = this.getMonthMap(addMonths(date, 1));
  }

  /**
   * Moves the date system forward one month.
   */
  public moveNext(): void {
    const nextDate = addMonths(this.currDate, 1);
    this.set(nextDate.getFullYear(), nextDate.getMonth());
  }

  /**
   * Moves the date system backward on month.
   */
  public movePrev(): void {
    const nextDate = subMonths(this.currDate, 1);
    this.set(nextDate.getFullYear(), nextDate.getMonth());
  }

  /**
   * Gets a map of week to weekdays, filled with monthly dates.
   * @param date The date to get the map for.
   */
  private getMonthMap(date: Date): DateMap {
    const map = this.createEmptyMap();
    
    const daysInMonth = getDaysInMonth(date);
    let startDay = getDay(date);

    let currentDayNum = 1;
    for (var weekNum = 0; weekNum < 6; weekNum++) {

      for (var dayInWeek = startDay; dayInWeek < 7; dayInWeek++) {
        map[weekNum][dayInWeek] = currentDayNum;
        currentDayNum++;

        if(currentDayNum > daysInMonth) {
          break;
        }
      }

      startDay = 0;
      if (currentDayNum > daysInMonth) {
        break;
      }
    }

    return {
      year: date.getFullYear(),
      month: date.toLocaleString('default', { month: 'long' }),
      monthNum: date.getMonth(),
      map: map
    };
  }

  /**
   * Creates an empty month map.
   */
  private createEmptyMap(): (number | null)[][] {
    return [
      Array(7).fill(null),
      Array(7).fill(null),
      Array(7).fill(null),
      Array(7).fill(null),
      Array(7).fill(null),
      Array(7).fill(null)
    ];
  }
}