import React from 'react';
import clsx from 'clsx';
import './LayoutItem.css';

interface LayoutItemProps {
  sm?: number;
  md?: number;
  lg?: number;
  xl?: number;
  className?: string;
}

export const LayoutItem: React.FC<LayoutItemProps> = (props) => {

  const classes = clsx(
    props.className,
    'jetui-layout-item',
    {
      //Default flex 3 items grid if nothing else defined
      //'jetui-layout-item-sm-3': !(props.sm || props.md || props.lg || props.xl),

      //Add the specific spacing classes if defined
      [`jetui-layout-item-sm-${String(props.sm)}`]: props.sm,
      [`jetui-layout-item-md-${String(props.md)}`]: props.md,
      [`jetui-layout-item-lg-${String(props.lg)}`]: props.lg,
      [`jetui-layout-item-xl-${String(props.xl)}`]: props.xl
    }
  );

  return (
    <div className={classes}>
      {props.children}
    </div>
  );
};