import React from 'react';
import axios from 'axios';

import { JetTable } from '../jet-ui/JetTable/JetTable';
import { Column, Cell } from 'react-table';
import { useAuth } from '../common/auth/use-auth';
import { AxiosRequestConfig } from 'axios';
import { Dialog, ToolButton, Spinner } from '../jet-ui';
import { ApiResult, AddComponent } from '../common/dataTypes/Jetstream';
import { EditPanel, EditPanelState } from '../jet-ui';
import { Formic } from '../jet-ui/Form/FormSystem';
import { useForm } from '../jet-ui/Form/Form';

/**
 * A component that displays a table of Jetstream flow components.
 */
export const ComponentTable: React.FC = () => {

  const [components, setComponents] = React.useState<any[]>([]);

  const [dialogError, setDialogError] = React.useState<string>();
  const [dialogOpen, setDialogOpen] = React.useState(false);

  const [addState, setAddState] = React.useState<EditPanelState<AddComponent>>({value: {}, open: false});
  const Form = useForm(addState.value);

  const auth = useAuth();
  
  let results: any[] = [];

  /** Fetches the components from the Jetstream API */
  const fetchData = React.useCallback(async () => {
    const result = await axios.get<ApiResult<any[]>>('/api/components', auth.getRequestConfig());
    const sortedResults = result.data.data.sort((a, b) => a?.path?.localeCompare(b?.path));
    
    // Check if there are any changes before updating
    let updateFound = false
    if (results.length != sortedResults.length) updateFound = true;
    else for (let i = 0; i < results.length; i++)
      // Check if there are any differences between the retrieved results and the current results.
      // Also, check if any components are still loading (loaded is false with no errors)
      if (results[i].path != sortedResults[i].path || 
          (results[i].loaded == false && results[i].errors == null)) updateFound = true;

    if (updateFound)
    {
      results = sortedResults;
      setComponents(sortedResults);
    }
  }, []);

  React.useEffect(() => {

    fetchData();

    let timeout: { instance?: NodeJS.Timeout } = {};
    const handleTimeout = async () => {
      try {
        await fetchData();
      }
      catch {}

      timeout.instance = setTimeout(handleTimeout, 2500);
    };

    timeout.instance = setTimeout(handleTimeout, 2500);
    return () => timeout.instance && clearTimeout(timeout.instance);
  }, []);

  const handleAdding = async (component: Partial<Formic<AddComponent>>): Promise<void> => {
    try {
      const result = await axios.post<ApiResult<undefined>>('/api/components', JSON.stringify(component.componentPath), auth.getRequestConfig({"Content-Type": "application/json"}));
      if (!result.data.success) throw new Error(result.data.message);
      setAddState(prev => ({...prev, open: false, status: `Added component ${component.componentPath}.`}));
      await fetchData();
    }
    catch (err) {
      throw {message: err.message};
    }
  };

  /**
   * Refreshes the component.
   */
  const refreshComponent = async (component: string) => {
    setComponents(prev => {
      const current = prev.find(x => x.path === component);
      current.errors = null;
      current.loaded = false;

      return [...prev];
    });

    await axios.post('/api/components', JSON.stringify(component), auth.getRequestConfig({'Content-Type': 'application/json'}));
    await fetchData();
  };

  /**
   * Removes the component.
   */
   const removeComponent = async (component: string) => {
    const config = auth.getRequestConfig() as AxiosRequestConfig;
    config.method = 'delete';
    config.url = '/api/components';
    config.params = { component: component };

    await axios(config);
    await fetchData();
  };

  const renderStatus = React.useCallback((cell: Cell<any>) => {
    const isLoaded = cell.value;
    const errors = cell.row.original.errors;

    if (!isLoaded)
    {
      if (errors) {
        return <ToolButton iconStyle='error' icon='error' onClick={() => { setDialogError(errors); setDialogOpen(true); }} />
      }
      else {
        return <Spinner size={24} />
      }
    }

    return <ToolButton iconStyle='good' icon='check' onClick={() => {}} disabled />
  }, []);

  const columns: Column<any>[] = React.useMemo(() => [
    {
      id: 'actions',
      width: 32,
      Cell: cell => (<ToolButton icon="refresh" disabled={!cell.row.original.loaded && !cell.row.original.errors} onClick={() => refreshComponent(cell.row.original.path)} />)
    },
    {
      id: 'remove',
      width: 32,
      Cell: cell => (<ToolButton icon="delete" disabled={!cell.row.original.loaded && !cell.row.original.errors} onClick={() => removeComponent(cell.row.original.path)} />)
    },
    {
      width: '100%',
      accessor: 'path',
      Header: 'Component Path'
    },
    {
      width: '64px',
      accessor: 'loaded',
      Header: 'Status',
      Cell: renderStatus
    }
  ], []);

  return (
    <div style={{maxWidth: 1000}}>
      <JetTable data={components} columns={columns} rowsPerPage={10} rowKey={c => c.path} />
      <EditPanel<AddComponent> state={addState} addLabel="Add Component" saveLabel="Add" modelLabel="Component"
        onSave={handleAdding} form={Form}>
          <Form.Input name="componentPath" required placeholder="Component Path" />
      </EditPanel>
      <Dialog className="Components__Dialog" open={dialogOpen} onClose={() => setDialogOpen(false)} actions={[{label: "OK", onClick: () => setDialogOpen(false)}]}>
        <h2>Error Loading Component</h2>
        <div className="Components__Dialog__Error">     
          <pre>
            {dialogError}
          </pre>
        </div> 
      </Dialog>
    </div>
  );
}