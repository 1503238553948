import React from 'react';

import './Header.css'

import { User } from './user/User';
import { Logo } from '../jet-ui/Logo/Logo';
import { Breakpoint, Small, Medium, Large } from '../jet-ui/Breakpoints/Breakpoint';
import { TopNav } from '../jet-ui/TopNav/TopNav';
import { Slider } from '../jet-ui/Slider/Slider';
import { ThemeResult } from '../jet-ui/Theme/useTheme';
import { Link } from 'react-router-dom';
import { useAuth } from '../common/auth/use-auth';

/**
 * A component that renders the Jetstream app header bar.
 */
export const Header: React.FC<{theme: ThemeResult}> = (props) => {

  const [sliderOpen, setSliderOpen] = React.useState(false);
  const handleThemeToggle = () => {
    props.theme.toggleTheme();
    setSliderOpen(false);
  }

  const auth = useAuth();

  const links = [
    {name: "Monitor", url: "/"},
    {name: "Flows", url: "/flows"},
    {name: "Components", url: "/components"}
  ];

  if (auth.user?.profile.role === 'admin') {
    links.push({name: "Admin", url: "/admin/"});
  }

  return (
    <header className="Header">
      <Breakpoint>
        <Small>
          <button className="Header__MenuBars">
            <i className="material-icons" onClick={() => setSliderOpen(true)}>menu</i>
          </button>
          <Logo size="sm" includeText link="/" />
          <Slider className="Header__Slider" open={sliderOpen} onClose={() => setSliderOpen(false)}>
            <Logo size="sm" includeText />
            <hr />
            {links.map(link => <Link className="Header__Slider__MenuItem" key={link.name} to={link.url} onClick={() => setSliderOpen(false)}>{link.name}</Link>)}
            <hr />
            <Link className="Header__Slider__MenuItem" to="/profile" onClick={() => setSliderOpen(false)}>Profile</Link>
            {props.theme.theme === 'light' && <Link className="Header__Slider__MenuItem" to="#" onClick={handleThemeToggle}>Dark Mode</Link>}
            {props.theme.theme === 'dark' && <Link className="Header__Slider__MenuItem" to="#" onClick={handleThemeToggle}>Light Mode</Link>}
            <Link className="Header__Slider__MenuItem" to="#" onClick={() => { auth.service.logout(); setSliderOpen(false); }}>Log Out</Link>
          </Slider>
        </Small>
        <Medium>
          <Logo link="/" />
          <TopNav links={links} />
        </Medium>
        <Large>
          <Logo includeText link="/" />
          <TopNav links={links} />
        </Large>
      </Breakpoint>
      <User theme={props.theme} />
    </header>
  );
}