import React, { useContext, useEffect } from 'react';
import AuthService from './AuthService';
import Oidc from 'oidc-client';
import { AxiosRequestConfig } from 'axios';

/**
 * The Jetstream authentication hook interface.
 */
interface JetstreamAuth {

  /**
   * An instance of the currently logged in user.
   */
  user?: Oidc.User;

  /**
   * An instance of the authentication service.
   */
  service: AuthService;

  /**
   * Gets the request configuration with bearer authorization.
   * 
   * @param headers Any additional headers for the request.
   */
  getRequestConfig: (headers?: any) => AxiosRequestConfig | undefined;
}

const authService = new AuthService();
const authContext = React.createContext<JetstreamAuth>({ service: authService, getRequestConfig: () => undefined });

/**
 * A context element that provides authentication to the useAuth
 * Jetstream custom hook.
 * @param el The children of this element.
 */
export const ProvideAuth: React.FC = (props) => {
  const auth = useProvideAuth();
  return (<authContext.Provider value={auth}>{props.children}</authContext.Provider>);
};

/**
 * A hook that provides user and authentication services.
 */
export const useAuth = () => {
  return useContext(authContext);
};

/**
 * A hook that provides the state of the ProvideAuth context element.
 */
function useProvideAuth() {
  const auth = React.useMemo(() => authService, []);
  const [user, setUser] = React.useState<Oidc.User | undefined>(undefined);

  useEffect(() => {
    auth.user.then(user => setUser(user));
    auth.onUserChange(user => setUser(user));
  }, [auth]);

  const getRequestConfig: (headers?: any) => AxiosRequestConfig | undefined = React.useCallback((headers?: any) => {
    if (user) {
      let config: AxiosRequestConfig = {
        headers: {
          Authorization: `Bearer ${user?.access_token}`
        }
      };

      if (headers) {
        config.headers = {...config.headers, ...headers};
      }

      return config;
    }
  }, [user]);

  return { user: user, service: auth, getRequestConfig };
}