import React from 'react';
import { useHistory } from 'react-router-dom';
import clsx from 'clsx';

import './Logo.css';

interface LogoProps {
  includeText?: boolean;
  size?: 'sm' | 'md' | 'lg';
  className?: string;
  link?: string;
}

export const Logo: React.FC<LogoProps> = (props) => {

  const history = useHistory();

  const classes = clsx(
    props.className,
    'jetui-logo',
    {
      'jetui-logo-sm': props.size === 'sm',
      'jetui-logo-md': !props.size || props.size === 'md',
      'jetui-logo-lg': props.size === 'lg',
      'jetui-logo-linked': props.link
    }
  );

  const handleOnClick = () => {
    if (props.link) {
      history.push(props.link);
    }
  };

  return (
    <div className={classes} onClick={handleOnClick}>
      <svg version="1.1" x="0px" y="0px" viewBox="0 0 549 549">
        <line className="st0" x1="504" y1="274.5" x2="72" y2="274.5"/>
        <circle className="st1" cx="497.5" cy="274.5" r="42.5"/>
        <line className="st2" x1="216" y1="103.5" x2="324" y2="274.5"/>
        <circle className="st1" cx="222.5" cy="110" r="42.5"/>
        <line className="st3" x1="45" y1="211.5" x2="72" y2="274.5"/>
        <line className="st2" x1="216" y1="445.5" x2="324" y2="274.5"/>
        <line className="st3" x1="45" y1="337.5" x2="72" y2="274.5"/>
        <circle className="st1" cx="222.5" cy="439" r="42.5"/>
        <circle className="st1" cx="45" cy="211.5" r="36"/>
        <circle className="st1" cx="45" cy="337.5" r="36"/>
      </svg>
      {props.includeText && <span>JETSTREAM</span>}
    </div>
  )
}