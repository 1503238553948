import React from 'react';
import axios from 'axios';
import { RetentionPolicy, ApiResult, RetentionPolicyStatistics } from '../../common/dataTypes/Jetstream';
import { useAuth } from '../../common/auth/use-auth';
import { Input, Status, StatusType, Card, Spinner } from '../../jet-ui';

import './GlobalRetentionPolicy.css';
import { format } from 'date-fns';

export const GlobalRetentionPolicy: React.FC = () => {

  const [newPolicy, setNewPolicy] = React.useState<RetentionPolicy>({retentionPolicyId: 'Global'});
  const [currentPolicy, setCurrentPolicy] = React.useState<RetentionPolicy>();
  const [statistics, setStatistics] = React.useState<RetentionPolicyStatistics>();

  const [updating, setUpdating] = React.useState(false);
  const [status, setStatus] = React.useState<{type: StatusType, message?: string}>({type: 'neutral'});

  const [applying, setApplying] = React.useState(false);
  const [applyStatus, setApplyStatus] = React.useState<{type: StatusType, message?: string}>({type: 'neutral'});

  const auth = useAuth();

  const fetchGlobalPolicy = React.useCallback(async () => {
    const policyResult = await axios.get<ApiResult<RetentionPolicy>>('/api/retentionpolicies/Global', auth.getRequestConfig());
    if (policyResult.data.success) {
      setCurrentPolicy(policyResult.data.data);
      setNewPolicy(policyResult.data.data);
    }

    const statsResult = await axios.get<ApiResult<RetentionPolicyStatistics>>('/api/retentionpolicies/statistics', auth.getRequestConfig());
    if (statsResult.data.success) {
      setStatistics(statsResult.data.data);
    }
  }, []);

  React.useEffect(() => {
    fetchGlobalPolicy();
  }, [fetchGlobalPolicy]);

  const updateGlobalPolicy = async () => {
    setUpdating(true);
    try {
      const result = await axios.post<ApiResult<RetentionPolicy>>('/api/retentionpolicies', newPolicy, auth.getRequestConfig());
      setStatus({type: result.data.success ? 'good' : 'error', message: result.data.message});
      if (result.data.success) {
        await fetchGlobalPolicy();
      }    
    }
    catch (err) {
      if (err.message) {
        setStatus({type: 'error', message: err.message});
      }
      else {
        setStatus({type: 'error', message: 'An unexpected error occurred.'});
      }
    }
    finally {
      setUpdating(false);
    }
  };

  const applyPolicy = async () => {
    setApplying(true);
    try {
      const result = await axios.post<ApiResult<void>>('/api/retentionpolicies/apply', {}, auth.getRequestConfig());
      if (result.data.success) {
        setApplyStatus({type: 'error', message: result.data.message});
        await fetchGlobalPolicy();
      } 
    }
    catch (err) {
      if (err.message) {
        setApplyStatus({type: 'error', message: err.message});
      }
      else {
        setApplyStatus({type: 'error', message: 'An unexpected error occurred.'});
      }
    }
    finally {
      setApplying(false);
    }
  };

  const currentPolicyDisplay = () => {
    if (currentPolicy) {
      if (currentPolicy.days && !currentPolicy.items) {
        return <p>Flow execution data will be kept for <b>{currentPolicy.days}</b> days.</p>;
      }
      else if (currentPolicy.items && !currentPolicy.days) {
        return <p>Flow execution data will be kept for the <b>{currentPolicy.items}</b> most recent executions of each flow.</p>;
      }
      else if (currentPolicy.items && currentPolicy.days) {
        return <p>
          Flow execution data will be kept for the <b>{currentPolicy.items}</b> most recent executions of each flow and
          then the remaining executions older than <b>{currentPolicy.days}</b> days will be discarded.
        </p>;
      }     
    }
    return <p>No global retention policy is currently defined.</p>
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const key = e.target.name;
    const value = e.target.value;
    setNewPolicy(prev => ({...prev, [key]: value}));
  };

  const hasPolicy = currentPolicy && (currentPolicy.days || currentPolicy.items);

  return (
    <div className="GlobalRetentionPolicy">
      <h1>Global Retention Policy</h1>
      <h3>Current Policy</h3>
      {currentPolicyDisplay()}
      {statistics?.lastRun && <p>Last Run On: {format(new Date(statistics.lastRun), "MMMM do',' yyyy 'at' h:mm a")}</p>}
      {statistics?.scheduled && <p>Next Run At: {format(new Date(statistics.scheduled), "MMMM do',' yyyy 'at' h:mm a")}</p>}
      {hasPolicy && <button disabled={applying} onClick={applyPolicy}>Apply Now</button>}
      {applying && <Spinner size={24} />}
      <Status open={applyStatus.message !== undefined} type='info' onClose={() => setApplyStatus(prev => ({...prev, message: undefined}))}>{applyStatus.message}</Status>
      <hr/>
      <h3>Update Policy</h3>
      <div className="GlobalRetentionPolicy__Fields">
        <label>The number of days of executions to retain for each flow.</label>
        <Input type="number" name="days" placeholder="Number of Days" value={newPolicy?.days} onChange={handleChange} min="1" />
        <label>The minimum number of recent executions to retain for each flow.</label>
        <Input type="number" name="items" placeholder="Number of Items" value={newPolicy?.items} onChange={handleChange} min="1" />
      </div>
      <button disabled={updating} onClick={updateGlobalPolicy}>Update</button>
      <Status open={status.message !== undefined} onClose={() => setStatus(prev => ({...prev, message: undefined}))} type={status?.type}>{status?.message}</Status> 
    </div>
  );
};