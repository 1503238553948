import React from 'react';
import axios from 'axios';
import { useHistory } from 'react-router-dom';

import './FlowNav.css';

import { ToolButton } from '../../jet-ui/ToolButton/ToolButton';
import { ApiResult, Flow, FlowExecution } from '../../common/dataTypes/Jetstream';
import { Breakpoint, Small, Medium } from '../../jet-ui/Breakpoints/Breakpoint';
import { Dialog } from '../../jet-ui/Dialog/Dialog';
import { Spinner } from '../../jet-ui/Spinner/Spinner';
import { useAuth } from '../../common/auth/use-auth';

/** Properties on the FlowNav component. */
interface FlowNavProps {

  /** The flow that is currently being displayed. */
  flow: Flow;

  /** The flow execution, if any. */
  execution?: FlowExecution;
}

/**
 * A component that renders the navigation bar on the flow detail
 * and execution pages.
 * @param props The properties of the component.
 */
export const FlowNav: React.FC<FlowNavProps> = (props) => {

  const history = useHistory();

  const [cancelDialogOpen, setCancelDialogOpen] = React.useState(false);
  const [canceling, setCanceling] = React.useState(false);

  const auth = useAuth();

  const isRunning = props.execution && (props.execution.status === 'Running' || props.execution.status === 'Canceling');

  /** Begins the run of a flow and navigates to the execution page. */
  const runFlow = async () => {
    const result = await axios.post<ApiResult<{flowExecutionId: number}>>('/api/flows/run',
      JSON.stringify(props.flow.structure.name), auth.getRequestConfig({'Content-Type': 'application/json'}));
    history.push(`/execution/${result.data.data.flowExecutionId}/detail`);
  };

  /** Begins the run of a flow and navigates to the execution page. */
  const cancelFlow = async () => {
    await axios.post<ApiResult<{flowExecutionId: number}>>('/api/executions/stop',
      JSON.stringify(props.flow.structure.flowExecutionId), auth.getRequestConfig({'Content-Type': 'application/json'}));
  };

  const renderCancelButton = () => {
    if (isRunning) {
      if (canceling) {
        return (
          <span>
            <Spinner size={24} /> Cancel Execution
          </span>
        );
      }
      else {
        return (<Breakpoint>
          <Small>
            <ToolButton icon="highlight_off" onClick={() => setCancelDialogOpen(true)}></ToolButton>
          </Small>
          <Medium>
            <ToolButton icon="highlight_off" onClick={() => setCancelDialogOpen(true)}>Cancel Execution</ToolButton>
          </Medium>
        </Breakpoint>);
      }
    }

    return null;
  };

  

  const handleDialogComplete = async (confirm: boolean) => {
    setCancelDialogOpen(false);

    if (confirm) {
      setCanceling(true);
      await cancelFlow();
    }
  };

  return (
    <div className="Flow-Nav">
      <ul>
        <Breakpoint>
          <Small>
            <li><ToolButton icon="timeline" to="detail" /></li>
            <li><ToolButton icon="share" to="tree" /></li>
            {!props.execution && <li><ToolButton icon="event" to="schedule" /></li>}
            {!props.execution && <li><ToolButton icon="nat" to="triggers" /></li>}
            {props.execution && <li><ToolButton icon="history" to="timing" /></li>}
            <li><ToolButton icon="toc" to="executions" /></li>
            {!isRunning && <li><ToolButton icon="play_circle_outline" onClick={runFlow} /></li>}
            <li>{renderCancelButton()}</li>
            <li><ToolButton icon="arrow_back" onClick={history.goBack} /></li>
          </Small>
          <Medium>
            <li><ToolButton icon="timeline" to="detail">Flow</ToolButton></li>
            <li><ToolButton icon="share" to="tree">Job Tree</ToolButton></li>
            {!props.execution && <li><ToolButton icon="event" to="schedule">Schedule</ToolButton></li>}
            {!props.execution && <li><ToolButton icon="nat" to="triggers">Triggers</ToolButton></li>}
            {props.execution && <li><ToolButton icon="history" to="timing">Timing</ToolButton></li>}
            <li><ToolButton icon="toc" to="executions">Executions</ToolButton></li>
            {!isRunning && <li><ToolButton icon="play_circle_outline" onClick={runFlow}>Run Now</ToolButton></li>}
            <li>{renderCancelButton()}</li>
            <li><ToolButton icon="arrow_back" onClick={history.goBack}>Back</ToolButton></li>
          </Medium>
        </Breakpoint>   
      </ul>
      <Dialog title="Cancel Execution" open={cancelDialogOpen} onClose={() => {}}>
        Are you sure you want to cancel this flow execution?
        <div className="jetui-dialog-actions">
          <button onClick={() => handleDialogComplete(true)}>Yes</button>
          <button onClick={() => handleDialogComplete(false)}>No</button>
        </div> 
      </Dialog>
    </div>
  )
}
