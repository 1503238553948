import React from 'react';
import clsx from 'clsx';
import { BrowserRouter as Router, Route } from 'react-router-dom'

import './App.css';
import './jet-ui/jet-ui.css';

import { ExecutionPage } from './flow/ExecutionPage';
import { FlowPage } from './flow/FlowPage';
import { Header } from './header/Header';
import { Login } from './login/Login';
import { Admin } from './admin/Admin';
import { ProvideAuth, useAuth } from './common/auth/use-auth';
import { SignUp } from './signup/Signup';
import { Profile } from './profile/Profile';
import { GridTest } from './jet-ui/GridTest';
import { Monitor } from './Monitor/Monitor';
import { Components } from './Components/Components';
import { Flows } from './Flows/Flows';
import { useTheme } from './jet-ui/Theme/useTheme';

/**
 * The main Jetstream app component.
 */
export default function App() {

  /**
   * A subcomponent that displays the main app page only if
   * authorized. This prevents flashes of content while the authentication
   * is set up.
   */
  function AuthorizedApp() {
    const auth = useAuth();
    const jetuiTheme = useTheme();

    const classes = clsx('App', `jetui-theme-${jetuiTheme.theme}`);

    if(auth.user) {
      return (
        <div className={classes}>
          <Header theme={jetuiTheme} />
          <Route path="/" exact>
            <Monitor />
          </Route>
          <Route path="/flows" exact>
            <Flows />
          </Route>
          <Route path="/flow/:flowName">
            <FlowPage />
          </Route>
          <Route path="/execution/:executionId">
            <ExecutionPage />
          </Route>
          <Route path="/components">
            <Components />
          </Route>
          <Route path="/admin">
            <Admin />
          </Route>
          <Route path="/profile">
            <Profile />
          </Route>
          <Route path="/gridtest">
            <GridTest />
          </Route>
        </div>
      );
    } else {
      return null;
    }
  }

  return (
    <ProvideAuth>
      <Router>
        <AuthorizedApp />
        <Route path="/login" exact>
          <Login />
        </Route>
        <Route path="/signup" exact>
          <SignUp />
        </Route>
      </Router>
    </ProvideAuth>   
  );
}
