import axios from 'axios';
import React from 'react';
import { ApiResult, FlowSchedule } from '../../common/dataTypes/Jetstream';
import { Card } from '../../jet-ui/Card/Card';
import { Layout } from '../../jet-ui/Layout/Layout';
import { LayoutItem } from '../../jet-ui/LayoutItem/LayoutItem';
import './Schedule.css';
import { ScheduleForm } from './ScheduleForm';
import { useAuth } from '../../common/auth/use-auth';

interface ScheduleProps {
  flowName?: string;
}

export const Schedule: React.FC<ScheduleProps> = (props) => {
  const [schedule, setSchedule] = React.useState<FlowSchedule | undefined>();
  const [isChanging, setIsChanging] = React.useState(false);

  const auth = useAuth();

  const fetchSchedule = React.useCallback(async (flowName: string) => {
    const result = await axios.get<ApiResult<FlowSchedule>>(`/api/flows/${encodeURIComponent(flowName)}/schedule`, auth.getRequestConfig());
    setSchedule(result.data.data);
  }, []);

  React.useEffect(() => {
    if (props.flowName) {
      fetchSchedule(props.flowName);
    }
    else {
      setSchedule(undefined);
    }
  }, [props.flowName, fetchSchedule]);

  const scheduleObject = schedule?.schedule && JSON.parse(schedule.schedule);

  const handleFormClose = React.useCallback(async () => {
    if (props.flowName) {
      await fetchSchedule(props.flowName)
      setIsChanging(false);
    }
  }, [props.flowName, fetchSchedule]);

  if (!isChanging) {
    return (
      <div className="Schedule">
        <h3>Current Flow Schedule</h3>    
        <Card className="Schedule__Card">
          <h4>{schedule?.type}</h4>
          <Layout spacing={0}>
            {scheduleObject && Object.keys(scheduleObject).map(field => <LayoutItem sm={6} key={field}><b>{field}:</b> {scheduleObject[field]}</LayoutItem>)}
          </Layout>     
        </Card>
        <button onClick={() => setIsChanging(true)}>Change</button>
      </div>
    );
  }
  else {
    return (
      <div className="Schedule">
        <div className="Schedule__Form">
          <h3>Change Flow Schedule</h3>
          <ScheduleForm flowName={schedule?.flowName} model={schedule && JSON.parse(schedule?.schedule)} onClose={handleFormClose} />
        </div>  
      </div>
    );
  }
};
