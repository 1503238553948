import React from 'react';
import './Spinner.css';

interface SpinnerProps {
  size?: number;
  className?: string;
};

export const Spinner: React.FC<SpinnerProps> = (props) => {

  const style: React.CSSProperties | undefined = props.size
    ? {width: props.size, height: props.size}
    : {width: 48, height: 48};

  return (
    <div className={props.className} style={{...style, display: 'inline-block'}}>
      <svg className="jetui-spinner" width="100%" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
        <g>
          <circle className="jetui-spinner-outer" fill="none" strokeWidth="4" strokeLinecap="round" cx="33" cy="33" r="30"></circle>
        </g>
        <g>
          <circle className="jetui-spinner-inner" fill="none" strokeWidth="4" strokeLinecap="round" cx="33" cy="33" r="21"></circle>
        </g>
      </svg>
    </div>
  );
};