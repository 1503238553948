import React from 'react';
import { FormSystem, ValidationErrors, Formic } from './FormSystem';
import { FormInput, InputProps } from '../Input/Input';
import { StatusType, Status } from '../Status/Status';
import { Spinner } from '../Spinner/Spinner';

import './Form.css';
import { FormOptionSelect, OptionSelectProps } from '../OptionSelect/OptionSelect';
import { TextBoxProps, FormTextBox } from '..';

type InnerFormProps<T> = {
  formSystem: FormSystem<T>;
}

type OuterFormProps<T> = {
  onSubmit: (state: Partial<Formic<T>>) => Promise<void>;
  actions?: FormAction<T>[];
}

type FormProps<T> = InnerFormProps<T> & OuterFormProps<T>;

export declare type FormAction<T> = {
  type: 'submit';
  label: string;
} | {
  type?: 'button' | 'reset';
  label: string;
  onClick: (state: Partial<Formic<T>>) => Promise<void>;
};

type FormError = {
  type?: StatusType;
  message: string;
}

function Form<T>(props: React.PropsWithChildren<FormProps<T>>) {

  const [error, setError] = React.useState<FormError>({message: ''});
  const [processing, setProcessing] = React.useState(false);

  const handleSubmit = React.useCallback(async (state: Partial<Formic<T>>) => {
    setProcessing(true);
    try {
      await props.onSubmit(state);
    }
    catch (err) {
      if (err.message) {
        setError({type: 'error', message: err.message});
      }
      else {
        setError({type: 'error', message: 'An unexpected error occurred.'});
      }
    }
    finally {
      setProcessing(false);
    }
  }, []);

  return (
    <div className="jetui-form">
      <form onSubmit={props.formSystem.handleSubmit(handleSubmit)}>
        <div className="jetui-form-fields">
          {props.children}
        </div>
        <div className="jetui-form-actions">
          <div>
            {!props.actions && <button disabled={processing} type='submit'>Submit</button>}
            {props.actions && props.actions.map(action => <button type={action.type} onClick={() => action.type !== 'submit' && action.onClick(props.formSystem.state)} disabled={processing}>{action.label}</button>)}
            {processing && <Spinner size={24} />}
          </div>
          <Status open={error.message !== ''} type={error.type} onClose={() => setError({message: ''})}>{error.message}</Status>
        </div>     
      </form>
    </div>
  );
};

export function useForm<T>(initialState: Partial<Formic<T>>): UseForm<T> {

  const initialErrors: ValidationErrors<T> = {all: [], byField: {}};

  const [state, setState] = React.useState(initialState);
  const [errors, setErrors] = React.useState<ValidationErrors<T>>(initialErrors);

  const handleOnValid = (state: Partial<Formic<T>>) => setState(state);
  const handleOnInvalid = (errors: ValidationErrors<T>) => setErrors(errors);

  const [formSystem, setFormSystem] = React.useState(new FormSystem<T>(initialState, handleOnValid, handleOnInvalid, initialErrors));
  const createForm = React.useCallback((props: React.PropsWithChildren<OuterFormProps<T>>) => Form<T>({...props, formSystem}), [formSystem]);

  return {
    Form: createForm,
    Input: FormInput<T>(formSystem),
    OptionSelect: FormOptionSelect<T>(formSystem),
    TextBox: FormTextBox<T>(formSystem),
    replace: formSystem.replace.bind(formSystem)
  };
}

export declare type UseForm<T> = {
  Form: (props: React.PropsWithChildren<OuterFormProps<T>>) => JSX.Element;
  Input: React.ForwardRefExoticComponent<InputProps<T> & React.RefAttributes<HTMLInputElement>>;
  OptionSelect: React.ForwardRefExoticComponent<OptionSelectProps<T> & React.RefAttributes<HTMLSelectElement>>;
  TextBox: React.ForwardRefExoticComponent<TextBoxProps<T> & React.RefAttributes<HTMLTextAreaElement>>;
  replace: (state: Partial<Formic<T>>) => void;
}
