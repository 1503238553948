import React from 'react';
import * as Router from 'react-router-dom';
import axios from 'axios';

import './FlowDetail.css';

import { Flow, ApiResult, JobState, FlowExecution } from '../../common/dataTypes/Jetstream';
import { FlowGraph } from '../flowGraph/FlowGraph';
import { JobLog } from '../jobLog/JobLog';
import { JobList } from '../jobList/JobList';
import { JobStateBus } from '../../common/jobStateBus/JobStateBus';
import { Layout } from '../../jet-ui/Layout/Layout';
import { LayoutItem } from '../../jet-ui/LayoutItem/LayoutItem';
import { Breakpoint, Medium } from '../../jet-ui/Breakpoints/Breakpoint';
import { useAuth } from '../../common/auth/use-auth';

/**
 * Properties on the FlowDetail component.
 */
interface FlowDetailProps {

  /** The flow to display in the component. */
  flow: Flow;

  /** The flow execution to display. */
  flowExecution?: FlowExecution;
}

/**
 * A component that displays the execution tree, jobs, and logs of a flow.
 * @param props The properties of the component.
 */
export const FlowDetail: React.FC<FlowDetailProps> = (props) => {

  const [log, setLog] = React.useState<string[]>([]);
  const [selectedJobId, setJobId] = React.useState(0);
  const [jobStates, setJobStates] = React.useState<{ [index: number] : JobState }>({});

  const busRef = React.useRef<JobStateBus>();

  const history = Router.useHistory();

  const flowExecutionId = props.flow.structure.flowExecutionId;
  const jobExecutionId = props.flow.structure.jobs[selectedJobId]?.jobExecutionId;
  const status = props.flowExecution?.status;

  const auth = useAuth();

  React.useEffect(() => {

    /**
     * Starts a timer that fetches the job log at an interval.
     * @param jobId The ID of the job.
     * @returns A function that cancels the job log fetch timer.
     */
    const startLogFetchTimer = () => {  

      if (selectedJobId >= 0 && flowExecutionId && jobExecutionId && (status && (status == 'Running' || status == 'Canceling'))) {
        fetchLog(flowExecutionId, jobExecutionId);

        let timeout: { instance?: NodeJS.Timeout } = {};

        const handleTimeout = async () => {
          await fetchLog(flowExecutionId, jobExecutionId);
          timeout.instance = setTimeout(handleTimeout, 2500);
        };

        timeout.instance = setTimeout(handleTimeout, 2500);
        return () => timeout.instance && clearTimeout(timeout.instance);
      }

      return () => {};
    }

    return startLogFetchTimer();
  }, [selectedJobId, flowExecutionId, jobExecutionId, status]);

  const fetchJobStates = React.useCallback(async () => {
    const result = await axios.get(`/api/executions/${flowExecutionId}/statuses`, auth.getRequestConfig())
    setJobStates(prev => ({...prev, ...result.data.data}));
  }, [flowExecutionId]);

  React.useEffect(() => {
    if (jobExecutionId && flowExecutionId) {
      fetchLog(flowExecutionId, jobExecutionId);
      fetchJobStates();
    }
  }, [status, jobExecutionId, flowExecutionId])

  React.useEffect(() => {

    /**
     * Subscribes to job state updates for a given flow execution ID.
     * 
     * @param flowExecutionId The ID of the execution.
     * @returns A function that unsubscribes from the job state updates.
     */
    const subscribeToJobUpdates = (flowExecutionId: number) => {
      if (!busRef.current) {
        busRef.current = new JobStateBus();
      }

      const jobBus = busRef.current;

      setLog([]);
      setJobId(0);

      fetchJobStates().then(() => {
        const states = jobBus.subscribe(flowExecutionId, (states: { [index: number]: JobState }) => {
          setJobStates(prev => ({...prev, ...states}));
        });

        setJobStates(prev => ({...prev, ...states}));
      });
       
      return () => jobBus.unsubscribe(flowExecutionId);
    }

    if (props.flow.structure.flowExecutionId) {
      return subscribeToJobUpdates(props.flow.structure.flowExecutionId);
    }
  }, [props.flow.structure.flowExecutionId]);

  /**
   * Handles when a job is selected from a child component.
   * @param id The ID of the job that was selected.
   */
  const handleJobSelect = React.useCallback((id: number) => setJobId(id), []);

  /**
   * Fetches the log for a job execution.
   * @param flowExecutionId The ID of the flow execution.
   * @param jobExecutionId The ID of the job execution.
   */
  const fetchLog = async (flowExecutionId: number, jobExecutionId: number) => {
    const result = await axios.get<ApiResult<string[]>>(`/api/logs?flowExecutionId=${flowExecutionId}&jobExecutionId=${jobExecutionId}`, auth.getRequestConfig());
    if (result.status !== 200 || !result.data.success) {
      setLog([]);
    } else {
      setLog(result.data.data);
    }   
  };

  /**
   * Handles when a flow is selected to resume from a particular job.
   * @param jobId The ID of the job to resume from.
   */
  const handleJobResume = React.useCallback(async (jobId: number, singleJob: boolean) => {
    const result = await axios.post<ApiResult<{flowExecutionId: number}>>('/api/flows/resume', { flowName: props.flow.structure.name, jobId: jobId, singleJob: singleJob }, auth.getRequestConfig());
    history.push(`/execution/${result.data.data.flowExecutionId}/detail`);
  }, [props.flow.structure.name, history]);

  return (
    <div className="FlowDetail">
      <Breakpoint>
        <Medium>
          <div className="FlowDetail__Graph">
            <FlowGraph flow={props.flow.structure} states={jobStates} onJobSelect={handleJobSelect} />
          </div>
        </Medium>
      </Breakpoint>      
      <Layout>
        <LayoutItem sm={12} md={4} xl={3}>
          <h2>Jobs</h2>
          <JobList jobs={props.flow.structure.jobs} onJobSelect={handleJobSelect} states={jobStates} onJobResume={handleJobResume} selectedJob={selectedJobId} />
        </LayoutItem>
        <LayoutItem sm={12} md={8} xl={9} className="FlowDetail__JobLog__Container">
          <JobLog log={log} name={props.flow.structure.jobs[selectedJobId] && props.flow.structure.jobs[selectedJobId].name}/>
        </LayoutItem>
      </Layout>    
    </div>
  )
}
