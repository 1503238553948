import React from 'react';
import axios from 'axios';
import { Link }  from 'react-router-dom';
import { format as dateFormat } from 'date-fns';

import './FlowTable.css';

import { Histogram } from '../common/histogram/Histogram';
import { useAuth } from '../common/auth/use-auth';
import { JetTable } from '../jet-ui/JetTable/JetTable';
import { Column } from 'react-table';
import { Breakpoint, Large, Small } from '../jet-ui/Breakpoints/Breakpoint';
import { Card } from '../jet-ui/Card/Card';
import { Layout } from '../jet-ui/Layout/Layout';
import { LayoutItem } from '../jet-ui/LayoutItem/LayoutItem';
import { Spinner } from '../jet-ui/Spinner/Spinner';

interface FlowTableProps {
    pageIndex?: number;
    onManualPageChangeFn?: (page: number) => void;
};

/** A component that displays a table of currently registered flows. */
export const FlowTable: React.FC<FlowTableProps> = (props) => {

  const [flows, setFlows] = React.useState<any[]>([]);
  const [isLoading, setIsLoading] = React.useState(true);

  const auth = useAuth();
  const onManualPageChange = React.useCallback((pageIndex: number) => {
    if (props.onManualPageChangeFn != undefined) {
        props.onManualPageChangeFn(pageIndex);
    }
    return;
  }, []);

  const fetchData = React.useCallback(async () => {
    setIsLoading(true);

    try {
      const result = await axios('/api/flows', auth.getRequestConfig() );
      setFlows(result.data.data.flows.map((flow: any) => {
        return {
          paused: false,
          name: flow.structure.name,
          jobs: flow.structure.jobs.length,
          schedule: flow.schedule,
          next: flow.next,
          last: flow.last,
          histogram: flow.histogram
        }
      }));
    }
    finally {
      setIsLoading(false);
    }
    
  }, [auth.user]);

  React.useEffect(() => {
    fetchData();
  }, [auth.user, fetchData]);

  const columns: Column<any>[] = [
    { Header: 'Flow', accessor:'name', Cell: ({value}) => <Link to={`/flow/${encodeURIComponent(value)}/detail`}>{value}</Link>},
    { Header: 'Jobs', accessor:'jobs', width: '7%' },
    { Header: 'Schedule', accessor:'schedule' },
    { Header: 'Up Next', accessor:'next' },
    { Header: 'Last Run', accessor:'last' },
    { Header: 'Histogram', accessor:'histogram', Cell: ({value}) => <div className="FlowTable__Histogram"><Histogram data={value} /></div> },
  ];

  return (
    <Breakpoint>
      <Small>
        {isLoading && <div className="FlowTable__Loading"><Spinner /></div>}
        {flows.map(flow => (
          <Card key={flow.name} className="FlowTable__Card">
            <Layout>
              <LayoutItem sm={8}>
                <h4><Link to={`/flow/${encodeURIComponent(flow.name)}/detail`}>{flow.name}</Link></h4>
                <p>
                  <b>Schedule</b> | {flow.schedule}
                </p>
                <p>
                  <b>Up Next</b> | {dateFormat(new Date(flow.next), 'yyyy-MM-dd HH:mm:ss')}
                </p>
                <p>
                  <b>Last Run</b> | {dateFormat(new Date(flow.last), 'yyyy-MM-dd HH:mm:ss')}
                </p>
              </LayoutItem>
              <LayoutItem className="FlowTable__CardHistogram" sm={4}>
                <Histogram data={flow.histogram} />
              </LayoutItem>
            </Layout>
          </Card>
        ))}
      </Small>
      <Large>
        <JetTable className="FlowTable" data={flows} columns={columns} loading={isLoading} rowsPerPage={20} searchable
         onManualPageChange={onManualPageChange} pageIndex={props.pageIndex} />
      </Large>
    </Breakpoint>   
  );
}
