import React from 'react';

export const useTransition = (duration: number, open: boolean) => {
  const [currentState, setCurrentState] = React.useState<'closed' | 'open' | 'closing'>(open ? 'open' : 'closed');
  const [, setTimeoutRef] = React.useState<NodeJS.Timeout | null>(null);

  React.useEffect(() => {
    if (open) {
      setTimeoutRef(ref => {
        if (ref) {
          clearTimeout(ref);
        }
        return null;
      });
      setCurrentState('open');
    }

    if (!open) {
      setTimeoutRef(ref => {
        if (ref && currentState === 'open') {
          clearTimeout(ref);
        }
        return null;
      });

      if (currentState === 'open') {
        const timeout = setTimeout(() => setCurrentState('closed'), duration * 1000);
        setTimeoutRef(timeout);
        setCurrentState('closing');
      }   
    }

  }, [open, duration, currentState]);

  return currentState;
};