import React from 'react';
import axios from 'axios';

import { ApiResult, FlowExecution, FlowSchedule } from '../common/dataTypes/Jetstream';
import { Breadcrumb } from '../jet-ui/Breadcrumb/Breadcrumb';
import { Content } from '../jet-ui/Content/Content';
import { Card } from '../jet-ui/Card/Card';
import { UpcomingTable } from './UpcomingTable';

import './Monitor.css';
import { RunningTable } from './RunningTable';
import { RecentTable } from './RecentTable';
import { useAuth } from '../common/auth/use-auth';

export const Monitor: React.FC = () => {

  const [upcoming, setUpcoming] = React.useState<any[]>([]);
  const [upcomingLoading, setUpcomingLoading] = React.useState(true);

  const [running, setRunning] = React.useState<FlowExecution[]>([]);
  const [runningLoading, setRunningLoading] = React.useState(true);

  const [recent, setRecent] = React.useState<FlowExecution[]>([]);
  const [recentPages, setRecentPages] = React.useState(0);
  const [recentRowsPerPage] = React.useState(20);
  const [recentPageIndex, setRecentPageIndex] = React.useState(0);
  const [recentLoading, setRecentLoading] = React.useState(true);

  const auth = useAuth();

  const fetchUpcoming = React.useCallback(async () => {
    const result = await axios.get<ApiResult<any[]>>('/api/executions/scheduled', auth.getRequestConfig());

    setUpcoming(result.data.data);
    setUpcomingLoading(false);
  }, []);

  const fetchRunning = React.useCallback(async () => {
    const result = await axios.get<ApiResult<any>>('/api/executions', auth.getRequestConfig());

    setRunning(result.data.data);
    setRunningLoading(false);
  }, []);

  const fetchRecent = React.useCallback(async (rowsPerPage: number, pageIndex: number) => {
    const result = await axios.get<ApiResult<any>>(`/api/executions/recent?itemsPerPage=${rowsPerPage}&pageNumber=${pageIndex}`, auth.getRequestConfig());

    setRecent(result.data.data.executions);
    setRecentPages(result.data.data.pages);

    setRecentLoading(false);
  }, []);

  React.useEffect(() => {
    fetchUpcoming();
    fetchRunning();
    fetchRecent(recentRowsPerPage, recentPageIndex);

    const interval = setInterval(() => {
      fetchUpcoming();
      fetchRunning();
      fetchRecent(recentRowsPerPage, recentPageIndex);
    }, 5000);

    return () => clearInterval(interval);
  }, [fetchRecent, fetchRunning, fetchUpcoming, recentPageIndex]);

  const onRecentPageChange = React.useCallback((pageIndex: number) => {
    setRecentLoading(true);
    setRecentPageIndex(pageIndex);
  }, []);

  /** Begins the run of a flow and navigates to the execution page. */
  const handleRun = React.useCallback(async (flowName: string) => {
    const config = auth.getRequestConfig();
    if (config) {
      config.headers = {...config.headers, 'Content-Type': 'application/json'};
    }
    await axios.post<ApiResult<{flowExecutionId: number}>>('/api/flows/run',
      JSON.stringify(flowName), config);
    await fetchRunning();
  }, []);

  const handleOnRemove = React.useCallback(async (flowName: string) => {
    const noSchedule: FlowSchedule = {
      flowName: flowName,
      schedule: "",
      type: 'None'
    };

    await axios.post<ApiResult<any>>(`/api/flows/${encodeURIComponent(flowName)}/schedule`, noSchedule, auth.getRequestConfig());
    await fetchUpcoming();
  }, []);

  const handleStop = React.useCallback(async (flowExecutionId: number) => {
    await axios.post<ApiResult<any>>('/api/executions/stop', JSON.stringify(flowExecutionId), auth.getRequestConfig({'Content-Type': 'application/json'}));
    await fetchRunning();
  }, []);

  return (
    <Content className="Monitor">
      <div>
        <Breadcrumb href="/">Home</Breadcrumb>
        <Breadcrumb href="/monitor">Monitor</Breadcrumb>
      </div>
      <h1>Monitor</h1>   
      <Card>
        <div className="Monitor__Section">
          <h2>Upcoming</h2>
          <UpcomingTable key="upcoming" data={upcoming} onRun={handleRun} onRemove={handleOnRemove} loading={upcomingLoading} />
        </div>
        <div className="Monitor__Section">
          <h2>Running</h2>
          <RunningTable key="running" data={running} onStop={handleStop} loading={runningLoading} />
        </div>
        <div className="Monitor__Section">
          <h2>Recent</h2>
          (displaying {recent.length} results)
          <RecentTable key="recent" data={recent} rowsPerPage={recentRowsPerPage} loading={recentLoading} pages={recentPages} onPageChange={onRecentPageChange} pageIndex={recentPageIndex} />
        </div>
      </Card>
    </Content>
  );
}