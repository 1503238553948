import React from 'react';
import './DropdownItem.css';

interface DropdownItemProps {
  icon?: string;
  onClick: (e: React.MouseEvent<HTMLDivElement>) => void;
}

export const DropdownItem: React.FC<DropdownItemProps> = (props) => {
  return (
    <div className='jetui-dropdown-item' onClick={props.onClick}>
      {props.icon && <i className="material-icons">{props.icon}</i>}
      {props.children}
    </div>
  );
};