import React from 'react';
import clsx from 'clsx';

import './Content.css';

interface ContentProps {
  className?: string;
}

export const Content: React.FC<ContentProps> = (props) => {
  const classes = clsx('jetui-content', props.className);
  return (
    <div className={classes}>
      {props.children}
    </div>
  );
};