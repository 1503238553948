import React from 'react';
import axios from 'axios';
import { ApiResult } from '../../common/dataTypes/Jetstream';
import { useAuth } from '../../common/auth/use-auth';
import { Column, Cell } from 'react-table';
import { JetTable, Dialog, Input, ConfirmAction } from '../../jet-ui';

/** A personal access token reference. */
interface PersonalAccessToken {

  /** The key of the token grant. */
  key: string;

  /** The expiration of the token. */
  expiration: string;
}

/** A component that manages personal access tokens. */
export const AccessTokens: React.FC = () => {

  const [tokens, setTokens] = React.useState<PersonalAccessToken[]>([]);
  const [createdToken, setCreatedToken] = React.useState("");

  const [isLoading, setIsLoading] = React.useState(false);
  const [createDialogOpen, setCreateDialogOpen] = React.useState(false);

  const auth = useAuth();

  const fetchTokens = React.useCallback(async () => {
    setIsLoading(true);

    try {
      const result = await axios.get<ApiResult<PersonalAccessToken[]>>("/api/tokens", auth.getRequestConfig());
      setTokens(result.data.data);
    }
    finally {
      setIsLoading(false);
    }
  }, []);

  const handleRevokeToken = async (data: PersonalAccessToken) => {
    setIsLoading(true);

    try {
      await axios.delete(`/api/tokens?key=${encodeURIComponent(data.key)}`, auth.getRequestConfig());
      await fetchTokens();
    }
    finally {
      setIsLoading(false);
    }    
  };

  React.useEffect(() => {
    fetchTokens();
  }, [fetchTokens]); 

  const handleCreateToken = async (e: React.MouseEvent<HTMLButtonElement>) => {
    const result = await axios.post<ApiResult<string>>(`/api/tokens`, {}, auth.getRequestConfig());
    setCreatedToken(result.data.data);
    setCreateDialogOpen(true);
  };

  const handleCloseDialog = async (e: React.MouseEvent<HTMLButtonElement>) => {
    setCreateDialogOpen(false);
    await fetchTokens();
  };

  const columns: Column<PersonalAccessToken>[] = [
    { 
      id: 'actions',
      width: 64,
      maxWidth: 64,
      Cell: (cell: Cell<PersonalAccessToken>) => (
        <ConfirmAction value={cell.row.original} icon="delete" onAction={(token, confirm) => confirm && handleRevokeToken(token)} title='Revoke Token'>
          Are you sure you wish to revoke this token?
        </ConfirmAction>
      )
    },
    { Header: 'Expires', accessor: 'expiration' }
  ];



  return (
    <div className="Profile__Section">
      <h2>Personal Access Tokens</h2>
      <JetTable data={tokens} columns={columns} loading={isLoading} />
      <button onClick={handleCreateToken}>Create Token</button>
      <Dialog open={createDialogOpen} onClose={() => {}}>
        <h2>Personal Access Token</h2>
        <p>
          This is the personal access token you have just created. Note the token and store in a secure place,
          as you will not be able to have this token displayed again, for security reasons.
        </p>
        <Input name="token" placeholder="Token" value={createdToken} disabled />
        <button onClick={handleCloseDialog}>OK</button>
      </Dialog>
    </div>
  )
}