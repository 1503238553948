import React from 'react';
import clsx from 'clsx';
import './OptionSelect.css';
import { FormKeys, Formic, ValidationResult, FormSystem } from '../Form/FormSystem';

export type SelectOption = {
  value: string;
  label: string;
}

export interface OptionSelectProps<T> {
  options: SelectOption[];
  className?: string;
  placeholder?: string;
  value?: Formic<T>[FormKeys<T>];
  onChange?: (value: Formic<T>[FormKeys<T>]) => void;
  name: FormKeys<T>;
  onValidate?(state: Partial<Formic<T>>, value: Partial<Formic<T>>[FormKeys<T>]): ValidationResult;
  formSystem?: FormSystem<T>;
  required?: boolean;
};

export function FormOptionSelect<T>(formSystem?: FormSystem<T>) { 
  function useFormOptionSelect(props: OptionSelectProps<T>, ref: React.Ref<HTMLSelectElement>) {

    const {onValidate, options, required, ...innerProps} = props;
    let [value, setValue] = React.useState(props.value);

    if (formSystem) {
      [value, setValue] = formSystem.useRegistration({name: props.name, label: props.placeholder ?? props.name, onValidate: props.onValidate, required: required});
      //error = formSystem.errors.byField[props.name]?.message;
    }

    const handleChanged = (e: React.ChangeEvent<HTMLSelectElement>) => {
      setValue(e.target.value);
      props.onChange && props.onChange(e.target.value);
    };

    const handleClear = (e: React.MouseEvent<HTMLElement>) => {
      setValue(undefined);
      props.onChange && props.onChange('');
    };

    const optionValue = value ?? '';
    const classes = clsx('jetui-selection', props.className);
    const labelClass = clsx({'jetui-selection-label-selected': optionValue !== ''});

    return (
      <span className={classes}>
        <label className={labelClass} onClick={() => {}}>{props.placeholder}</label> 
        <select {...innerProps} onChange={handleChanged} value={optionValue} ref={ref}>    
          <option value="" disabled hidden />
          {options.map(option => <option key={option.value} value={option.value}>{option.label}</option>)}
        </select>
        {optionValue !== '' && <i className="material-icons jetui-selection-clear" onClick={handleClear}>clear</i>}
      </span> 
    );
  };

  return React.forwardRef(useFormOptionSelect);
};

export const OptionSelect = FormOptionSelect<any>();