import React from 'react';
import clsx from 'clsx';
import * as ReactTable from 'react-table';
import './JetTable.css';
import { Spinner } from '../Spinner/Spinner';
import { GlobalSearch } from './GlobalSearch';

interface JetTableProps<T extends {}> {
  /** Classes to apply to the table element. */
  className?: string;

  /** The data contained in the table. */
  data: T[];

  /** The columns of the table. */
  columns: ReactTable.Column<T>[];

  /** Whether or not the loading indicator should be displayed. */
  loading?: boolean;

  /** The message displayed if the table is empty. */
  emptyMessage?: string;

  /** An accessor that gets the React key of the row. */
  rowKey?: string | ((item: T) => string | number);

  /** The number of rows per page. If undefined, no pagination will be used. */
  rowsPerPage?: number;

  /** A callback to execute when the page changes. If defined, pagination will be manual. */
  onManualPageChange?: (page: number) => void;

  /** The number of pages in the dataset. */
  pages?: number;

  /** The current page index, if being controlled manually. */
  pageIndex?: number;

  /** Whether or not the table is globally searchable. */
  searchable?: boolean;

  /** Whether or not the columns are filterable. */
  filterable?: boolean;
};

export type JetTableFilterProps<T extends {}, P> = {
  table?: ReactTable.TableInstance;
  column: ReactTable.ColumnInstance<T>;
} & React.PropsWithChildren<P>;

export const JetTable = <T extends {}>(props: React.PropsWithChildren<JetTableProps<T>>) => {

  const hasManualPagination = props.onManualPageChange !== undefined && props.pages !== undefined;

  let table: ReactTable.TableInstance<T>;
  const initialPageIndex: number = props.pageIndex ?? 0;

  if (hasManualPagination) {
    table = ReactTable.useTable({
      columns: props.columns,
      data: props.data ?? [],
      manualPagination: true,
      pageCount: props.pages,
      initialState: {
        pageIndex: initialPageIndex,
        pageSize: props.rowsPerPage ? props.rowsPerPage : Number.MAX_SAFE_INTEGER
      }},
      ReactTable.useGlobalFilter,
      ReactTable.useFilters,
      ReactTable.usePagination);
  }
  else {
    table = ReactTable.useTable({
      columns: props.columns,
      data: props.data ?? [],
      initialState: {
        pageIndex: initialPageIndex,
        pageSize: props.rowsPerPage ? props.rowsPerPage : Number.MAX_SAFE_INTEGER
      }},
      ReactTable.useGlobalFilter,
      ReactTable.useFilters,
      ReactTable.usePagination);
  }

  const classes = clsx('jetui-table', props.className);

  const { pageIndex, pageSize } = table.state;
  React.useEffect(() => {
    if (props.onManualPageChange) {
      props.onManualPageChange(pageIndex);
    }
  }, [props.onManualPageChange, pageIndex, pageSize])

  const getColumnStyle = (column: ReactTable.Column<T>): React.CSSProperties => {
    const style: React.CSSProperties = {};
    if (column.width) {
      style.width = column.width;
    }

    if (column.maxWidth && column.maxWidth !== Number.MAX_SAFE_INTEGER) {
      style.maxWidth = column.maxWidth;
    }

    if (column.minWidth) {
      style.minWidth = column.minWidth;
    }

    return style;
  };

  const getKey = (item: T): string | number | undefined => {
    if (props.rowKey) {
      if (typeof props.rowKey === 'string') {
        return (item as any)[props.rowKey];
      }
      else {
        return props.rowKey(item);
      }
    }
    
    return undefined;
  };

  return (
    <div className="jetui-table-container">
      {props.loading && <div className="jetui-table-loading-overlay"><Spinner size={32} /></div>}
      {props.searchable && <GlobalSearch unfilteredRows={table.preGlobalFilteredRows} value={table.state.globalFilter} onChange={table.setGlobalFilter} />}
      <table className={classes} {...table.getTableProps()}>
        <thead>
          {table.headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th {...column.getHeaderProps()} style={getColumnStyle(column)}>
                  {column.render('Header')}
                  {props.filterable && (
                    <div className="jetui-table-filter">
                      {column.canFilter && column.render('Filter')}
                    </div>)}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...table.getTableBodyProps()}>
          {table.page.map(row => {
            table.prepareRow(row)
            return (
              <tr {...row.getRowProps()} key={getKey(row.original)}>
                {row.cells.map(cell => {
                  return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                })}
              </tr>
            )
          })}
          {table.rows.length === 0 && (
            <tr>
              <td className="jetui-table-norecords" colSpan={table.columns.length}>{props.emptyMessage ?? "No records to display"}</td>
            </tr>
          )}
        </tbody>
      </table>
      {props.rowsPerPage && (
      <div className='jetui-table-paginator'>
        <button onClick={() => table.gotoPage(0)} disabled={table.state.pageIndex == 0}><i className="material-icons">first_page</i></button>
        <button onClick={() => table.previousPage()} disabled={table.state.pageIndex == 0}><i className="material-icons">navigate_before</i></button>
        <button onClick={() => table.nextPage()} disabled={table.state.pageIndex >= table.pageCount - 1}><i className="material-icons">navigate_next</i></button>
        <button onClick={() => table.gotoPage(table.pageCount - 1)} disabled={table.state.pageIndex >= table.pageCount - 1}><i className="material-icons">last_page</i></button>
        <span>Page {table.state.pageIndex + 1} of {(table.pageCount != 0) ? table.pageCount : 1}</span>
      </div>)}
    </div>  
  )
};