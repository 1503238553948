import React from 'react';
import ReactDOM from 'react-dom';
import clsx from 'clsx';
import * as bodyScrollLock from 'body-scroll-lock';

import './Shade.css';
import { useTransition } from '../Transition/useTransition';

interface ShadeProps {
  active: boolean;
  onClick: (e: React.MouseEvent<HTMLDivElement>) => void;
  darkened?: boolean;
  disableScroll?: boolean;
  center?: boolean;
}

export const Shade: React.FC<ShadeProps> = (props) => {

  const [shadeEl, setShadeEl] = React.useState<HTMLDivElement | null>(null);
  const animState = useTransition(0.5, props.active);

  const classes = clsx(
    'jetui-shade',
    { 
      'jetui-shade-darkened': props.darkened && props.active,
      'jetui-shade-lightened': props.darkened && !props.active,
      'jetui-shade-center': props.center
    }
  );

  React.useEffect(() => {
    if (props.disableScroll && shadeEl) {
      bodyScrollLock.disableBodyScroll(shadeEl);
    }
  }, [props.disableScroll, shadeEl]);

  React.useEffect(() => {
    if (!props.active) {
      bodyScrollLock.clearAllBodyScrollLocks();
    }
    return () => bodyScrollLock.clearAllBodyScrollLocks();
  }, [props.active]);

  type ViewportSize = {
    width: number;
    height: number;
  };

  const getViewportSize = React.useCallback((): ViewportSize => {
    var e: any = window;
    var a = 'inner';
    if (!('innerWidth' in window)){
        a = 'client';
        e = document.documentElement || document.body;
    }
    return { width : e[ a+'Width' ] as number , height : e[ a+'Height' ] as number }
  }, []);

  const [size, setSize] = React.useState<ViewportSize>(getViewportSize());

  React.useEffect(() => {
    if (props.active) {
      setSize(getViewportSize());
      const handleResize = () => setSize(getViewportSize());

      window.addEventListener('resize', handleResize)
      return () => window.removeEventListener('resize', handleResize);
    }
  }, [props.active, getViewportSize]);

  const handleClick = React.useCallback((e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    if (shadeEl && e.target === shadeEl) {
      props.onClick(e);
    }
  }, [shadeEl, props.onClick]);

  if ((animState !== 'closed' && props.darkened) || (!props.darkened && props.active)) {
    return ReactDOM.createPortal((
      <div className={classes} style={{width: size?.width, height: size?.height}} onClick={handleClick} ref={el => setShadeEl(el)}>
        {props.children}
      </div>
    ), document.getElementsByTagName('body')[0]);
  }

  return null;
};