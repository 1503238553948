import React from 'react';
import axios from 'axios';
import { Input, Status } from '../../jet-ui';
import { useAuth } from '../../common/auth/use-auth';

import './CliAuth.css';

/**
 * A component that accepts CLI authentication codes and submits
 * them to Jetstream for authentication confirmation.
 */
export const CliAuth: React.FC = () => {

  const [userCode, setUserCode] = React.useState<string>('');
  const [authorizing, setAuthorizing] = React.useState<boolean>(false);

  const [message, setMessage] = React.useState<{message: string, type: 'info' | 'error'} | null>(null);
  const auth = useAuth();

  /**
   * Handles the submission of the CLI auth code form.
   * @param e The form submission event.
   */
  const handleSubmit = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    e.stopPropagation();

    setAuthorizing(true);
    setMessage(null);

    try {
      await axios.post('/account/deviceauth', JSON.stringify(userCode), auth.getRequestConfig({"Content-Type": "application/json"}));
      setMessage({message: 'CLI authorized successfully.', type: 'info'});
    }
    catch (err) {
      if (err.response.status === 400) {
        setMessage({message: 'The CLI authorization code entered was not valid. Please try again.', type: 'error'});
      }
      else {
        setMessage({message: 'There was an error processing the authorization code request. Please try again later or contact your administrator.', type: 'error'});
      }
    }
    
    setAuthorizing(false);
  };

  const state = {thing: 'blah'};

  return (
    <div className="Profile__Section">
      <h2>Authorize CLI</h2>
      <p>Please enter the code provided by the CLI below.</p>
      <div>
        <Input name="authcode" className="CliAuth__Input" type="text" placeholder="Enter CLI Code" value={userCode} onChange={e => setUserCode(e.target.value)} />
        <button disabled={authorizing} onClick={handleSubmit}>{authorizing ? "Authorizing" : "Authorize"}</button>
        <Status open={message !== null} onClose={() => setMessage(null)} type={message?.type}>{message?.message}</Status>
      </div>   
    </div>
  );
}