import React, { Fragment } from 'react';
import axios from 'axios';
import { ApiResult, NugetSource } from '../../common/dataTypes/Jetstream';
import { useAuth } from '../../common/auth/use-auth';
import { JetTable, EditPanelState, EditPanel, Formic, useForm, FormKeys, FormValue, ValidationResult, ConfirmAction, ToolButton } from '../../jet-ui';

import './NuGetSources.css';
import { Column, Cell } from 'react-table';

export const NuGetSources: React.FC = () => {

  const [sources, setSources] = React.useState<NugetSource[]>([]);
  const [loading, setLoading] = React.useState(true);
  const [editState, setEditState] = React.useState<EditPanelState<NugetSource>>({value: {}, open: false});

  const auth = useAuth();
  const Form = useForm(editState.value);

  const fetchNuGetSources = React.useCallback(async () => {
    setLoading(true);

    const result = await axios.get<ApiResult<NugetSource[]>>('/api/nugetsources', auth.getRequestConfig());
    if (result.data.success) {
      setSources(result.data.data.map(source => {
        delete source.password;
        return source;
      }));
    }

    setLoading(false);
  }, [auth]);

  React.useEffect(() => {
    fetchNuGetSources();
  }, [fetchNuGetSources]);

  const columns = React.useMemo((): Column<NugetSource>[] => [
    { 
      id: 'actions',
      width: 64,
      maxWidth: 64,
      Cell: (cell: Cell<NugetSource>) => (
        <Fragment>
          <ConfirmAction value={cell.row.original as any} icon="delete" onAction={handleOnDelete} title='Delete Source'>
            Are you sure you wish to delete NuGet source {cell.row.original.name}?
          </ConfirmAction>
          <ToolButton icon="edit" onClick={() => setEditState({value: cell.row.original as any, open: true})} collapse />
        </Fragment>
      )
    },
    {Header: 'Name', accessor: 'name', width: '20%'},
    {Header: 'Source', accessor: 'url', width: '80%'},
    {Header: 'Enabled', accessor: 'enabled', width: 80, Cell: ({value}) => value ? 'true' : 'false'}
  ], []);

  const validatePassword = React.useCallback((state: Partial<Formic<NugetSource>>, value: FormValue): ValidationResult => {
    if (state.password !== state.confirmPassword) {
      return {valid: false, message: 'Passwords must match.'};
    }

    return {valid: true};
  }, []);

  const handleOnSave = React.useCallback(async (source: Partial<Formic<NugetSource>>) => {
    const result = await axios.post<ApiResult<NugetSource[]>>('/api/nugetsources', source, auth.getRequestConfig());
    if (!result.data.success) {
      throw {message: result.data.message};
    }

    setEditState(prev => ({...prev, open: false, status: `Source ${source.name} saved.`}));
    fetchNuGetSources();
  }, [auth]);

  const handleOnDelete = React.useCallback(async (source: Partial<Formic<NugetSource>>, confirm: boolean) => {
    if (confirm && source.name) {
      const result = await axios.delete<ApiResult<NugetSource[]>>(`/api/nugetsources?source=${encodeURIComponent(String(source.name))}`, auth.getRequestConfig());
      setEditState(prev => ({...prev, status: result.data.message}));
    }
  }, []);

  return (
    <div className="NuGetSources">
      <h1>NuGet Package Sources</h1>
      <JetTable data={sources} columns={columns} loading={loading} />
      <EditPanel<NugetSource> className="NuGetSources__EditPanel" state={editState} form={Form} addLabel="Add Package Source" modelLabel="Package Source" onSave={handleOnSave}>
        <Form.OptionSelect name="enabled" placeholder="Enabled" options={[{label: "Enabled", value: "true"}, {label: "Disabled", value: "false"}]} />
        <Form.Input name="name" placeholder="Name" required />
        <Form.Input name="url" placeholder="Source URL" required />
        <Form.Input name="username" placeholder="Username" />
        <Form.Input type="password" name="password" placeholder="Password" onValidate={validatePassword} />
        <Form.Input type="password" name="confirmPassword" placeholder="Confirm Password" onValidate={validatePassword} />
      </EditPanel>
    </div>
  );
};