import React from 'react';
import clsx from 'clsx';
import './TextBox.css';
import { FormSystem, ValidationResult, Formic, FormKeys } from '../Form/FormSystem';

export interface TextBoxProps<T> extends React.DetailedHTMLProps<React.TextareaHTMLAttributes<HTMLTextAreaElement>, HTMLTextAreaElement> {
  error?: string;
  name: FormKeys<T>;
  onValidate?(state: Partial<Formic<T>>, value: Partial<Formic<T>>[FormKeys<T>]): ValidationResult;
};

export function FormTextBox<T>(formSystem: FormSystem<T> | undefined) {

  function useFormTextBox(props: TextBoxProps<T>, ref: React.Ref<HTMLTextAreaElement>) {
    let {error, onValidate, ...inputProps} = props;
    let [value, setValue] = React.useState<Formic<T>[FormKeys<T>]>();

    if (formSystem) {
      let {required} = props;
      delete inputProps.required;

      [value, setValue] = formSystem.useRegistration({name: props.name, label: props.placeholder ?? props.name, onValidate: props.onValidate, required: required});
      error = formSystem.errors.byField[props.name]?.message;
    }

    const classes = clsx('jetui-textbox', props.className, {
      'jetui-textbox-disabled': props.disabled,
      'jetui-textbox-error': props.error !== null && props.error !== undefined
    });

    const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      setValue(e.target.value);
      props.onChange && props.onChange(e);
    }

    return (
      <div className="jetui-textbox-container">
        <textarea {...inputProps} ref={ref} className={classes} placeholder=" " value={value ?? props.value ?? ''} onChange={handleChange}></textarea>
        {props.placeholder && <label className="jetui-textbox-label">{props.placeholder}</label>}
        {error !== undefined && <i className="material-icons" title={error}>error_outline</i>}
      </div>
    );
  };
  
  return React.forwardRef(useFormTextBox);
};

export const TextBox = FormTextBox<any>(undefined);