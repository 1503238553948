import React, { Fragment } from 'react';
import { Shade } from '../Shade/Shade';
import { Card } from '../Card/Card';
import clsx from 'clsx';

import './Dialog.css';
import { Status } from '../Status/Status';

interface DialogProps {
  title?: string;
  open: boolean;
  onClose: (confirmed: boolean) => void;
  actions?: DialogAction[];
  error?: string;
  className?: string;
};

export type DialogAction = {
  label: string;
  onClick: () => void;
};

export const Dialog: React.FC<DialogProps> = (props) => {

  const classes = clsx(props.className, 'jetui-dialog', {'jetui-dialog-open': props.open});
  const [error, setError] = React.useState<string>();

  React.useEffect(() => setError(props.error), [props.error]);

  return (
    <Shade onClick={() => props.onClose(false)} active={props.open} darkened center disableScroll>
      <Card className={classes} padding='large'>
        {props.title && <h4>{props.title}</h4>}
        <div>
          {props.children}
        </div>
        <div>
          {props.actions && <div className="jetui-dialog-actions">
            <Status type='error' open={error !== undefined && error !== ''} onClose={() => setError(undefined)}>{error}</Status>
            {props.actions.map(action => (<button key={action.label} onClick={action.onClick}>{action.label}</button>))}
          </div>}
        </div>
      </Card>
    </Shade>
  )
};