import React from 'react';
import clsx from 'clsx';

import './Avatar.css';

type AvatarProps = { className?: string; src?: string; }

export const Avatar: React.FC<AvatarProps> = (props) => {

  const classes = clsx('jetui-avatar', props.className);

  if (props.children) {
    return (
      <div className={classes}>
        {props.children}
      </div>
    );
  }
  else {
    return (
      <div className={classes}>
        <img src={props.src} alt="avatar" />
      </div>
    );
  }
}