import React, { SetStateAction } from "react";

/**
 * A system for validating and processing forms.
 */
export class FormSystem<T> {

  /** The fields registered to the form system. */
  private fields: {[K in FormKeys<T>]?: Field<T>} = {};

  /** The useState hook setters used to trigger renders. */
  private setters: {[K in FormKeys<T>]?: React.Dispatch<SetStateAction<Formic<T>[FormKeys<T>] | undefined>>} = {};

  /**
   * Creates an instance of a FormSystem.
   * @param state The initial state to populate the form with.
   * @param onValid A callback to run when the form is valid.
   * @param onInvalid A callback to run when the form is invalid.
   * @param errors The errors from the most recent form validation cycle.
   */
  constructor(public state: Partial<Formic<T>>,
    private onValid: (state: Partial<Formic<T>>) => void,
    private onInvalid: (errors: ValidationErrors<T>) => void,
    public errors: ValidationErrors<T>) { }

  /**
   * Handles for submission and generates a handler method for validation
   * and submission.
   * @param onValid The callback to call when the form is fully valid.
   */
  public handleSubmit(onValid: (state: Partial<Formic<T>>) => Promise<void>): (e: React.FormEvent) => void {
    return async (e: React.FormEvent) => {
      e.preventDefault();
      e.stopPropagation();

      this.errors = {all: [], byField: {}};

      for(var key in this.fields) {
        const field = this.fields[key as FormKeys<T>];
        const value = this.state[key as FormKeys<T>];

        if (field !== undefined) {
          if ((value === undefined || value === null || (typeof value === 'string' && value === '')) && field.required) {
            this.addError({field: field.name, message: `Field ${field.label} is required.`});
          }
          else if (field.onValidate) {
            const result = field.onValidate(this.state, this.state[field.name]);
            if (!result.valid) {
              this.addError({field: field.name, message: result.message});
            }
          }
        }
      }

      if (this.errors.all.length === 0) {
        await onValid(this.state);
        this.onValid(this.state);
      }
      else {
        this.onInvalid(this.errors);
      }
    }
  }

  /**
   * Registers a new field with the form system.
   * @param field The field to register.
   */
  public useRegistration(field: Field<T>): [Formic<T>[FormKeys<T>] | undefined, React.Dispatch<SetStateAction<Formic<T>[FormKeys<T>] | undefined>>] {
    this.fields[field.name] = field;
    const [fieldValue, setFieldValue] = React.useState<Formic<T>[FormKeys<T>] | undefined>(this.state[field.name]);

    this.setters[field.name] = setFieldValue;
    return [fieldValue, (value) => this.setBridge(field.name, value)];
  }

  /**
   * Bridges the form update and React hook setter.
   * @param key The key of the field to update.
   * @param value The value to update the field with.
   */
  private setBridge(key: keyof Partial<Formic<T>> & string, value: SetStateAction<Formic<T>[FormKeys<T>] | undefined>) {
    const partialState: Partial<Formic<T>> = {};
    if (typeof value === 'function') {
      const val = this.state[key] as Formic<T>[FormKeys<T>] | undefined;
      partialState[key] = value(val);
    }
    else {
      partialState[key] = value;
    }

    this.update(partialState);
  }

  /**
   * Updates the form state.
   * @param state The partial state to apply.
   */
  public update(state: Partial<Formic<T>>): void {
    this.state = {...this.state, ...state};
    for (var key in state) {
      const setter = this.setters[key];
      setter && setter(state[key]);
    }
  }

  /**
   * Replaces the form state with a new state.
   * @param state The state to replace with.
   */
  public replace(state: Partial<Formic<T>>): void {
    this.errors = {all: [], byField: {}};
    this.state = state;
    
    for (var key in this.setters) {
      const setter = this.setters[key as FormKeys<T>];
      setter && setter(state[key as FormKeys<T>]);
    }
  }

  /**
   * Adds a validation error to the form system.
   * @param error The validation error to add.
   */
  private addError(error: ValidationError<T>): void {
    this.errors.all.push(error);
    this.errors.byField[error.field] = error;
  }
};

/** 
 * A union type that enforces that form models only have values
 * of form appropriate types.
 */
export declare type Formic<T> = {
  [K in keyof T]: string | number | string[] | undefined;
};

/**
 * A field in a form.
 * @template T The type of the state in the form.
 */
export declare type Field<T> = {
  [K in FormKeys<T>]: {
    /** The name of the field. */
    name: K;

    /** The label for the field. */
    label: string;

    /** Whether or not the field is required. */
    required?: boolean;

    /**
     * A callback that validates the field against the form state.
     * @param state The current state of the form.
     * @param value The value to validate.
     */
    onValidate?(state: Partial<Formic<T>>, value: Partial<Formic<T>>[K]): ValidationResult;
  }
}[FormKeys<T>];

/** The result of validation of a field */
export declare type ValidationResult = {
  /** Whether or not the field was valid. */
  valid: false;

  /** An error message for failed validation. */
  message: string;
} | {
  /** Wether or not the field was valid. */
  valid: true;
};

/** 
 * A collection of form validation errors.
 * @tempate T The type of the state in the form. 
 */
export declare type ValidationErrors<T> = {

  /** All current validation errors. */
  all: ValidationError<T>[];

  /** Validation errors by field. */
  byField: ErrorsByField<T>;
}

/**
 * A form validation error for a field.
 * @template T The type of the state in the form.
 */
export declare type ValidationError<T> = {
  /** The field that failed to validate. */
  field: FormKeys<T>;

  /** The validation failure message. */
  message: string;
}

/** 
 * Validation errors by field on the form state type.
 * @template T The type of the state in the form.
 */
export declare type ErrorsByField<T> = {
  [K in FormKeys<T>]?: ValidationError<T>;
}

/**
 * The state of the form.
 * @template T The type of the state in the form.
 */
export declare type FormState<T> = {
  [K in keyof Formic<T>]?: K;
}

/**
 * The possible keys to the form state.
 * @template T The type of the state in the form.
 */
export declare type FormKeys<T> = Extract<keyof Formic<T>, string>;

/** The values types that can be entered in an HTML form. */
export declare type FormValue = string | string[] | number | undefined;