import React from 'react';
import './SlideRule.css';

interface SlideRuleProps {
  min: number;
  max: number;
  step?: number;
  value?: number;
  onChange?: (value: number) => void;
};

export const SlideRule: React.FC<SlideRuleProps> = (props) => {

  const [dragState, setDragState] = React.useState<{el: HTMLDivElement, x: number, max: number} | null>(null);

  const [outerEl, setOuterEl] = React.useState<HTMLDivElement | null>(null);
  const [innerEl, setInnerEl] = React.useState<HTMLDivElement | null>(null);

  const getStride = React.useCallback(() => {
    if (outerEl) {
      const size = props.max - props.min;
      return (outerEl.clientWidth - 16) / size;
    }

    return 1;
  }, [outerEl, props.max, props.min])

  const onChange = props.onChange;
  React.useEffect(() => {
    if (dragState) {

      const handleMove = (e: MouseEvent | TouchEvent) => {

        const xPos = (e as MouseEvent).screenX
          ?? (e as TouchEvent).targetTouches[0].screenX;

        let divLeft = xPos - dragState.x;
        if (divLeft < 0) {
          divLeft = 0;
        }

        if (divLeft > dragState.max) {
          divLeft = dragState.max;
        }

        const stride = getStride();
        const localValue = Math.round(divLeft / stride);
        divLeft = localValue * stride;

        dragState.el.style.left = `${divLeft}px`;
        onChange && onChange(localValue + props.min);
      };

      document.onmousemove = handleMove;
      document.ontouchmove = handleMove;    
    }
    else {
      document.onmousemove = null;
      document.ontouchmove = null;
    }
  }, [dragState, onChange, getStride, props.min]);

  React.useEffect(() => {
    if (innerEl && props.value !== undefined) {
      innerEl.style.left = `${getStride() * props.value}px`;
    }
  }, [props.value, innerEl, getStride]);

  const handleMouseDown = React.useCallback((e: React.MouseEvent<HTMLDivElement> | React.TouchEvent<HTMLDivElement>) => {
    if (outerEl) {

      document.onmouseup = () => {
        setDragState(null);
        document.onmouseup = null;
      };

      document.ontouchend = () => {
        setDragState(null);
        document.ontouchend = null;
      };
  
      const getLeftPosition = (el: HTMLDivElement) => {
        const leftPx = el.style.left ? el.style.left : '0px';
        return parseInt(leftPx.substr(0, leftPx.length - 2));
      };
  
      const xPos = (e as React.MouseEvent<HTMLDivElement>).screenX
        ?? (e as React.TouchEvent<HTMLDivElement>).targetTouches[0].screenX;

      setDragState({
        el: e.target as HTMLDivElement,
        x: xPos - getLeftPosition(e.target as HTMLDivElement),
        max: outerEl.clientWidth - 16
      });
    }
  }, [outerEl]);

  return (
    <div className="jetui-sliderule-outer" ref={ref => setOuterEl(ref)}>
      <div className="jetui-sliderule-inner" onMouseDown={handleMouseDown} onTouchStart={handleMouseDown} ref={ref => setInnerEl(ref)} />
    </div>
  );
};