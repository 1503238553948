import React from 'react';

import './JobList.css';

import { Job, JobState } from '../../common/dataTypes/Jetstream';
import { JobEntry } from './jobEntry/JobEntry';

/** Properties on the JobList component. */
interface JobListProps {

  /** The jobs within the flow. */
  jobs: Job[];

  /** A collection of all tracked job states by job execution ID. */
  states: { [index: number]: JobState };

  /**
   * A callback fired when this job is selected.
   * @param jobId The ID of the job that was selected.
   */
  onJobSelect: (jobId: number) => void;

  /**
   * A callback fired when a request is made to resume
   * the flow starting at this job.
   * @param jobId The ID of the job that was requested.
   * @param singleJob Whether or not to run only the job requested.
   */
  onJobResume: (jobId: number, singleJob: boolean) => void;

  /** The job that is selected in the list. */
  selectedJob: number;
}

/**
 * A component that renders a list of jobs within the flow detail page.
 * @param props The properties of the component.
 */
export const JobList: React.FC<JobListProps> = (props) => {

  /**
   * Creates a job entry element.
   * @param job The job to create the element for.
   * @returns A new job entry element.
   */
  const createJobEntry = (job: Job) => {
    return (
      <JobEntry key={job.id} jobs={props.jobs} id={job.id} onJobSelect={props.onJobSelect} onJobResume={props.onJobResume}
        state={props.states[job.jobExecutionId ?? 0] ?? {}} selected={props.selectedJob === job.id} />
    );
  };

  const jobEntries = props.jobs.map(createJobEntry)

  return (
    <div className="JobList">
      {jobEntries}
    </div>
  )
}
