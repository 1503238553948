import React from 'react';
import clsx from 'clsx';
import { Shade } from '../Shade/Shade';

import './Dropdown.css';

interface DropdownProps {
  anchor: HTMLElement | null;
  open: boolean;
  onClose: () => void;
  pinned?: boolean;
  className?: string;
}

export const Dropdown: React.FC<DropdownProps> = (props) => {
  
  const [anchor, setAnchor] = React.useState(props.anchor);
  const [dropdownEl, setDropdownEl] = React.useState<HTMLDivElement | null>(null);

  const [shadeOpen, setShadeOpen] = React.useState(false);

  React.useEffect(() => {
    setAnchor(props.anchor);
  }, [props.anchor]);

  React.useEffect(() => {
    if (anchor) {
      const rect = anchor.getBoundingClientRect();
      if (props.open) {
        setShadeOpen(true);

        if (dropdownEl) {
          //Move the hidden dropdown into the start position
          dropdownEl.style.transition = 'none';
          dropdownEl.style.opacity = '0.0';
          dropdownEl.style.top = `${rect.bottom - 4}px`;
          dropdownEl.style.left = `${rect.left}px`;
          let h = dropdownEl.offsetHeight; //Trigger reflow to flush changes

          dropdownEl.style.transition = 'all 0.1s ease-out';
          dropdownEl.style.opacity = '1.0';
          dropdownEl.style.top = `${rect.bottom}px`;
          dropdownEl.style.left = `${rect.left}px`;
          h = dropdownEl.offsetHeight;

          window.addEventListener('scroll', function handleScrollClose() {
            window.removeEventListener('scroll', handleScrollClose);

            setShadeOpen(false);

            dropdownEl.style.transition = 'none';  
            dropdownEl.style.opacity = '0.0';
            let h = dropdownEl.offsetHeight;
            setDropdownEl(null);

            props.onClose();
          });
        }
      }

      if(!props.open && dropdownEl) {
        
        dropdownEl.style.opacity = '0.0';
        dropdownEl.style.top = `${rect.bottom - 4}px`;
        dropdownEl.style.left = `${rect.left}px`;

        setTimeout(() => { 
          setShadeOpen(false);
        }, 100);
      }
    }
  }, [anchor, dropdownEl, props.open]);

  const handleClicked = (e: React.MouseEvent<HTMLDivElement>) => {
    if (props.pinned) {
      e.stopPropagation();
    }
    else {
      props.onClose();
    }
  };
  
  const classes = clsx('jetui-dropdown', props.className);

  return (
    <Shade active={shadeOpen} onClick={props.onClose}>
      <div className={classes} ref={r => setDropdownEl(r)} onClick={handleClicked}>
        {props.children}
      </div>
    </Shade>  
  );
}