import React from 'react';
import './LogSearch.css';

interface LogSearchProps {
  log: string[];
  onSearch: (results: SearchResults | undefined) => void;
  onSelect: (match: SearchMatch | undefined) => void;
};

export interface SearchResults {
  total: number;
  term: string;
  matches: {[lineNumber: number]: number[]};
  lineMatches: number[];
};

export interface SearchMatch {
  matchNumber: number;
  line: number;
  lineIndex: number;
  lineMatchIndex: number;
};

export const LogSearch: React.FC<LogSearchProps> = (props) => {

  const [searchTerm, setSearchTerm] = React.useState<string>("");
  const [searchResults, setSearchResults] = React.useState<SearchResults>();
  const [currentMatch, setCurrentMatch] = React.useState<SearchMatch>();

  const [isActive, setIsActive] = React.useState(false);

  const {log, onSearch, onSelect} = props;

  const handleSearch = React.useCallback((term: string) => {

    if (term === null || term === undefined || term === "") {
      onSearch(undefined);
      onSelect(undefined);

      setSearchResults(undefined);
      setCurrentMatch(undefined);

      return;
    }

    let results: SearchResults = {
      total: 0,
      term: term,
      matches: {},
      lineMatches: []
    };

    for (var i = 0; i < log.length; i++) {
      const lineResults = searchLogLine(log[i], term);
      if (lineResults && lineResults.length > 0) {
        results.matches[i] = lineResults;
        results.total += lineResults.length;
        results.lineMatches.push(i);
      }
    }

    setSearchResults(results);
    onSearch(results); 

    if (results.total > 0) {
      const firstMatch: SearchMatch = {
        matchNumber: 1,
        line: results.lineMatches[0],
        lineIndex: 0,
        lineMatchIndex: 0
      };

      setCurrentMatch(firstMatch);
      onSelect(firstMatch);
    }
    else {
      setCurrentMatch(undefined);      
    }
  }, [log, onSearch, onSelect]);

  const debounceSearchOnChange = React.useCallback((() => {
    let timeout: NodeJS.Timeout | null;
    return (e: React.ChangeEvent<HTMLInputElement>) => {
      if (timeout) {
        clearTimeout(timeout);
      }

      const term = e.target.value;
      setSearchTerm(term);

      timeout = setTimeout(() => {
        timeout = null;
        handleSearch(term);
      }, 300);
    };
  })(), [props.log]);

  const searchLogLine = (logLine: string, searchTerm: string) => {

    logLine += "";
    searchTerm += "";

    searchTerm = searchTerm.toLocaleLowerCase();
    logLine = logLine.toLocaleLowerCase();

    if (searchTerm.length <= 0) {
      return null;
    }

    let pos = 0;
    let step = searchTerm.length;

    let matches: number[] = [];

    while (true) {
      pos = logLine.indexOf(searchTerm, pos);

      if (pos >= 0) { 
        matches.push(pos);
        pos += step;
      } 
      else {
        break;
      }
    }

    return matches;
  };

  const renderResultsTag = () => {
    if (searchResults && searchResults.total > 0 && currentMatch) {
      return `${currentMatch.matchNumber} of ${searchResults.total}`;
    }

    return "No matches.";
  };

  const advanceMatch = () => {
    setCurrentMatch(match => {
      if (match && searchResults) {
        const newMatch = {...match};
        
        const currentLine = searchResults.matches[newMatch.line];
        newMatch.lineIndex++;
        newMatch.matchNumber++;

        if (newMatch.matchNumber > searchResults.total) {
          newMatch.matchNumber = 1;
          newMatch.lineMatchIndex = 0;
          newMatch.line = searchResults.lineMatches[0];
          newMatch.lineIndex = 0;
        }
        else {
          if (newMatch.lineIndex >= currentLine.length) {
            newMatch.lineIndex = 0;
            newMatch.lineMatchIndex++;

            newMatch.line = searchResults.lineMatches[newMatch.lineMatchIndex];
          }
        }

        props.onSelect(newMatch);
        return newMatch;
      }    
    });
  };

  const decreaseMatch = () => {
    setCurrentMatch(match => {
      if (match && searchResults) {
        const newMatch = {...match};
        
        newMatch.lineIndex--;
        newMatch.matchNumber--;

        if (newMatch.matchNumber < 1) {
          newMatch.matchNumber = searchResults.total;
          newMatch.lineMatchIndex = searchResults.lineMatches.length - 1;
          newMatch.line = searchResults.lineMatches[newMatch.lineMatchIndex];
          newMatch.lineIndex = searchResults.matches[newMatch.line].length - 1;
        }
        else {
          if (newMatch.lineIndex < 0) {
            newMatch.lineIndex = 0;
            newMatch.lineMatchIndex--;

            newMatch.line = searchResults.lineMatches[newMatch.lineMatchIndex];
          }
        }
                  
        props.onSelect(newMatch);
        return newMatch;
      }    
    });
  };

  return (
    <div className="LogSearch">
      <input className={isActive || searchTerm !== "" ? "LogSearch__Active" : undefined} type="text" onChange={debounceSearchOnChange}
        value={searchTerm} onFocus={() => setIsActive(true)} onBlur={() => setIsActive(false)} />
      <i className="material-icons LogSearch__Icon" unselectable="on">search</i>
      {searchResults && (
        <span>
          <button>
            <i className="material-icons LogSearch__Control" onClick={advanceMatch} unselectable="on">expand_more</i>
          </button>
          <button>
            <i className="material-icons LogSearch__Control" onClick={decreaseMatch} unselectable="on">expand_less</i>
          </button>
          {renderResultsTag()}
        </span>
      )}
    </div>
  );
};