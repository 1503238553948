import React from 'react';
import clsx from 'clsx';
import { useHistory } from 'react-router-dom';

import './ToolButton.css';

/** Properties for the ToolButton component. */
interface ToolButtonProps {

  /** The icon to use for the button. */
  icon: string;

  /** A URI that will be navigated to when clicked. */
  to?: string;

  /**
   * A handler that fires when the button is clicked.
   * @param e The click event generated by the DOM.
   */
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;

  /** Whether or not tool buttons will have close spacing. */
  collapse?: boolean;

  /** Whether or not the tool button is disabled. */
  disabled?: boolean;

  /** The style of the icon. */
  iconStyle?: 'good' | 'warning' | 'error' | 'regular';
}

/**
 * A component that displays a button on the flow toolbar.
 * @param props The properties of the component.
 */
export const ToolButton: React.FC<ToolButtonProps> = (props) => {

  const history = useHistory();

  /**
   * Handles the click event when the button is clicked.
   * @param e The click event generated by the DOM.
   */
  const handleOnClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (props.to) {
      history.push(props.to);
      return;
    }

    if(props.onClick) {
      props.onClick(e);
      return;
    }
  }

  const classes = clsx('jetui-toolbutton', {
    'jetui-toolbutton-collapse': props.collapse,
    [`jetui-toolbutton-${props.iconStyle}`]: props.iconStyle
  });

  return (
    <button className={classes} onClick={handleOnClick} disabled={props.disabled}>
      <i className="material-icons">{props.icon}</i>
      {props.children && (
        <span>
          {props.children}
        </span>)}
    </button>
  )
}
