import React, { SyntheticEvent } from 'react';

import './JobLog.css';
import { Layout } from '../../jet-ui/Layout/Layout';
import { LayoutItem } from '../../jet-ui/LayoutItem/LayoutItem';
import { LogSearch, SearchMatch, SearchResults } from './LogSearch';

/** Properties on the JobLog component. */
interface JobLogProps {

  /** The name of the job. */
  name: string;

  /** The job log as a collection of lines of the log. */
  log: string[];
};

/**
 * A component that displays the log of the job.
 * @param props The properties of the component.
 */
export const JobLog: React.FC<JobLogProps> = (props) => {

  const [autoScroll, setAutoScroll] = React.useState(true);
  const [searchResults, setSearchResults] = React.useState<SearchResults>();
  const [searchMatch, setSearchMatch] = React.useState<SearchMatch>();

  const element = React.useRef<HTMLDivElement>(null);

  /** Scrolls the log to the bottom if auto scroll is active. */
  React.useEffect(() => {
    if (element.current != null && autoScroll) {
      element.current.scrollTop = element.current.scrollHeight;
    }
  }, [props.log, autoScroll]);

  React.useEffect(() => {
    if (searchMatch) {
      const el = document.getElementById('currentMatch');

      if (el) {
        el.scrollIntoView({block: 'nearest', inline:'center'});
      }
    }
  }, [searchMatch]);

  /**
   * Handles when the log is scrolled to decide whether or not
   * to auto scroll the log.
   * @param e 
   */
  const handleScroll = (e: SyntheticEvent<HTMLDivElement>) => {
    if (!autoScroll && scrolledToBottom(e.currentTarget)) {
      setAutoScroll(true);
    }

    if(autoScroll && !scrolledToBottom(e.currentTarget)) {
      setAutoScroll(false);
    }
  };

  /**
   * Checks whether or not the log is scrolled to the bottom by
   * inspecting the scroll height.
   * @param el The element that is being checked.
   */
  const scrolledToBottom = (el: HTMLDivElement) => (el.scrollHeight - el.scrollTop - el.offsetHeight) < 1;

  const renderLogLines = () => {
    return props.log.map((line, index) => (
      <p key={index}>
        <span>{index + 1}</span>{renderLine(line, index)}
      </p>
    ));
  };

  const renderLine = (line: string, lineNumber: number) => {
    if (searchResults && searchResults.matches[lineNumber] && searchMatch) {
      return renderLineWithMatches(line, lineNumber, searchResults.matches[lineNumber], searchResults.term.length, searchMatch);
    }
    else {
      return line;
    }
  };

  const renderLineWithMatches = (line: string, lineNumber: number, matches: number[], termLength: number, match: SearchMatch) => {
    const nodes = [];
    let prevPos = 0;
    let pos = 0;

    for (var i = 0; i < matches.length; i++) {
      pos = matches[i];
      if (pos !== prevPos) {
        nodes.push(line.substr(prevPos, pos - prevPos));
      }

      if (match.line === lineNumber && match.lineIndex === i) {
        nodes.push(<mark id="currentMatch">{line.substr(pos, termLength)}</mark>);
      }
      else {
        nodes.push(<mark>{line.substr(pos, termLength)}</mark>);
      }
      prevPos = pos + termLength;
    }

    if (line.length - 1 !== prevPos) {
      nodes.push(line.substr(prevPos, (line.length - prevPos)));
    }

    return nodes;
  };

  const handleSearchMatchSelect = (match: SearchMatch | undefined) => {
    setSearchMatch(match);
  };

  if (props.log.length > 0) {
    return (
      <React.Fragment>
        <div className="JobLog__Header">
          <h2>{props.name}</h2>
          <LogSearch log={props.log} onSearch={results => setSearchResults(results)} onSelect={handleSearchMatchSelect} />
        </div>
        <div className="JobLog" ref={element} onScroll={handleScroll}>
          {renderLogLines()}
        </div>
      </React.Fragment>
    )
  } else {
    return (
      <div className="JobLog JobLog--disabled" ref={element}>
        No logs available.
      </div>
    )
  }
}
