import React from 'react';
import Croppie from 'croppie';
import axios from 'axios';

import './AvatarUploader.css';
import '../../../node_modules/croppie/croppie.css';
import { useAuth } from '../../common/auth/use-auth';

/** Properties on the AvatarUploader component. */
interface AvatarUploaderProps {

  /** The source URI for the avatar image. */
  src?: string;
}

/**
 * A component that manages the user's avatar image.
 * 
 * @param props The properties of the component.
 */
export const AvatarUploader: React.FC<AvatarUploaderProps> = (props) => {

  const [avatarImage, setAvatarImage] = React.useState<string | null>(null);
  const [prevAvatarImage, setPrevAvatarImage] = React.useState<string | null>(null);

  const [imageToCrop, setImageToCrop] = React.useState<string | null>(null);
  const fileInputRef = React.useRef<HTMLInputElement>(null);

  const [uploaderRef, setUploaderRef] = React.useState<HTMLDivElement | null>(null);
  const [cropper, setCropper] = React.useState<Croppie | null>(null);

  const [uploading, setUploading] = React.useState<boolean>(false);
  const [removing, setRemoving] = React.useState<boolean>(false);

  const auth = useAuth();

  /** 
   * An effect that stores the current image source when the image source 
   * property changes.
   */
  React.useEffect(() => {
    if (props.src) {
      setAvatarImage(props.src);
    }
  }, [props.src]);

  /**
   * An effect hook that sets up the Croppie instance when the user has
   * selected an image to crop.
   */
  React.useEffect(() => {
    if (uploaderRef && imageToCrop) {
      var crop = new Croppie(uploaderRef, {
          viewport: { width: 200, height: 200, type: 'circle' },
          boundary: { width: 200, height: 200 },
          showZoomer: true,
          mouseWheelZoom: false,
          enableOrientation: false,
          enableExif: false
      });

      crop.bind({url: imageToCrop});
      setCropper(crop);
    }
  }, [uploaderRef, imageToCrop]);

  /** Renders the avatar uploader control. */
  const renderUploader = () => {

    if (imageToCrop) {
      return (
        <div>
          <div ref={(el) => setUploaderRef(el)} />
          <button onClick={handleImageUpload} disabled={uploading}>{uploading ? 'Uploading...' : 'Upload'}</button>
          {renderCancelButton()}
        </div>  
      );
    }

    if (avatarImage) {
      return (
        <div>
          <img src={avatarImage} alt="Avatar" />
          <button disabled={removing} onClick={() => { setPrevAvatarImage(avatarImage); setAvatarImage(null); }}>Change</button>
          <button disabled={removing} onClick={handleRemoveImage}>{removing ? 'Removing...' : 'Remove'}</button>
        </div>      
      );
    }
    else {
      return (
        <div>
          <div className="AvatarUploader--Empty" onDragOver={handleDragOver} onDragEnter={handleDragOver} onDrop={handleFileDropped} onClick={handleEmptyClick}>
            <input type="file" id="avatar-upload" accept="image/png, image/jpeg" style={{ display: 'none' }} onChange={handleFileSelected} ref={fileInputRef} />
            <p>Drag an image or click here to add a profile image.</p>
          </div>
          {renderCancelButton()}
        </div>
      );
    }
  };

  /** Renders a cancel button to cancel the current uploader operation. */
  const renderCancelButton = () => {
    if (prevAvatarImage && !uploading) {
      return (<button onClick={() => { setAvatarImage(prevAvatarImage); setPrevAvatarImage(null); setImageToCrop(null); }}>Cancel</button>);
    }

    return null;
  }

  /**
   * Handles when a new avatar image is uploaded.
   * @param e The click event from the upload button.
   */
  const handleImageUpload = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (cropper) {
      setUploading(true);

      cropper.result({type: 'blob'}).then(blob => {
        var data = new FormData();
        data.append('image', blob, blob.type);

        axios.post('/account/image', data, auth.getRequestConfig({'Content-Type': 'multipart/form-data'}))
          .then(response => {
            auth.service.refresh().then(user => {
              setAvatarImage(user.profile.picture ?? null);

              setCropper(null);
              setPrevAvatarImage(null);
              setImageToCrop(null);

              setUploading(false);
            })
          });
      });
    }
  };

  /**
   * Handles when the user removes the avatar image.
   * @param e The click event from the remove button.
   */
  const handleRemoveImage = async (e: React.MouseEvent<HTMLButtonElement>) => {
    setRemoving(true);
    axios.delete('/account/image', auth.getRequestConfig())
    .then(response => {
      auth.service.refresh().then(user => {
        setAvatarImage(null);

        setCropper(null);
        setPrevAvatarImage(null);
        setImageToCrop(null);

        setUploading(false);
      })
    .finally(() => setRemoving(false));
    });
  }

  /**
   * Handles when an item is dragged over the avatar upload hotspot.
   * @param e A drag event from the browser.
   */
  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => e.preventDefault();

  /**
   * Handles when the user clicks in an empty avatar hotspot to trigger the
   * file selection dialog.
   * @param e A click event from the hotspot.
   */
  const handleEmptyClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  /**
   * Handles when the user selects an avatar image file from the file dialog.
   * @param e An input change event fired when the user completes the dialog.
   */
  const handleFileSelected = (e: React.ChangeEvent<HTMLInputElement>) => {
    var selectedFile = e.target.files?.item(0);
    if (selectedFile) {
      readImageFile(selectedFile);
    }
  };

  /**
   * Handles a file being dropped into the avatar hotspot.
   * @param e A drag event fired when the file is dropped.
   */
  const handleFileDropped = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();

    var droppedFile = e.dataTransfer.files.item(0);
    if (droppedFile && (droppedFile.type === 'image/png' || droppedFile.type === 'image/jpeg')) {
      readImageFile(droppedFile);
    }
  };

  /**
   * Reads an image file dropped into the hotspot or selected from the file dialog.
   * @param file The image file to read.
   */
  const readImageFile = (file: Blob) => {
    var reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onload = (e: ProgressEvent<FileReader>) => {
      setImageToCrop(reader.result as string);
    };
  };

  return (
    <div className="AvatarUploader">
      {renderUploader()}
    </div>
  )
}