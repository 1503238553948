import React from 'react';
import clsx from 'clsx';
import './Capsule.css';

interface CapsuleProps {
  className?: 'string';
  color?: 'primary' | 'warning' | 'error' | 'good' | 'neutral';
};

export const Capsule: React.FC<CapsuleProps> = (props) => {
  const classes = clsx(
    'jetui-capsule',
    props.className,
    {
      'jetui-capsule-primary': !props.color,
      [`jetui-capsule-${props.color}`]: props.color
    }
  );

  return (
    <div className={classes}>{props.children}</div>
  );
}