import React from 'react';
import { EMailNotifications } from './EMailNotifications';
import { WebhookNotifications } from './WebhookNotifications';

import './Notifications.css';

export const Notifications: React.FC = () => {
  return (
    <div className="Notifications">
      <h1>Manage Notifications</h1>
      <EMailNotifications />
      <WebhookNotifications />
    </div>
  );
}