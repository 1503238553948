import React from 'react';
import { Content } from '../jet-ui/Content/Content';
import { Breadcrumb } from '../jet-ui/Breadcrumb/Breadcrumb';
import { Card } from '../jet-ui/Card/Card';
import { ComponentTable } from './ComponentTable';

import './Components.css';

export const Components: React.FC = () => {
  return (
    <Content className="Components">
      <div>
        <Breadcrumb href="/">Home</Breadcrumb>
        <Breadcrumb href="/components">Components</Breadcrumb>
      </div>
      <h1>Components</h1>
      <Card>
        <h2>Loaded Components</h2>
        <ComponentTable />
      </Card>
    </Content>
  );
};