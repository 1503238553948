export default class TimeSpan {
  constructor(public readonly hours: number, public readonly minutes: number, public readonly seconds: number) {}

  public toString(): string {
    return `${this.pad(this.hours)}:${this.pad(this.minutes)}:${this.pad(this.seconds)}`;
  }

  public static parse(value: string): TimeSpan {
    const sections = value.split(':');
    return new TimeSpan(parseInt(sections[0].substr(sections[0].length - 3, 2)), parseInt(sections[1]), parseInt(sections[2]));
  }

  private pad(num: number): string {
    return num < 10 ? `0${num}` : num.toString();
  }
}