import React from 'react';
import { useAuth } from '../../common/auth/use-auth';

export interface ThemeResult {
  theme: 'light' | 'dark';
  toggleTheme: () => void;
}

export const useTheme = (): ThemeResult => {
  const auth = useAuth();
  const [theme, setTheme] = React.useState<'light' | 'dark'>('light');
  const [prefersDark, setPrefersDark] = React.useState(window.matchMedia && window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches);

  React.useEffect(() => {
    if (window.matchMedia !== undefined) {
      const handleDefaultChange = (ev: MediaQueryListEvent) => {
        setPrefersDark(ev.matches);
      };

      var media = window.matchMedia('(prefers-color-scheme: dark)');
      if (media.addEventListener !== undefined) {
        media.addEventListener('change', handleDefaultChange);
        return () => media.removeEventListener('change', handleDefaultChange);
      }

      if (media.addListener !== undefined) {
        media.addListener(handleDefaultChange);
        return () => media.removeListener(handleDefaultChange);
      }
    }
  }, []);

  React.useEffect(() => {
    const prefersDark = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;
    const defaultTheme = prefersDark ? 'dark' : 'light';

    if (auth.user) {
      const localTheme = window.localStorage.getItem(`jetstream-theme-${auth.user.profile.sub}`) as ('light' | 'dark' | null);
      setTheme(localTheme ?? defaultTheme);

      document.getElementsByTagName('body')[0].className = `jetui-theme-${localTheme ?? defaultTheme}`;
    }
    else {
      setTheme(defaultTheme);
      document.getElementsByTagName('body')[0].className = `jetui-theme-${defaultTheme}`;
    }
  }, [auth.user, prefersDark]);

  const toggleTheme = React.useCallback(() => {
    if (auth.user) {
      if (theme === 'light') {
        setTheme('dark');
        window.localStorage.setItem(`jetstream-theme-${auth.user.profile.sub}`, 'dark');

        document.getElementsByTagName('body')[0].className = `jetui-theme-dark`;
      }
      else if (theme === 'dark') {
        setTheme('light');
        window.localStorage.setItem(`jetstream-theme-${auth.user.profile.sub}`, 'light');

        document.getElementsByTagName('body')[0].className = `jetui-theme-light`;
      }
    }
  }, [auth.user, theme]);

  return { theme, toggleTheme };
};