import React from 'react';
import { Datesystem, DateMap } from './Datesystem';
import './Datepicker.css';

interface DatepickerProps {
  value: Date;
  onChange: (date: Date) => void;
}

export const Datepicker: React.FC<DatepickerProps> = (props) => {
  const dateSystemRef = React.useRef(new Datesystem());
  const dateSystem = dateSystemRef.current;

  const [value, setValue] = React.useState(props.value);
  const [currentMonth, setCurrentMonth] = React.useState<DateMap>();

  React.useEffect(() => {
    const dateValue = new Date(props.value.getFullYear(), props.value.getMonth(), props.value.getDate());
    setValue(dateValue);

    dateSystem.set(dateValue.getFullYear(), dateValue.getMonth());
    setCurrentMonth(dateSystem.currentMonth);
  }, [props.value, dateSystem]);

  const handleDateSelect = (date: number | null) => {
    if (date) {
      const dateValue = new Date(dateSystem.currentMonth.year, dateSystem.currentMonth.monthNum, date);
      props.onChange(dateValue);
      setValue(dateValue);
    }
  };

  const moveNext = () => {
    dateSystem.moveNext();
    setCurrentMonth(dateSystem.currentMonth);
  };

  const movePrev = () => {
    dateSystem.movePrev();
    setCurrentMonth(dateSystem.currentMonth);
  };

  const getDateClass = (date: number | null) => {
    if (currentMonth && date) {
      return (currentMonth.year === value.getFullYear() && currentMonth.monthNum === value.getMonth() && date === value.getDate())
        ? "jetui-datepicker-active"
        : undefined;
    }

    return undefined;
  };

  return (
    <div className="jetui-datepicker">
      <div>
        <i className="material-icons" onClick={movePrev}>navigate_before</i>       
        <h4>{dateSystem.currentMonth.month} {dateSystem.currentMonth.year}</h4>
        <i className="material-icons" onClick={moveNext}>navigate_next</i>
      </div>   
      <div>
        <table>
          <thead>
            <tr>
              <th>Su</th>
              <th>Mo</th>
              <th>Tu</th>
              <th>We</th>
              <th>Th</th>
              <th>Fr</th>
              <th>Sa</th>
            </tr>      
          </thead>
          <tbody>
            {currentMonth && currentMonth.map.map((week, index) => (
              <tr key={index}>
                {week.map(date => <td key={date ?? undefined} className={getDateClass(date)} onClick={() => handleDateSelect(date)}>{date}</td>)}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}
