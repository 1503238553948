import React from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';

import '../login/Login.css';
import { ApiResult } from '../common/dataTypes/Jetstream';
import { FormInput, Input } from '../jet-ui/Input/Input';

/** An interface that describes the login form model. */
interface ISignupForm {

  /** The username to signup with. */
  username: string;

  /** The user's full name. */
  fullName: string;

  /** The password for the user. */
  password: string;

  /** The password confirmation. */
  passwordConfirmation: string;
}

/** An interface that describes the login metadata API response. */
interface ILoginMetadata {

  /** A collection of enabled external authentication providers. */
  providers: IAuthProvider[];
}

/**
 * An external authentication provider that has been enabled
 * in the Jetstream auth system.
 */
interface IAuthProvider {

  /** The display name of the authentication provider. */
  displayName: string;

  /** The name of the authentication scheme for this provider. */
  scheme: string;
}

/**
 * The login page functional component.
 */
export const SignUp: React.FC = () => {

  const [isProcessing, setIsLoggingIn] = React.useState<boolean>(false);
  const [externalProviders, setExternalProviders] = React.useState<IAuthProvider[]>([]);
  const [formState, setFormState] = React.useState<ISignupForm>({ username: '', password: '', passwordConfirmation: '', fullName: '' });
  const [error, setError] = React.useState<string>('');

  const location = useLocation();
  const search = new URLSearchParams(location.search);

  const returnUrl = search.get('ReturnUrl');
  const inviteId = search.get('inviteId');

  React.useEffect(() => {

    /**
     * Fetches the login metadata from the backend.
     */
    const fetchLoginMetadata = async () => {
      const result = await axios.get<ILoginMetadata>(`account/metadata?ReturnUrl=${returnUrl}`);
      if (result.status === 200) {
        setExternalProviders(result.data.providers);
      }
    };

    fetchLoginMetadata();
  }, [returnUrl]);

  /**
   * Handles the submission of the login form.
   * @param e The form submission event.
   */
  const handleLoginSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();

    setIsLoggingIn(true);

    try {
      const result = await axios.post<ApiResult<void>>('/account/signup', Object.assign({inviteId: inviteId}, formState));

      if (result.status === 200 && result.data.success) {
        setError('Sign up successful. You will now be redirected to login to Jetstream.');
        setTimeout(() => window.location.assign('/'), 3000);      
      } else {
        setError(result.data.message);
      }

    } catch(err) {
      setError('There was an error processing your signup request.');
    }
    
    setIsLoggingIn(false);
  };

  /**
   * Handles when an external login is requested via clicking on
   * an external auth provider icon.
   * @param scheme The scheme to login using.
   */
  const handleExternalLogin = (scheme: string) => {
    if (returnUrl) {
      window.location.assign(`/account/external?scheme=${encodeURI(scheme)}&ReturnUrl=${encodeURIComponent(returnUrl)}&inviteId=${inviteId}`);
    } else {
      window.location.assign(`/account/external?scheme=${encodeURI(scheme)}&inviteId=${inviteId}`);
    }   
  };

  /**
   * Handles when the username field is updated.
   * @param e An input change event.
   */
  const handleSetUsername = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setFormState(prev => cloneInto(prev, {username: value}));
  }

  /**
   * Handles when the full name field is updated.
   * @param e An input change event.
   */
  const handleSetFullName = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setFormState(prev => cloneInto(prev, {fullName: value}));
  }

  /**
   * Handles when the password field is updated.
   * @param e An input change event.
   */
  const handleSetPassword = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setFormState(prev => cloneInto(prev, {password: value}));
  }

  /**
   * Handles when the password confirmation field is updated.
   * @param e An input change event.
   */
  const handleSetPasswordConfirmation = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setFormState(prev => cloneInto(prev, {passwordConfirmation: value}));
  }

  /**
   * Clones the target object and shallow copies the fields
   * of the source object into the target object.
   * @param target The object to clone and assign to.
   * @param source The source object to copy fields from.
   */
  const cloneInto = (target: any, source: any) => {
    const clone = Object.assign({}, target);
    return Object.assign(clone, source);
  };
  
  /**
   * Renders external authentication providers.
   */
  const renderExternalProviders = () => externalProviders.map(provider => {
    return (
      <li onClick={() => handleExternalLogin(provider.scheme)}>
        <img src={`auth_${provider.scheme}.svg`} alt={provider.displayName} /> {provider.displayName}
      </li>
    );
  });

  return (
    <div className='Login__Wrapper'>
      <div className='Login'>
        <div className='Login__Logo'>
          <img src='logo.svg' alt="Logo" /> JETSTREAM
        </div>
        <div className='Login__Card'>
          <h1>SIGNUP WITH JETSTREAM</h1>
          <form onSubmit={handleLoginSubmit}>
            <label className='Login__Card__Error'>{error}</label>
            <Input type="text" name="username" placeholder="Username" value={formState.username} onChange={handleSetUsername} />
            <Input type="text" name="fullName" placeholder="Full Name" value={formState.fullName} onChange={handleSetFullName} />
            <Input type="password" name="password" placeholder="Password" value={formState.password} onChange={handleSetPassword} />
            <Input type="password" name="passwordConfirmation" placeholder="Confirm Password" value={formState.passwordConfirmation} onChange={handleSetPasswordConfirmation} />
            <input type="submit" value={isProcessing ? "PROCESSING..." : "SIGN UP"} />
          </form>
          <div className='Login__Card__ExternalProviders'>
            <h1>OR LOGIN WITH</h1>
            <ul>
              {renderExternalProviders()}
            </ul>
          </div>
        </div>
      </div>   
    </div>
  )
}