import React from 'react';
import { Link } from 'react-router-dom';
import './TopNav.css';

interface TopNavProps {
  links: TopNavLink[];
};

interface TopNavLink {
  name: string;
  url: string;
};

export const TopNav: React.FC<TopNavProps> = (props) => {
  return (
    <div className='jetui-topnav'>
      {props.links.map(link => <Link className='jetui-topnav-link' to={link.url}>{link.name}</Link>)}
    </div>
  );
};