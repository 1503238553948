import React from 'react';
import { Link } from 'react-router-dom';

import './Breadcrumb.css';

interface BreadcrumbProps {
  href: string;
}

export const Breadcrumb: React.FC<BreadcrumbProps> = (props) => {
  return (
    <span className="jetui-breadcrumb">
      <Link to={props.href}>{props.children}</Link>
    </span>
  );
};